import {
  Auth,
  AuthErrorCodes,
  ConfirmationResult,
  RecaptchaVerifier,
  User,
} from 'firebase/auth'

import CommonStore from '~/client/src/shared/stores/ui/Common.store'

import Localization from '../../localization/LocalizationManager'
import ApiAuthService from '../../services/ApiAuthService'
import FirebaseAuthService, {
  FIREBASE_PASSWORD_PROVIDER_ID,
} from '../../services/FirebaseAuthService'
import EventsStore from '../EventStore/Events.store'
import { INIT_APP } from '../EventStore/eventConstants'

export default class AuthenticationStore {
  public get withPasswordProvider(): boolean {
    return this.firebaseAuthService.withPasswordProvider
  }

  public get isAuthenticated(): boolean {
    return !!this.firebaseAuthService.authUser
  }

  public get fromMicrosoftAzure(): boolean {
    return this.firebaseAuthService.fromMicrosoftAzure
  }

  public get authEmail(): string {
    return this.firebaseAuthService.authUser.email
  }

  public get firebaseAuth(): Auth {
    return this.firebaseAuthService.auth
  }

  public constructor(
    private readonly commonStore: CommonStore,
    private readonly firebaseAuthService: FirebaseAuthService,
    private readonly apiService: ApiAuthService,
    private readonly eventsStore: EventsStore,
  ) {}

  public observeAuthState() {
    this.onAuthStateChanged(user => {
      if (user) {
        user.getIdToken().then(accessToken => {
          this.firebaseAuthService.setAccessToken(accessToken)
          this.eventsStore.dispatch(INIT_APP, user)
        })
      } else {
        this.firebaseAuthService.resetAccessToken()
        this.commonStore.displayLoginView()
      }
    })
  }

  public onAuthStateChanged(cb: (user: User) => void) {
    this.firebaseAuthService.onAuthStateChanged(cb)
  }

  public async loginWithInviteKey(inviteKey: string) {
    const { data } = await this.apiService.loginWithInviteKey(inviteKey)
    await this.firebaseAuthService.loginWithInviteKey(data.fbCustomToken)
  }

  public async reauthenticateWithInviteKey() {
    const accessToken = await this.firebaseAuthService.getValidAccessToken()
    const freshInviteToken = await this.apiService.getInviteKey(accessToken)

    await this.loginWithInviteKey(freshInviteToken)
  }

  public async verifyPasswordResetCode(code: string) {
    return this.firebaseAuthService.verifyPasswordResetCode(code)
  }

  public async confirmPasswordReset(resetCode: string, newPassword: string) {
    return this.firebaseAuthService.confirmPasswordReset(resetCode, newPassword)
  }

  public async resetPassword(email: string) {
    return this.firebaseAuthService.resetPassword(email)
  }

  public async login(email: string, password: string) {
    await this.firebaseAuthService.login(email, password)
  }

  public async loginWithPhoneNumber(
    phoneNumber: string,
    applicationVerifier: RecaptchaVerifier,
  ): Promise<ConfirmationResult> {
    const doesUserExist = await this.apiService.checkUserByPhoneNumber(
      phoneNumber,
    )

    if (!doesUserExist) {
      throw {
        code: AuthErrorCodes.USER_DELETED,
        message: Localization.translator.userNotFoundError,
      }
    }

    return this.firebaseAuthService.loginWithPhoneNumber(
      phoneNumber,
      applicationVerifier,
    )
  }

  public async loginWithProvider(
    providerId: string,
    providerType: string,
  ): Promise<void> {
    return await this.firebaseAuthService.loginWithProvider(
      providerId,
      providerType,
    )
  }

  public updatePassword(newPassword: string) {
    return this.firebaseAuthService.updatePassword(newPassword)
  }

  public logout() {
    return this.firebaseAuthService.logout()
  }

  public async savePassword(password: string) {
    try {
      await this.firebaseAuthService.updatePassword(password)
    } catch (e) {
      if (e.code === AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN) {
        await this.reauthenticateWithInviteKey()
        return this.savePassword(password)
      }

      throw e
    }

    // Firebase rejects existing refreshToken after auth sensitive operation (e.g. password updating)
    // Post re-authentication is required for further interaction with API without re-logging
    await this.reauthenticateWithInviteKey()
  }

  public async linkEmailProvider(email: string, password: string) {
    try {
      await this.firebaseAuthService.linkEmailProvider(email, password)
    } catch (e) {
      if (e.code === AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN) {
        await this.reauthenticateWithInviteKey()
        return this.linkEmailProvider(email, password)
      }

      if (e.code === AuthErrorCodes.PROVIDER_ALREADY_LINKED) {
        await this.reauthenticateWithInviteKey()
        await this.unlinkProvider(FIREBASE_PASSWORD_PROVIDER_ID)
        return this.linkEmailProvider(email, password)
      }

      throw e
    }

    // Firebase rejects existing refreshToken after auth sensitive operation (e.g. provider linking)
    // Post re-authentication is required for further interaction with API without re-logging
    await this.reauthenticateWithInviteKey()
  }

  public unlinkProvider(providerId: string): Promise<User> {
    return this.firebaseAuthService.unlinkProvider(providerId)
  }

  public async resendInviteKey(expiredInviteKey: string): Promise<void> {
    return this.apiService.resendInviteKey(expiredInviteKey)
  }
}
