import { FirebaseError } from 'firebase/app'
import * as fbAuth from 'firebase/auth'
import { action, computed, observable } from 'mobx'

import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import AuthenticationStore from '~/client/src/shared/stores/domain/Authentication.store'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'

import { LocalStorageKey } from '../../enums/LocalStorageKey'
import Localization from '../../localization/LocalizationManager'
import { getFormattedPhoneNumberForSubmission } from '../../utils/phoneNumberHelpers'

// common for all tenants
export enum SignInMethod {
  PhoneNumber = 'phone-number',
  EmailPassword = 'email-password',
  Procore = 'procore',
}

const relatedEvents = [
  e.LOGIN_PROCORE,
  e.LOGIN_WITH_INVITE_KEY,
  e.LOGIN_WITH_PHONE_NUMBER,
  e.LOGIN_WITH_EMAIL_AND_PASSWORD,
]

export const OTPLength = 6

export default class LoginViewStore {
  @observable public errorMessage: string = ''
  @observable public signInMethod: SignInMethod = SignInMethod.EmailPassword

  @observable public shouldShowCodeInput: boolean = false
  @observable public isCodeBeingVerified: boolean = false
  @observable public codeVerificationErrorMsg: string = ''

  public confirmationResult: fbAuth.ConfirmationResult = null
  public enteredPhoneNumber: string = ''

  public constructor(
    protected readonly eventsStore: EventsStore,
    protected readonly auth: AuthenticationStore,
    protected readonly commonStore: CommonStore,
  ) {
    this.signInMethod =
      (localStorage.getItem(
        LocalStorageKey.DefaultLoginMethod,
      ) as SignInMethod) || SignInMethod.EmailPassword
  }

  @computed
  public get isLoading(): boolean {
    const { loading } = this.eventsStore.appState
    return relatedEvents.some(evt => loading.get(evt))
  }

  @action.bound
  public switchSignInMethod(newSignInMethod: SignInMethod) {
    this.resetErrorMessage()
    this.signInMethod = newSignInMethod
  }

  @action.bound
  public signInWithEmailPassword(email: string, password: string) {
    this.resetErrorMessage()

    const trimmedEmail = email.trim()
    this.eventsStore.dispatch(
      e.LOGIN_WITH_EMAIL_AND_PASSWORD,
      trimmedEmail,
      password,
      this.loginSuccessful,
      this.onSignWithEmailPasswordError.bind(this, trimmedEmail),
    )
  }

  @action.bound
  public async signInWithPhoneNumber(
    phoneNumber: string,
    recaptchaVerifier: fbAuth.RecaptchaVerifier,
    recaptchaWidgetId: number,
  ) {
    this.resetErrorMessage()

    this.enteredPhoneNumber = phoneNumber

    this.eventsStore.dispatch(
      e.LOGIN_WITH_PHONE_NUMBER,
      getFormattedPhoneNumberForSubmission(phoneNumber),
      recaptchaVerifier,
      this.onSignInWithPhoneNumberSuccess,
      this.onSignInWithPhoneNumberError.bind(this, recaptchaWidgetId),
    )
  }

  @action.bound
  public onSignInWithPhoneNumberError(
    recaptchaWidgetId: number,
    error: FirebaseError,
  ) {
    window.grecaptcha.reset(recaptchaWidgetId)
    this.setErrorMessage(error)
  }

  @action.bound
  public onSignInWithPhoneNumberSuccess(
    confirmationResult: fbAuth.ConfirmationResult,
  ) {
    this.confirmationResult = confirmationResult
    this.showVerificationCodeModal()
  }

  @action.bound
  public async handleCodeInputComplete(otp: string) {
    this.isCodeBeingVerified = true
    try {
      await this.confirmationResult.confirm(otp)
    } catch (e) {
      this.codeVerificationErrorMsg = e.message
      return
    } finally {
      this.isCodeBeingVerified = false
    }

    this.loginSuccessful()
  }

  @action.bound
  public handleCodeInputChange(value: string) {
    this.resetCodeVerificationErrorMessage()

    if (value.length >= OTPLength) {
      this.handleCodeInputComplete(value)
    }
  }

  @action.bound
  public showVerificationCodeModal() {
    this.shouldShowCodeInput = true
  }

  @action.bound
  public hideVerificationCodeModal() {
    this.shouldShowCodeInput = false
    this.codeVerificationErrorMsg = ''
    this.enteredPhoneNumber = ''
  }

  @action.bound
  public resetErrorMessage() {
    this.errorMessage = ''
  }

  @action.bound
  public resetCodeVerificationErrorMessage() {
    this.codeVerificationErrorMsg = ''
  }

  @action.bound
  public async signInWithCustomProvider(
    providerId: string,
    providerType: string,
  ) {
    this.resetErrorMessage()

    try {
      await this.auth.loginWithProvider(providerId, providerType)

      this.loginSuccessful()
    } catch (error) {
      this.setErrorMessage(error)
    }
  }

  public procoreLogin = () => {
    this.eventsStore.dispatch(e.LOGIN_PROCORE, this.setDefaultLoginMethod)
  }

  public loginSuccessful = () => {
    this.setDefaultLoginMethod()
    this.commonStore.displayAuthSuccessRoute()
  }

  public goToResetPasswordView = (email: string) => {
    this.commonStore.displayResetPasswordView('', { email })
  }

  private onSignWithEmailPasswordError = (
    email: string,
    error: FirebaseError,
  ) => {
    this.setErrorMessage(error)

    if (error.code === fbAuth.AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
      this.eventsStore.dispatch(
        e.REQUEST_RESET_PASSWORD,
        email,
        () => {
          this.errorMessage =
            Localization.translator.tooManyUnsuccessfulLoginAttempts +
            ' ' +
            Localization.translator.passwordResetLinkIsSentToMailbox
        },
        (errorMsg: string) => (this.errorMessage = errorMsg),
      )
    }
  }

  private setDefaultLoginMethod = () => {
    localStorage.setItem(LocalStorageKey.DefaultLoginMethod, this.signInMethod)
  }

  private setErrorMessage(error: FirebaseError) {
    // TODO:3045 There are many more errors related to authorization
    // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Error
    // Let's skip custom localization of error for now to get more useful info on the current stage
    this.errorMessage = error.message
  }
}
