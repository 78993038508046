import { action, observable } from 'mobx'

import { IPermitTypeField } from '~/client/graph/types.generated'

export default class FormDropdownOptionsModalStore {
  @observable
  public values: string[] = []

  public constructor(
    private selectedField: IPermitTypeField,
    values: string[],
  ) {
    this.values = [...values]
  }

  @action.bound
  private addValue(value: string): void {
    this.values.push(value)
  }

  @action.bound
  private removeValue(value: string): void {
    this.values = this.values.filter(v => v !== value)
  }

  @action.bound
  public onOptionClick(value: string): void {
    if (this.isMultiple) this.onMultipleSelectClick(value)
    else this.onSingleSelectClick(value)
  }

  @action.bound
  public onSingleSelectClick(value: string): void {
    if (!value) {
      this.values = []
      return
    }

    if (this.values.includes(value)) this.values = []
    else this.values = [value]
  }

  @action.bound
  public onMultipleSelectClick(value: string): void {
    if (!value) {
      this.values = []
      return
    }
    if (this.values.includes(value)) this.removeValue(value)
    else this.addValue(value)
  }

  @action.bound
  public setSelectedValues(values: string[]): void {
    this.values = values
  }

  @action.bound
  public reset(selectedField: IPermitTypeField, values: string[]): void {
    this.selectedField = selectedField

    this.values = [...values]
  }

  private get isMultiple(): boolean {
    return this.selectedField?.isMultiple
  }
}
