import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import {
  DIGITS_PATTERN,
  NO_NUMBERS_DASH_DOT_CHAR_PATTERN,
  NO_NUMBERS_DASH_DOT_PATTERN,
  VALID_NUMBER_PATTERN,
} from '~/client/src/shared/utils/regExpPatterns'
import {
  DASH,
  EMPTY_STRING,
  PERIOD,
} from '~/client/src/shared/utils/usefulStrings'

import DeliveryControlTypes from '../../enums/DeliveryControlTypes'
import { TagType } from '../../enums/TagType'
import BaseStruxhubInput, { ISharedProps } from './BaseStruxhubInput'

// localization: no text to translate
const DEFAULT_INPUT_DELAY = 1000

export interface IStruxhubInputProps extends ISharedProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void

  isAlternativeNumberMode?: boolean
  withDelay?: boolean
  inputDelay?: number
  tagType?: TagType
  type?: string
  id?: string
  name?: string
  placeholder?: string
  min?: number
  max?: number
  minLength?: number
  maxLength?: number
  step?: number
  autoComplete?: string
  autoFocus?: boolean
  pattern?: string
  negativeOrDecimal?: boolean
  disableScroll?: boolean
  selectOnFocus?: boolean
  customRightIcon?: React.ReactNode
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onFocus?: () => void
  onBlur?: () => void
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void
  children?: (onFocus: () => void, onBlur: () => void) => React.ReactNode
}

@observer
export default class StruxhubInput extends React.Component<IStruxhubInputProps> {
  public static defaultProps = {
    type: 'text',
    negativeOrDecimal: false,
    disableScroll: true,
  }
  @observable private typingTimer: number
  @observable private value: string

  public constructor(props: IStruxhubInputProps) {
    super(props)

    this.setValue()
  }

  private inputRef = React.createRef<HTMLInputElement>()

  private get shouldPreventNegativeOrDecimal(): boolean {
    return this.isNumberType && !this.props.negativeOrDecimal
  }

  private get isNumberType(): boolean {
    return this.props.type === DeliveryControlTypes.NUMBER
  }

  private get isAlternativeNumberMode(): boolean {
    return this.isNumberType && this.props.isAlternativeNumberMode
  }

  private get formattedInputType(): string {
    return this.isAlternativeNumberMode ? 'text' : this.props.type
  }

  private get formattedInputMode(): 'numeric' | undefined {
    if (this.shouldPreventNegativeOrDecimal) {
      return 'numeric'
    }
  }

  public componentDidUpdate(): void {
    this.setValue()
  }

  @action.bound
  private setValue(): void {
    if (this.props.value !== this.value) {
      clearTimeout(this.typingTimer)
      this.value = this.props.value
    }
  }

  public render() {
    const { autoFocus, children } = this.props

    return (
      <BaseStruxhubInput {...this.props}>
        {(isValueInvalid, isInFocus, onFocus, onBlur) =>
          children
            ? this.renderSelectWrapper(
                isValueInvalid,
                isInFocus,
                onFocus,
                onBlur,
              )
            : this.renderInput(
                isValueInvalid,
                isInFocus,
                onFocus,
                onBlur,
                autoFocus,
              )
        }
      </BaseStruxhubInput>
    )
  }

  private renderSelectWrapper(
    isValueInvalid: boolean,
    isInFocus: boolean,
    onFocus: () => void,
    onBlur: () => void,
  ): JSX.Element {
    const { id, children } = this.props

    return (
      <div
        id={id}
        className={classList({
          'row y-center full-width bg-palette-brand-lightest no-outline brada4 py7 pl8 pr24 h40 text extra-large line-24':
            true,
          'ba-light-blue': isInFocus && !isValueInvalid,
          'ba-palette-brand-lighter': !isInFocus && !isValueInvalid,
          'ba-red': isValueInvalid,
        })}
      >
        {children(onFocus, onBlur)}
      </div>
    )
  }

  private renderInput(
    isValueInvalid: boolean,
    isInFocus: boolean,
    onFocus: () => void,
    onBlur: () => void,
    shouldForceFocus?: boolean,
  ) {
    const {
      id,
      isRequired,
      isDisabled,
      isReadOnly,
      name,
      minLength,
      maxLength,
      step,
      placeholder,
      autoComplete,
      pattern,
      withDelay,
      onKeyUp,
      onKeyDown,
    } = this.props

    return (
      <input
        className={classList({
          'bare-input-appearance full-width bg-palette-brand-lightest no-outline brada4 py7 pl8 pr24 h40 text extra-large line-24':
            true,
          'ba-light-blue': isInFocus && !isValueInvalid,
          'ba-palette-brand-lighter': !isInFocus && !isValueInvalid,
          'ba-red': isValueInvalid,
        })}
        id={id}
        ref={this.inputRef}
        autoFocus={shouldForceFocus}
        onFocus={this.onFocus.bind(this, onFocus)}
        onBlur={this.onBlur.bind(this, onBlur)}
        type={this.formattedInputType}
        inputMode={this.formattedInputMode}
        name={name}
        value={this.value}
        minLength={minLength}
        maxLength={maxLength}
        step={step}
        placeholder={placeholder}
        autoComplete={autoComplete}
        pattern={pattern}
        required={isRequired}
        disabled={isDisabled}
        readOnly={isReadOnly}
        onChange={
          withDelay
            ? this.onChangeWithDelay.bind(this)
            : this.onChange.bind(this)
        }
        onInput={this.onInput.bind(this)}
        onKeyPress={this.onKeyPress.bind(this)}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onPaste={this.onPaste}
      />
    )
  }

  private onChangeWithDelay(event: React.ChangeEvent<HTMLInputElement>) {
    clearTimeout(this.typingTimer)
    this.value = event?.target?.value
    this.typingTimer = window.setTimeout(
      () => this.onChange(event),
      this.props.inputDelay || DEFAULT_INPUT_DELAY,
    )
  }

  private onChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.value = event?.target?.value
    this.preventExceedingMaxAndMin(event)
    this.props.onChange?.(event)
  }

  private preventSpecialCharacters(
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void {
    if (!DIGITS_PATTERN.test(event.key)) {
      event.preventDefault()
    }
  }

  private preventNegativeOrDecimal(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    const el = event.target

    el.value =
      !!el.value && Math.abs(Number(el.value)) >= 0
        ? Math.trunc(Math.abs(Number(el.value))).toString()
        : null
  }

  private preventExceedingMaxAndMin(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    if (!this.isNumberType && this.props.type !== DeliveryControlTypes.DATE) {
      return
    }
    const { max, min } = this.props
    const el = event.target
    if (!!min && isFinite(min) && +el.value < min) {
      el.value = min.toString()
    }
    if (!!max && isFinite(max) && +el.value > max) {
      el.value = max.toString()
    }
  }

  private disableScrollOnField(): void {
    if (this.isNumberType) {
      this.inputRef.current.addEventListener(
        'wheel',
        function (e) {
          e.preventDefault()
        },
        { passive: false },
      )
    }
  }

  private onFocus(baseOnFocus: () => void) {
    const { disableScroll, selectOnFocus, onFocus } = this.props
    if (disableScroll) {
      Promise.resolve().then(() => this.disableScrollOnField())
    }
    if (selectOnFocus) {
      Promise.resolve().then(() => this.inputRef.current.select())
    }
    baseOnFocus?.()
    onFocus?.()
  }

  private onBlur(baseOnBlur: () => void) {
    baseOnBlur?.()
    this.props.onBlur?.()
  }

  private onInput(event: React.ChangeEvent<HTMLInputElement>) {
    if (this.shouldPreventNegativeOrDecimal) {
      this.preventNegativeOrDecimal(event)
    } else if (this.isAlternativeNumberMode) {
      this.formatAlternativeNumberIfNeed(event)
    }
    this.props.onInput?.(event)
  }

  private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (this.shouldPreventNegativeOrDecimal) {
      this.preventSpecialCharacters(event)
    } else if (this.isAlternativeNumberMode) {
      this.preventIfNotNumber(event)
    }
    this.props.onKeyPress?.(event)
  }

  private onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    if (
      this.isAlternativeNumberMode &&
      !VALID_NUMBER_PATTERN.test(event.clipboardData.getData('Text'))
    ) {
      return event.preventDefault()
    }

    this.props.onPaste?.(event)
  }

  private formatAlternativeNumberIfNeed = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (NO_NUMBERS_DASH_DOT_PATTERN.test(event.target.value)) {
      event.target.value = event.target.value.replace(
        NO_NUMBERS_DASH_DOT_PATTERN,
        EMPTY_STRING,
      )
    }
  }

  private preventIfNotNumber = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const { key } = event

    if (NO_NUMBERS_DASH_DOT_CHAR_PATTERN.test(key)) {
      return event.preventDefault()
    }

    const inputEl = this.inputRef.current
    const caretPosition = inputEl.selectionStart
    const value = inputEl.value

    if (key === DASH && (caretPosition || value.includes(DASH))) {
      return event.preventDefault()
    }

    if (
      key === PERIOD &&
      (!caretPosition ||
        (value[0] === DASH && caretPosition === 1) ||
        value.includes(PERIOD))
    ) {
      return event.preventDefault()
    }
  }
}
