import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import * as Icons from '~/client/src/shared/components/Icons'
import { Header, View } from '~/client/src/shared/components/Layout'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import { ITag } from '~/client/src/shared/models/Tag'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectRolesStore from '~/client/src/shared/stores/domain/ProjectRoles.store'
import ProjectTeamsStore from '~/client/src/shared/stores/domain/ProjectTeams.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { getBandTitleByTagType } from '~/client/src/shared/utils/TagHelper'
import { NOOP } from '~/client/src/shared/utils/noop'

import User from '../../models/User'
import InitialState from '../../stores/InitialState'
import ProjectDefaultTeamsStore from '../../stores/domain/ProjectDefaultTeams.store'
import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'
import UserProjectsStore from '../../stores/domain/UserProjects.store'
import getCompanyTypeTranslate from '../../utils/getCompanyTypeTranslate'
import BaseCompactPopup from '../BaseCompactPopup/BaseCompactPopup'
import Checkbox from '../Checkbox/Checkbox'
import CompactGroupByPicker, {
  IGroupByOption,
} from '../CompactGroupByPicker/CompactGroupByFilter'
import { Loader } from '../Loader'
import TagIconByTagType from '../TagIconByTagType/TagIconByTagType'
import UsersDirectoryStore from './UsersDirectory.store'
import FilterCounter from './components/FilterCounter/FilterCounter'
import GroupByModal from './components/GroupByModal/GroupByModal'
import SearchBar from './components/SearchBar/SearchBar'
import TagFilter from './components/TagFilter/TagFilter'
import TagFilterStore from './components/TagFilter/TagFilter.store'

import './UsersDirectory.scss'

interface IProps {
  onUserRowClick?: (userDto: User) => void
  className?: string
  isSelectionMode?: boolean
  shouldSelectOnlyUsers?: boolean
  onItemSelect?: (items: ITag[]) => void
  shouldHideFilters?: boolean
  shouldUseGroupByModal?: boolean
  shouldHideSelectAllBtn?: boolean
  selectedUsers?: User[]
  onUserRemoveClick?: (userId: string) => void
  onUserAddClick?: (userId: string) => void
  shouldHideFilterBar?: boolean
  shouldShowSelectAll?: boolean
  searchTypes?: TagType[]
  additionalBandsPredicate?: (a: ITag, b: ITag) => number
  userPredicate?: (userDto: User) => boolean
  isUserOnline?: (userId: string) => boolean
  onHeaderCloseClick?: () => void
  shouldUseAllProjectMembers?: boolean
  canBandsBeCollapsed?: boolean
  applyButton?: JSX.Element
  availableGroupingTypes?: TagType[]

  externalSearchQuery?: string
  useExternalSearch?: boolean

  tagsStore?: TagsStore
  projectRolesStore?: ProjectRolesStore
  projectTeamsStore?: ProjectTeamsStore
  projectDefaultTeamsStore?: ProjectDefaultTeamsStore
  companiesStore?: CompaniesStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  state?: InitialState
}

const relatedEvents = [
  e.GET_PROJECT_MEMBERS,
  e.LOAD_AND_LISTEN_TO_COMPANIES,
  e.LOAD_AND_LISTEN_TO_PROJECT_ROLES,
  e.LOAD_AND_LISTEN_TO_PROJECT_TEAMS,
  e.LOAD_AND_LISTEN_TO_PROJECT_TRADES,
]

@inject(
  'tagsStore',
  'projectRolesStore',
  'projectTeamsStore',
  'projectDefaultTeamsStore',
  'companiesStore',
  'userProjectsStore',
  'projectMembersStore',
  'state',
)
@observer
export default class UsersDirectory extends React.Component<IProps> {
  public static defaultProps = {
    onUserRowClick: NOOP,
    onItemSelect: NOOP,
    onUserRemoveClick: NOOP,
    onUserAddClick: NOOP,
    selectedUsers: [],
  }

  public static readonly tagIconSize: number = 18

  private readonly store: UsersDirectoryStore = null
  private readonly filterStore: TagFilterStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new UsersDirectoryStore(
      props.tagsStore,
      props.projectRolesStore,
      props.projectTeamsStore,
      props.projectDefaultTeamsStore,
      props.companiesStore,
      props.userProjectsStore,
      props.onItemSelect,
      props.selectedUsers,
      props.projectMembersStore,
      props.searchTypes,
      props.additionalBandsPredicate,
      props.userPredicate,
      props.shouldUseAllProjectMembers,
    )

    this.filterStore = new TagFilterStore(
      props.tagsStore,
      this.store.handleFiltersApplying,
    )
  }

  private get isLoading(): boolean {
    const { loading } = this.props.state
    return relatedEvents.some(re => loading.get(re))
  }

  public componentDidMount() {
    if (this.props.availableGroupingTypes) {
      this.store.availableGroupingTypes = this.props.availableGroupingTypes
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.externalSearchQuery !== prevProps.externalSearchQuery) {
      this.store.changeSearchQuery(this.props.externalSearchQuery)
    }

    if (this.props.selectedUsers !== prevProps.selectedUsers) {
      this.store.init(this.props.selectedUsers)
    }
  }

  public render() {
    if (this.isLoading) {
      return <Loader size={40} hint={Localization.translator.loading} />
    }

    const {
      className = '',
      shouldUseGroupByModal,
      onHeaderCloseClick,
      children,
      applyButton,
    } = this.props

    const {
      _searchQuery,
      isDefaultMode,
      isSingleBandMode,
      isSearchMode,
      changeSearchQuery,
      resetSearch,
      getBandItems,
      shouldShowGroupByPopup,
      hideGroupByPopup,
      changeGroupingBandType,
      groupingBandType,
      pivotalSearchTag,
      resetPivotalSearchTag,
      resetSearchQuery,
    } = this.store

    return (
      <View className={`user-directory ${className}`}>
        {this.renderTagFilter()}
        {!shouldUseGroupByModal && (
          <CompactGroupByPicker
            onHide={hideGroupByPopup}
            isShown={shouldShowGroupByPopup}
            selectedMode={groupingBandType}
            onGroupByChange={changeGroupingBandType}
            groupByOptions={this.groupByOptions}
          />
        )}
        <Header>
          <div className="row x-between no-flex-children">
            {!!onHeaderCloseClick && (
              <span
                className="text large blue-highlight bold no-grow lp05 pa12 ml8 mb5 pointer mw65"
                onClick={onHeaderCloseClick}
              >
                {Localization.translator.close}
              </span>
            )}
            {applyButton && <span className="pa12 mr8 mb5">{applyButton}</span>}
          </div>
          <div className="col">
            {!this.props.useExternalSearch && (
              <div className="row px12 py12">
                <SearchBar
                  value={_searchQuery}
                  onChange={changeSearchQuery}
                  onReset={resetSearchQuery}
                  pivotalTag={pivotalSearchTag}
                  onTagClick={resetPivotalSearchTag}
                />
                {!isDefaultMode && (
                  <span
                    className="text large blue-highlight bold no-grow lp05 ml8"
                    onClick={resetSearch}
                  >
                    {Localization.translator.clear}
                  </span>
                )}
              </div>
            )}

            {isDefaultMode && (
              <>
                {this.renderFilterBar()}
                {this.renderUserCount()}
              </>
            )}
          </div>
        </Header>
        <div className="overflow-auto user-directory-content">
          {isDefaultMode && (
            <>
              {children}
              {this.renderUsersByBands()}
            </>
          )}
          {isSearchMode && this.renderSearchResByType()}
          {isSingleBandMode &&
            this.renderBandWithUsers(
              pivotalSearchTag,
              getBandItems(pivotalSearchTag),
            )}
        </div>
      </View>
    )
  }

  public renderUsersByBands(): JSX.Element[] {
    const { groupingBandType, filteredUsersByGroupedBands, getBand } =
      this.store

    return Object.keys(filteredUsersByGroupedBands).map(bandId => {
      const { users, tagType } = filteredUsersByGroupedBands[bandId]

      const bandType =
        groupingBandType === TagType.Team ? tagType : groupingBandType

      const band = getBand(bandType, bandId)

      return this.renderBandWithUsers(band, users)
    })
  }

  private renderSearchResByType(): JSX.Element[] {
    const { isUserOnline } = this.props
    const { searchResByTypes, handleOnBandClick, getBandItemsCount } =
      this.store

    return Object.keys(searchResByTypes).map(tagType => {
      const items: any[] = searchResByTypes[tagType] || []
      const isUserTag = tagType === TagType.User

      return (
        <React.Fragment key={tagType}>
          <div className="row bg-palette-brand-lightest by-light-input-border px12 py8 text large bold capitalize">
            {getBandTitleByTagType(tagType as TagType)}
          </div>
          {items.map(item => {
            const clickHandler = isUserTag
              ? this.handleOnUserRowClick
              : handleOnBandClick

            return (
              <div
                key={item.id}
                className="row pa12 item-row"
                onClick={clickHandler.bind(null, item)}
              >
                {this.renderCheckbox(item.id, tagType)}
                {isUserTag ? (
                  <UserProfilePreview
                    user={item}
                    isOnline={isUserOnline && isUserOnline(item.id)}
                  />
                ) : (
                  <SitemapAttributeTag
                    dataObject={item as LocationAttributeBase}
                    contentContainerClassName="text-ellipsis"
                    shouldShowAsTag={false}
                    iconSize={UsersDirectory.tagIconSize}
                  >
                    <span>{item.name}</span>
                  </SitemapAttributeTag>
                )}

                {!isUserTag && (
                  <>
                    <span className="text light no-grow nowrap">
                      ({Localization.translator.xUsers(getBandItemsCount(item))}
                      )
                    </span>
                    <Icon icon={IconNames.CHEVRON_RIGHT} />
                  </>
                )}
              </div>
            )
          })}
        </React.Fragment>
      )
    })
  }

  private renderBandWithUsers(band: ITag, users: User[]): JSX.Element {
    const { canBandsBeCollapsed } = this.props
    const { isGroupingByCompany, collapsedBands, toggleBandCollapse } =
      this.store

    const isCollapsed = collapsedBands.get(band.id)

    return (
      <React.Fragment key={band.id}>
        <div className="row band-row bg-grey-scale-light bb-palette-grey bt-palette-grey px12 py8">
          {canBandsBeCollapsed && (
            <Icon
              icon={isCollapsed ? IconNames.CARET_UP : IconNames.CARET_DOWN}
              onClick={toggleBandCollapse.bind(null, band.id)}
              iconSize={UsersDirectory.tagIconSize}
              className="text white mr8 pointer"
            />
          )}
          {this.renderBandSelector(users)}
          <SitemapAttributeTag
            contentContainerClassName="text large ellipsis white"
            dataObject={band as LocationAttributeBase}
            shouldShowAsTag={false}
            iconSize={UsersDirectory.tagIconSize}
          >
            <span>{band.name}</span>
            {isGroupingByCompany && this.renderCompanyType(band.id)}
          </SitemapAttributeTag>

          <span className="text white no-grow nowrap">
            ({Localization.translator.xUsers(users.length)})
          </span>
        </div>
        {!isCollapsed &&
          users.map((user, index) => this.renderUserRow(user, index))}
      </React.Fragment>
    )
  }

  private renderUserRow(user: User, index: number): JSX.Element {
    const { isUserOnline } = this.props
    const isSelected = !!this.store.selectedItems.get(user.id)

    return (
      <div
        key={user.id}
        className={classList({
          'row pa12 item-row': true,
          'bt-palette-brand-lighter': !!index,
          selected: isSelected,
        })}
        onClick={this.handleOnUserRowClick.bind(null, user)}
      >
        {this.renderCheckbox(user.id, TagType.User)}
        <UserProfilePreview
          user={user}
          isOnline={isUserOnline && isUserOnline(user.id)}
        />
      </div>
    )
  }

  private renderCompanyType(companyId: string): JSX.Element {
    const company = this.props.companiesStore.getCompanyById(companyId)
    if (!company) {
      return null
    }
    const companyTypeTags = this.props.companiesStore.getCompanyTypeTagsByIds(
      company.typeTags,
    )

    return (
      company.typeTags.length && (
        <span className="pl8">
          ({companyTypeTags.map(tag => getCompanyTypeTranslate(tag.value))})
        </span>
      )
    )
  }

  private renderFilterBar(): JSX.Element {
    const { shouldHideFilters, shouldHideFilterBar } = this.props
    const { groupingBandType, showGroupByPopup } = this.store

    if (shouldHideFilterBar) {
      return null
    }

    return (
      <div className="px12 row x-end bg-palette-brand-lightest by-light-input-border py8">
        {!shouldHideFilters && (
          <>
            <div className="row">{this.renderFilterButtons()}</div>

            <div className="mx8 no-grow ba-none br-light-grey as-stretch" />
          </>
        )}
        <BaseActionButton
          isEnabled={true}
          title={getBandTitleByTagType(groupingBandType)}
          icon={<Icon icon={IconNames.BOX} />}
          onClick={showGroupByPopup}
          className={'filter-button ba-palette-brand-primary selected'}
        />
        {this.renderGroupByModal()}
      </div>
    )
  }

  private renderFilterButtons() {
    const { state } = this.props
    const { user: currentUser } = state

    const userId = currentUser.id
    const myCompanyId = state.userActiveProjectSettings.companyId

    const buttons = [
      {
        icon: this.renderFilterActionButtonIcon(),
        text: Localization.translator.filters,
        onClick: this.store.showFilterPopup,
        isSelected: false,
      },
      {
        icon: <Icons.User />,
        text: Localization.translator.me,
        onClick: () => {
          this.filterStore.toggleTagById(userId)
          this.filterStore.applyFilters()
        },
        isSelected: this.filterStore.isTagApplied(userId),
      },
      {
        icon: <Icons.CompanyCompact />,
        text: Localization.translator.myCo,
        onClick: () => {
          this.filterStore.toggleTagById(myCompanyId)
          this.filterStore.applyFilters()
        },
        isSelected: this.filterStore.isTagApplied(myCompanyId),
      },
    ]

    return buttons.map(button => (
      <BaseActionButton
        key={button.text}
        isEnabled={true}
        title={button.text}
        icon={button.icon}
        onClick={button.onClick}
        isActive={button.isSelected}
        className={classList({
          'filter-button mr4': true,
          'ba-palette-brand-primary selected': button.isSelected,
        })}
      />
    ))
  }

  private renderUserCount(): JSX.Element {
    const { shouldShowSelectAll, isSelectionMode } = this.props

    const selectedCount = this.store.selectedItems.size
    const shouldShowSelectionHint = isSelectionMode && !!selectedCount

    return (
      <div className="pa12 text large grey-light capitalize row">
        {shouldShowSelectAll && (
          <Checkbox
            className="as-start"
            isChecked={this.store.areAllDisplayedUsersSelected}
            onClick={this.store.toggleSelectionForAllDisplayedUsers}
          />
        )}
        {Localization.translator.xUsersShowing(
          this.store.totalDisplayedUsersCount,
        )}
        {shouldShowSelectionHint && (
          <>
            <div className="ml8">{`/ ${selectedCount} ${Localization.translator.selected}`}</div>
            <div
              className="no-grow text blue large underline pointer"
              onClick={this.store.resetSelection}
            >
              {Localization.translator.reset}
            </div>
          </>
        )}
      </div>
    )
  }

  private renderFilterActionButtonIcon(): JSX.Element {
    const appliedFiltersCount = this.store.appliedFilters.length

    return appliedFiltersCount ? (
      <FilterCounter count={appliedFiltersCount} />
    ) : (
      <Icon icon={IconNames.FILTER} />
    )
  }

  private get groupByOptions(): IGroupByOption[] {
    return this.store.availableGroupingTypes.map(type => ({
      title: getBandTitleByTagType(type),
      groupByType: type,
      icon: <TagIconByTagType tagType={type} className="no-grow mr8" />,
      shouldRenderSeparator: true,
    }))
  }

  private renderTagFilter(): JSX.Element {
    const { shouldShowFilterPopup, appliedFilters } = this.store

    const {
      selectedCategoryId,
      headerTitle,
      applyFilters,
      resetSelectedCategory,
      isFilteringButtonEnabled,
    } = this.filterStore

    return (
      <BaseCompactPopup
        title={headerTitle}
        isShown={shouldShowFilterPopup}
        onHide={this.handleFilterHide}
        actionButtonTitle={Localization.translator.filter}
        isActionButtonEnabled={isFilteringButtonEnabled}
        onActionButtonClick={applyFilters}
        customLeftIcon={
          selectedCategoryId && (
            <Icon
              className="text blue-highlight"
              icon={IconNames.CHEVRON_LEFT}
              onClick={resetSelectedCategory}
            />
          )
        }
      >
        <TagFilter
          appliedFilters={appliedFilters}
          store={this.filterStore}
          availableTagTypes={this.store.availableGroupingTypes}
        />
      </BaseCompactPopup>
    )
  }

  private renderCheckbox(
    itemId: string,
    itemType: TagType | string,
  ): JSX.Element {
    const { isSelectionMode, shouldSelectOnlyUsers } = this.props
    const { selectedItems } = this.store

    if (shouldSelectOnlyUsers && itemType !== TagType.User) {
      return null
    }

    const isSelected = !!selectedItems.get(itemId)

    return (
      isSelectionMode && (
        <Checkbox
          className="as-start"
          isChecked={isSelected}
          onClick={this.handleToggleItemSelection.bind(
            null,
            itemId,
            itemType,
            isSelected,
          )}
        />
      )
    )
  }

  private renderGroupByModal(): JSX.Element {
    const { shouldUseGroupByModal } = this.props

    if (!shouldUseGroupByModal) {
      return null
    }

    const {
      shouldShowGroupByPopup,
      hideGroupByPopup,
      changeGroupingBandType,
      groupingBandType,
    } = this.store

    return (
      shouldShowGroupByPopup && (
        <GroupByModal
          onHide={hideGroupByPopup}
          selectedMode={groupingBandType}
          onGroupByChange={changeGroupingBandType}
          groupByOptions={this.groupByOptions}
        />
      )
    )
  }

  private renderBandSelector(users: User[]): JSX.Element {
    const { isSelectionMode, shouldHideSelectAllBtn } = this.props

    if (shouldHideSelectAllBtn || !isSelectionMode || !users?.length) {
      return null
    }

    const areAllUsersSelected = users?.every(
      u => !!this.store.selectedItems.get(u.id),
    )

    return (
      <Checkbox
        className="as-start"
        isChecked={areAllUsersSelected}
        onClick={this.handleBandSelection.bind(
          this,
          users,
          areAllUsersSelected,
        )}
        darkMode
      />
    )
  }

  private handleFilterHide = () => {
    this.filterStore.reset()
    this.store.hideFilterPopup()
  }

  private handleToggleItemSelection = (
    itemId: string,
    itemType: TagType,
    isSelected: boolean,
    e?: React.MouseEvent<HTMLElement>,
  ) => {
    e?.stopPropagation()

    this.handleUserAddOrRemove(itemId, itemType, isSelected)

    this.store.toggleItemSelection(itemId, itemType)
  }

  private handleOnUserRowClick = (userDto: User) => {
    this.props.onUserRowClick(userDto)
  }

  private handleUserAddOrRemove = (
    itemId: string,
    itemType: TagType,
    isSelected: boolean,
  ) => {
    if (itemType !== TagType.User) {
      return
    }

    if (isSelected) {
      return this.props.onUserRemoveClick(itemId)
    }

    this.props.onUserAddClick(itemId)
  }

  private handleBandSelection = (
    users: User[],
    areAllUsersSelected: boolean,
    e: React.MouseEvent<HTMLElement>,
  ) => {
    e.stopPropagation()

    this.store.setSelectionForUsersByIds(
      users.map(u => u.id),
      !areAllUsersSelected,
    )
  }
}
