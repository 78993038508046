import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import { TagType } from '~/client/src/shared/enums/TagType'
import { ITag } from '~/client/src/shared/models/Tag'
import User from '~/client/src/shared/models/User'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'

import UsersDirectory from '../../../../../../UsersDirectory/UsersDirectory'
import SitePermitCreationFormStore from '../../../../../SitePermitCreationForm.store'
import FormDropdownOptionsModalStore from '../FormDropdownOptionsModal.store'

// localization: no text to translate

interface IProps {
  store: SitePermitCreationFormStore
  selectionStore: FormDropdownOptionsModalStore

  projectMembersStore?: ProjectMembersStore
}

const searchTagTypes = [TagType.User, TagType.Company]

@inject('projectMembersStore')
@observer
export default class FormUserPicker extends React.Component<IProps> {
  public render() {
    const { isMultiple } = this.props.store.selectedField
    return (
      <UsersDirectory
        shouldSelectOnlyUsers
        shouldUseAllProjectMembers
        onUserRowClick={this.onUserClick}
        searchTypes={searchTagTypes}
        additionalBandsPredicate={this.companyBandPredicate}
        isSelectionMode={isMultiple}
        selectedUsers={this.selectedUsers}
        onItemSelect={this.onItemSelect}
      />
    )
  }

  private companyBandPredicate = (a: ITag, b: ITag): number => {
    if (a.type !== TagType.Company || b.type !== TagType.Company) {
      return 0
    }

    const { selectedPermitCompanyIds } = this.props.store
    if (!selectedPermitCompanyIds?.length) {
      return 0
    }

    if (selectedPermitCompanyIds.includes(a.id)) {
      return -1
    }

    if (selectedPermitCompanyIds.includes(b.id)) {
      return 1
    }
  }

  private onUserClick = ({ id }: User) => {
    this.props.selectionStore.onOptionClick(id)
  }

  private onItemSelect = (users: ITag[]) => {
    this.props.selectionStore.setSelectedValues(users.map(user => user.id))
  }

  @computed
  private get selectedUsers(): User[] {
    const { projectMembersStore } = this.props

    return this.props.selectionStore.values
      .map(id => projectMembersStore.getById(id))
      .filter(Boolean)
  }
}
