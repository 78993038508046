import { DocumentNode } from 'graphql'
import { isMobile, isTablet } from 'react-device-detect'
import ReactGA from 'react-ga4'

import {
  ContentObjectType,
  IQuery,
  IStatusUpdate,
  ISubscription,
  LocationType,
} from '~/client/graph'
import {
  GetActivitiesConfigurationsDocument,
  ListenToActivitiesConfigurationsDocument,
} from '~/client/graph/operations/generated/ActivitiesConfigurations.generated'
import {
  GetActivityAssignmentListDocument,
  ListenToActivityAssignmentsDocument,
} from '~/client/graph/operations/generated/ActivityAssignments.generated'
import {
  CompanyRelationshipsByScheduleIdDocument,
  ListenCompanyRelationshipsByScheduleIdDocument,
} from '~/client/graph/operations/generated/ActivityCompanies.generated'
import { ActivityFiltersSettingsByScheduleIdDocument } from '~/client/graph/operations/generated/ActivityFiltersSettings.generated'
import {
  GetActivityFollowingListDocument,
  ListenToActivityFollowingDocument,
} from '~/client/graph/operations/generated/ActivityFollowing.generated'
import {
  ActivityLocationsByProjectIdDocument,
  ListenActivityLocationsByProjectIdDocument,
} from '~/client/graph/operations/generated/ActivityLocation.generated'
import {
  GetActivityPresetListDocument,
  ListenToActivityPresetDocument,
} from '~/client/graph/operations/generated/ActivityPresets.generated'
import {
  GetAnnouncementAssignmentsListDocument,
  ListenToAnnouncementAssignmentsDocument,
} from '~/client/graph/operations/generated/AnnouncementAssignments.generated'
import {
  GetAnnouncementFollowingListDocument,
  ListenToAnnouncementFollowingDocument,
} from '~/client/graph/operations/generated/AnnouncementFollowings.generated'
import {
  GetAnnouncementsListDocument,
  ListenToAnnouncementsDocument,
} from '~/client/graph/operations/generated/Announcements.generated'
import {
  BasemapsByProjectIdDocument,
  ListenBasemapsByProjectIdDocument,
} from '~/client/graph/operations/generated/Basemaps.generated'
import { GetCastAssignmentListDocument } from '~/client/graph/operations/generated/CastAssignments.generated'
import { GetCastFollowingListDocument } from '~/client/graph/operations/generated/CastFollowing.generated'
import {
  GetClosureAssignmentsListDocument,
  ListenToClosureAssignmentsDocument,
} from '~/client/graph/operations/generated/ClosureAssignments.generated'
import {
  GetClosureFollowingListDocument,
  ListenToClosureFollowingDocument,
} from '~/client/graph/operations/generated/ClosureFollowing.generated'
import {
  GetClosuresListDocument,
  ListenToClosuresDocument,
} from '~/client/graph/operations/generated/Closures.generated'
import {
  CompaniesByProjectIdDocument,
  CompanyTypeTagsDocument,
  ListenCompaniesByProjectIdDocument,
  ListenCompanyTypeTagDocument,
} from '~/client/graph/operations/generated/Companies.generated'
import {
  ConfigurationByNameDocument,
  ProjectConfigurationByNameDocument,
} from '~/client/graph/operations/generated/Configurations.generated'
import {
  GetContentObjectListDocument,
  GetThreadListDocument,
  ListenThreadDocument,
  ListenToContentObjectDocument,
} from '~/client/graph/operations/generated/ContentObjects.generated'
import { GetActivityCustomFiltersByProjectDocument } from '~/client/graph/operations/generated/CustomActivityFilters.generated'
import { GetDeliveryCustomFiltersByProjectDocument } from '~/client/graph/operations/generated/CustomDeliveryFilters.generated'
import { GetWorkflowCustomFiltersByProjectDocument } from '~/client/graph/operations/generated/CustomWorkflowsFilter.generated'
import { GetDefaultPermitTypesDocument } from '~/client/graph/operations/generated/DefaultPermitType.generated'
import { DefaultTeamsDocument } from '~/client/graph/operations/generated/DefaultTeams.generated'
import { ListenDeliveriesByProjectIdDocument } from '~/client/graph/operations/generated/Deliveries.generated'
import {
  GetDeliveryAssignmentListDocument,
  ListenToDeliveryAssignmentsDocument,
} from '~/client/graph/operations/generated/DeliveryAssignments.generated'
import {
  GetDeliveryConfigurationsDocument,
  ListenToDeliveryConfigurationsDocument,
} from '~/client/graph/operations/generated/DeliveryConfigurations.generated'
import {
  GetDeliveryFieldsConfigurationsDocument,
  ListenToDeliveryFieldsConfigurationsDocument,
} from '~/client/graph/operations/generated/DeliveryFieldsConfigurations.generated'
import {
  GetDeliveryFollowingListDocument,
  ListenToDeliveryFollowingDocument,
} from '~/client/graph/operations/generated/DeliveryFollowing.generated'
import {
  GetFormsConfigurationsDocument,
  ListenToFormsConfigurationsDocument,
} from '~/client/graph/operations/generated/FormsConfigurations.generated'
import {
  GlobeViewsByProjectIdDocument,
  ListenGlobeViewItemsDataByProjectIdDocument,
  ListenGlobeViewsByProjectIdDocument,
} from '~/client/graph/operations/generated/GlobeViews.generated'
import { GetHierarchyConfigurationsDocument } from '~/client/graph/operations/generated/HierarchyConfigurations.generated'
import {
  ListenLocationsByProjectIdDocument,
  LocationsByProjectIdDocument,
} from '~/client/graph/operations/generated/LocationType.generated'
import {
  GetLogisticsConfigurationsDocument,
  ListenToLogisticsConfigurationsDocument,
} from '~/client/graph/operations/generated/LogisticsConfigurations.generated'
import {
  GetMaterialConfigurationDocument,
  ListenToMaterialConfigurationDocument,
} from '~/client/graph/operations/generated/MaterialConfiguration.generated'
import {
  GetMaterialCategoryListDocument,
  GetMaterialListDocument,
  ListenToMaterialCategoryDocument,
  ListenToMaterialDocument,
} from '~/client/graph/operations/generated/Materials.generated'
import {
  ListenMessagesByProjectIdDocument,
  MessagesByProjectIdDocument,
} from '~/client/graph/operations/generated/Messages.generated'
import { ListenNotificationsByProjectIdDocument } from '~/client/graph/operations/generated/Notifications.generated'
import {
  ListenOperationRuleByProjectIdDocument,
  OperationRulesByProjectIdDocument,
} from '~/client/graph/operations/generated/OperationRules.generated'
import {
  ListenPermitTypesByProjectIdDocument,
  PermitTypeListDocument,
} from '~/client/graph/operations/generated/PermitTypes.generated'
import {
  ListenPhotosByProjectIdDocument,
  PhotosByProjectIdDocument,
} from '~/client/graph/operations/generated/Photos.generated'
import {
  GetAvailableProjectsDocument,
  ListenToProjectDocument,
} from '~/client/graph/operations/generated/Project.generated'
import {
  GetProjectAddressDocument,
  ListenToProjectAddressDocument,
} from '~/client/graph/operations/generated/ProjectAddress.generated'
import {
  GetProjectColoringOptionsDocument,
  ListenToProjectColoringOptionsDocument,
} from '~/client/graph/operations/generated/ProjectColoringOptions.generated'
import {
  GetProjectHistoryDocument,
  ListenToProjectHistoryDocument,
} from '~/client/graph/operations/generated/ProjectHistory.generated'
import {
  GetProjectMaterialOptionsDocument,
  ListenToProjectMaterialOptionsDocument,
} from '~/client/graph/operations/generated/ProjectMaterialOptions.generated'
import {
  GetProjectStatusUpdateOptionsDocument,
  ListenToProjectStatusUpdateOptionsDocument,
} from '~/client/graph/operations/generated/ProjectStatusUpdateOptions.generated'
import {
  GetProjectTypeOptionsDocument,
  ListenToProjectTypeOptionsDocument,
} from '~/client/graph/operations/generated/ProjectTypeOptions.generated'
import {
  GetRecurringDeliveriesSettingsByIdDocument,
  ListenToRecurringDeliveriesSettingsDocument,
} from '~/client/graph/operations/generated/RecurringDeliveriesSettings.generated'
import { GetReportTemplateDocument } from '~/client/graph/operations/generated/Reports.generated'
import {
  ListenScanHistoriesByProjectIdDocument,
  ScanHistoriesByProjectIdDocument,
} from '~/client/graph/operations/generated/ScanHistory.generated'
import {
  ListenScannersByProjectIdDocument,
  ScannersByProjectIdDocument,
} from '~/client/graph/operations/generated/Scanners.generated'
import {
  GetActivitiesDocument,
  GetActivityCodeRelationshipsDocument,
  GetActivityCodeTypesDocument,
  GetActivityCodesDocument,
  GetActivityResourceRelationshipsDocument,
  GetCalendarsDocument,
  GetResourcesDocument,
  GetScheduleDocument,
  ListenToActivityCodeDocument,
  ListenToActivityCodeRelationshipDocument,
  ListenToActivityCodeTypeDocument,
  ListenToActivityDocument,
  ListenToActivityResourceRelationshipDocument,
  ListenToResourceDocument,
} from '~/client/graph/operations/generated/Schedule.generated'
import {
  GetSitePermitAssignmentListDocument,
  ListenToSitePermitAssignmentsDocument,
} from '~/client/graph/operations/generated/SitePermitAssignment.generated'
import {
  GetSitePermitBicInspectionListDocument,
  ListenToSitePermitBicInspectionDocument,
} from '~/client/graph/operations/generated/SitePermitBicInspections.generated'
import {
  GetSitePermitFollowingListDocument,
  ListenToSitePermitFollowingDocument,
} from '~/client/graph/operations/generated/SitePermitFollow.generated'
import {
  GetSitePermitInspectionChangeListDocument,
  GetSitePermitInspectionListDocument,
  ListenToSitePermitInspectionChangeDocument,
  ListenToSitePermitInspectionDocument,
} from '~/client/graph/operations/generated/SitePermitInspections.generated'
import {
  GetSitePermitStatusChangeListDocument,
  ListenToSitePermitStatusChangeDocument,
} from '~/client/graph/operations/generated/SitePermitStatusChanges.generated'
import {
  GetSitePermitListDocument,
  ListenToSitePermitDocument,
} from '~/client/graph/operations/generated/SitePermits.generated'
import {
  ListenSitemapItemsByProjectIdDocument,
  SitemapItemsByProjectIdDocument,
} from '~/client/graph/operations/generated/SitemapItems.generated'
import {
  ListenSitemapItemDataByProjectIdDocument,
  ListenSitemapsByProjectIdDocument,
  SitemapsByProjectIdDocument,
} from '~/client/graph/operations/generated/Sitemaps.generated'
import {
  GetStatusUpdatesDocument,
  ListenToStatusUpdateDocument,
} from '~/client/graph/operations/generated/StatusUpdate.generated'
import {
  ListenTeamsByProjectIdDocument,
  TeamsByProjectIdDocument,
} from '~/client/graph/operations/generated/Teams.generated'
import {
  ListenTradesByProjectIdDocument,
  TradesByProjectIdDocument,
} from '~/client/graph/operations/generated/Trades.generated'
import {
  ListenUserProjectsByProjectIdDocument,
  UserProjectForActiveUserDocument,
  UserProjectsByProjectIdDocument,
} from '~/client/graph/operations/generated/UserProjects.generated'
import {
  ListenUserRolesByProjectIdDocument,
  UserRolesByProjectIdDocument,
} from '~/client/graph/operations/generated/UserRoles.generated'
import {
  GetProjectMembersDocument,
  GetUserByFirebaseIdDocument,
  ListenToUsersByProjectDocument,
} from '~/client/graph/operations/generated/Users.generated'
import {
  GetWeatherForecastsRangeDocument,
  ListenWeatherForecastsByProjectIdDocument,
} from '~/client/graph/operations/generated/WeatherForecast.generated'
import {
  GetZoneMapThresholdsDocument,
  ListenToZoneMapThresholdsDocument,
} from '~/client/graph/operations/generated/ZoneMapThresholds.generated'
import Config, { isRemote } from '~/client/src/shared/Config'
import ConfigurationName from '~/client/src/shared/enums/ConfigurationName'
import ActivityPreset from '~/client/src/shared/models/ActivityPreset'
import Project from '~/client/src/shared/models/Project'
import User from '~/client/src/shared/models/User'
import InitialState from '~/client/src/shared/stores/InitialState'
import RootStore from '~/client/src/shared/stores/Root.store'
import IAssociationDto from '~/client/src/shared/types/IAssociationDto'
import ILocationDto from '~/client/src/shared/types/ILocationDto'

import commonRoutes, {
  UrlParamKey,
  getInitProjectCodeFromPath,
  getRedirect,
} from '../../constants/commonRoutes'
import { flaggedFeaturesList } from '../../enums/FlaggedFeatures'
import IUserPreferences from '../../interfaces/IUserPreferences'
import Localization from '../../localization/LocalizationManager'
import UserProject from '../../models/UserProject'
import { listToMap } from '../../utils/converters'
import getIntegrationsConfigName from '../../utils/getIntegrationsConfigName'
import { IEvent } from './BaseEvents.store'
import { GET_ACTIVITY_ACTUAL_DATES } from './EffectsProcessors/WorkerProcessor/workerConstants'
import EventsStore from './Events.store'
import registerCommonChangeEvents from './commonChangeEvents'
import * as e from './eventConstants'
import EventTypes from './eventTypes'
import {
  emptyHandler,
  handleCompleteRequest,
  handleReportError,
  handleRequestError,
} from './handlers'

export default function (eventsStore: EventsStore, rootStore: RootStore) {
  eventsStore.on(e.START_ALL, (state, onAuthStateChangedCallback) => {
    state.onAuthStateChangedCallback = onAuthStateChangedCallback

    return {
      dispatchN: [
        [e.REDIRECT_TO_PROPER_SITE_VERSION],
        [e.DETECT_USER_LOCATION_INFO],
      ],
    }
  })

  eventsStore.on(e.REDIRECT_TO_PROPER_SITE_VERSION, state => {
    if (!Config.REDIRECT_CONFIG) {
      return {
        dispatch: [e.REQUEST_ERROR, e.REDIRECT_TO_PROPER_SITE_VERSION],
      }
    }

    const urlParams = new URLSearchParams(location.search)

    state.initProjectCode = getInitProjectCodeFromPath()

    const { redirectRequired, redirect, host } = getRedirect(
      Config.REDIRECT_CONFIG,
      isMobile,
      isTablet,
    )

    if (redirectRequired) {
      if (!redirect) {
        return {
          dispatch: [e.REQUEST_ERROR, e.REDIRECT_TO_PROPER_SITE_VERSION],
        }
      }

      return {
        cmd: {
          cmd: {
            execute: () => {
              location.assign(host + location.pathname + location.search)
              return Promise.resolve()
            },
          },
          onSuccess: [e.NO_EFFECT],
          onError: [e.NO_EFFECT],
        },
      }
    }

    const passwordResetCode = urlParams.get(UrlParamKey.PasswordResetCode)
    if (passwordResetCode) {
      // break chain
      return {
        dispatch: [e.VERIFY_PASSWORD_RESET_CODE, passwordResetCode],
      }
    }

    return {
      dispatch: [
        e.INIT_AUTH_USER,
        urlParams.get(UrlParamKey.InviteKey),
        urlParams.get(UrlParamKey.ForceInviteKey),
      ],
    }
  })

  eventsStore.on(
    e.INIT_AUTH_USER,
    (state, inviteKey?: string, forceInviteKey = false) => {
      state.loading.set(e.INIT_AUTH_USER, true)

      const { auth } = rootStore

      const authAwaiter = setTimeout(() => {
        state.onAuthStateChangedCallback()
        eventsStore.dispatch(e.LOGOUT)
      }, 10000)

      auth.onAuthStateChanged(user => {
        clearTimeout(authAwaiter)
        state.onAuthStateChangedCallback()

        // If the user sending an inviteKey wants to force the use of inviteKey
        // (overriding a previous session for instance) they can pass `forceInviteKey`
        // To prevent this overriding their own session (this callback won't fire again for the same user, locking them out),
        // compare the user's UID with that coming from the inviteKey.
        // When they match, abort `forceInviteKey` and let the usual logic take over.
        let shouldInitializeCurrentUser = true
        if (user && inviteKey && forceInviteKey) {
          // Ensure that this path isn't taken a second time
          forceInviteKey = false

          const inviteKeyData = JSON.parse(window.atob(inviteKey.split('.')[1]))

          // If UIDs don't match, skip this user and follow the inviteKey path
          shouldInitializeCurrentUser = user.uid === inviteKeyData.uid
        }

        if (shouldInitializeCurrentUser && user) {
          state.isUnauthorizedMode = false
          // override by a new callback that immediately runs => e.INIT_APP
          return auth.observeAuthState()
        }

        if (inviteKey) {
          eventsStore.dispatch(e.LOGIN_WITH_INVITE_KEY, inviteKey)
        } else {
          eventsStore.dispatchN([[e.TRY_UNAUTHORIZED_MODE], [e.INIT_COMPLETE]])
        }
      })
    },
  )

  eventsStore.on(e.TRY_UNAUTHORIZED_MODE_RESULT, state => {
    if (!state.isUnauthorizedMode) {
      rootStore.common.displayLoginView()
    }
  })

  eventsStore.on(
    e.LOGIN_WITH_PHONE_NUMBER,
    (state, phoneNumber, recaptchaVerifier, callbackSuccess, callbackError) => {
      state.loading.set(e.LOGIN_WITH_PHONE_NUMBER, true)

      return {
        cmd: {
          cmd: {
            execute: () =>
              rootStore.auth.loginWithPhoneNumber(
                phoneNumber,
                recaptchaVerifier,
              ),
          },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.LOGIN_WITH_PHONE_NUMBER,
            callbackSuccess,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.LOGIN_WITH_PHONE_NUMBER,
            callbackError,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.LOGIN_WITH_EMAIL_AND_PASSWORD,
    (
      state,
      email: string,
      password: string,
      callbackSuccess,
      callbackError,
    ) => {
      state.loading.set(e.LOGIN_WITH_EMAIL_AND_PASSWORD, true)

      return {
        cmd: {
          cmd: {
            execute: () => rootStore.auth.login(email, password),
          },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.LOGIN_WITH_EMAIL_AND_PASSWORD,
            callbackSuccess,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.LOGIN_WITH_EMAIL_AND_PASSWORD,
            callbackError,
          ],
        },
      }
    },
  )

  eventsStore.on(e.LOGIN_PROCORE, (state, onSuccessCb?: () => void) => {
    state.loading.set(e.LOGIN_PROCORE, true)

    return {
      cmd: {
        cmd: {
          execute: () => rootStore.procoreService.login(),
        },
        onSuccess: [e.LOGIN_PROCORE_SUCCESS, onSuccessCb],
        onError: [
          e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
          e.LOGIN_PROCORE,
          (error: Error) => alert(error.message),
        ],
      },
    }
  })

  eventsStore.on(
    e.LOGIN_PROCORE_SUCCESS,
    (state, onSuccessCb: () => void, inviteKey: string) => {
      state.loading.set(e.LOGIN_PROCORE, false)

      onSuccessCb?.()

      return {
        dispatch: [e.LOGIN_WITH_INVITE_KEY, inviteKey],
      }
    },
  )

  eventsStore.on(e.LOGIN_WITH_INVITE_KEY, (state, inviteKey: string) => {
    state.loading.set(e.LOGIN_WITH_INVITE_KEY, true)

    return {
      cmd: {
        cmd: {
          execute: () => rootStore.auth.loginWithInviteKey(inviteKey),
        },
        onSuccess: [
          e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
          e.LOGIN_WITH_INVITE_KEY,
          rootStore.common.displayAuthSuccessRoute,
        ],
        onError: [e.LOGIN_WITH_INVITE_KEY_ERROR, inviteKey],
      },
    }
  })

  eventsStore.on(
    e.LOGIN_WITH_INVITE_KEY_ERROR,
    (_, inviteKey: string, error: Error) => {
      rootStore.common.displayInfoPage(error?.message || '', inviteKey)

      return {
        dispatch: [e.INIT_COMPLETE],
      }
    },
  )

  eventsStore.on(e.DISPLAY_SAVE_PASSWORD, () => {
    const { common } = rootStore

    if (common.history.location.pathname !== commonRoutes.LOGIN) {
      common.displaySavePasswordView()
    }

    return {
      dispatch: [e.INIT_COMPLETE],
    }
  })

  /**
   * State app launch sequence by getting currently authenticated user from db.
   * Keeping the events decoupled (not chain dispatching) and only reliant on
   * state makes them easy to reason about.
   */
  eventsStore.on(e.INIT_APP, (state, user) => {
    state.loading.set(e.INIT_AUTH_USER, false)
    state.loading.set(e.INIT_APP, true)

    return {
      flow: {
        startWith: [[e.GET_CURRENT_USER, user.uid]],
        rules: [
          {
            when: 'on',
            event: e.GET_CURRENT_USER_SUCCESS,
            dispatchN: [
              [e.GET_DEFAULT_PERMIT_TYPES],
              [
                e.GET_CONFIGURATION,
                ConfigurationName.DefaultMandatoryFields,
                e.DEFAULT_MANDATORY_FIELDS_CONFIGURATION_RECEIVED,
              ],
              [
                e.GET_CONFIGURATION,
                ConfigurationName.DefaultHiddenFields,
                e.DEFAULT_HIDDEN_FIELDS_CONFIGURATION_RECEIVED,
              ],
              [e.GET_DEFAULT_TEAMS],
              [e.LOAD_AND_LISTEN_TO_COMPANY_TYPE_TAGS],
              [e.LOAD_PROJECTS],
            ],
          },
          {
            when: 'on',
            event: e.PROJECTS_RECEIVED,
            dispatch: [e.INIT_APP_2], // this chain will trigger GET_AUTH_USER_PROJECT in order to trigger INIT_APP_3 afterwards
          },
          {
            when: 'on',
            event: e.GET_AUTH_USER_PROJECT_SUCCESS,
            dispatch: [e.LOAD_AND_LISTEN_TO_PROJECT_TYPE_OPTIONS],
          },
          {
            when: 'on',
            event: e.PROJECT_TYPE_OPTIONS_RECEIVED,
            dispatch: [e.INIT_APP_3],
            shouldTerminate: true,
          },
          {
            when: 'on',
            event: e.REQUEST_ERROR,
            dispatch: [e.INIT_ERROR],
            shouldTerminate: true,
          },
        ],
      },
    }
  })

  eventsStore.on(e.GET_AUTH_USER_PROJECT, state => {
    state.loading.set(e.GET_AUTH_USER_PROJECT, true)
    state.userActiveProjectSettings = null

    return {
      graphQuery: {
        query: UserProjectForActiveUserDocument,
        variables: {
          projectId: state.activeProject.id,
          userId: state.user.id,
        },
        onSuccess: [e.GET_AUTH_USER_PROJECT_SUCCESS],
        onError: [e.REQUEST_ERROR, e.GET_AUTH_USER_PROJECT],
      },
    }
  })

  eventsStore.on(
    e.GET_AUTH_USER_PROJECT_SUCCESS,
    (state, { userProject }: IQuery) => {
      state.loading.set(e.GET_AUTH_USER_PROJECT, false)

      state.userActiveProjectSettings = UserProject.fromDto(userProject)
    },
  )

  eventsStore.on(e.LOAD_PROJECTS, state => {
    return {
      graphQuery: {
        query: GetAvailableProjectsDocument,
        variables: {
          userId: state.user.id,
        },
        onSuccess: [e.PROJECTS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_PROJECTS],
      },
    }
  })

  eventsStore.on(
    e.PROJECTS_RECEIVED,
    (_, { projects: { data: receivedProjects } }: IQuery) => {
      rootStore.projectsStore.receiveList(receivedProjects)
      rootStore.projectsStore.loadProjectAddresses()
    },
  )

  /**
   * Get users last viewed project (user.settings) or the users first project
   */
  eventsStore.on(e.INIT_APP_2, state => {
    state.loading.set(e.INIT_APP, false)
    state.loading.set(e.INIT_APP_2, true)

    if (shouldInterruptInitialization(rootStore)) {
      return { dispatch: [e.INIT_COMPLETE] }
    }

    const { getProjectByCode, lastViewedProjectCode } = rootStore.projectsStore

    const initProjectDto = getProjectByCode(state.initProjectCode)
    const lastViewedProjectDto = getProjectByCode(lastViewedProjectCode)
    const nextActiveProjectDto = initProjectDto || lastViewedProjectDto

    if (nextActiveProjectDto) {
      state.activeProject = Project.fromDto(nextActiveProjectDto)
    } else {
      const { list: projectsList } = rootStore.projectsStore
      const projectsCount = projectsList.length

      if (!projectsCount) {
        alert(Localization.translator.thereIsNoProject)
        return { dispatch: [e.INIT_COMPLETE] }
      }

      // Many projects, user must pick one to load, do nothing
      if (projectsCount > 1) {
        return { dispatch: [e.INIT_APP_SELECT_PROJECT] }
      }

      // Exactly one project, load it if possible
      if (projectsCount === 1) {
        const [firstProjectDto] = projectsList

        if (firstProjectDto) {
          state.activeProject = Project.fromDto(firstProjectDto)
        }
      }
    }

    return {
      dispatch: [e.GET_AUTH_USER_PROJECT],
    }
  })

  eventsStore.on(e.DETECT_USER_LOCATION_INFO, state => {
    const IPAPI_URL = 'https://ipapi.co/json/'

    const url = Config.IPAPI_KEY
      ? IPAPI_URL + `?key=${Config.IPAPI_KEY}`
      : IPAPI_URL

    fetch(url)
      .then(response => {
        response.json().then(jsonData => {
          if (jsonData.error) {
            const jsonString = JSON.stringify(jsonData)
            throw new Error(
              `Error interacting with the IPAPI service ${jsonString}`,
            )
          }

          state.countryCode = jsonData.country.toLowerCase()
          state.latitude = parseFloat(jsonData.latitude)
          state.longitude = parseFloat(jsonData.longitude)
        })
      })
      .catch(error => console.warn(error))
  })

  /**
   * Something went wrong when trying to launch the app and we had to stop
   */
  eventsStore.on(e.INIT_ERROR, (state, reason: string) => {
    state.didInitialize = false

    console.warn('Application launch failure:')

    if (reason) {
      console.warn(reason)
    }

    console.log('Only limited routes will be available')
  })

  eventsStore.on(e.GET_PROJECT_MEMBERS, state => {
    state.loading.set(e.GET_PROJECT_MEMBERS, true)

    return {
      graphQuery: {
        query: GetProjectMembersDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.GET_PROJECT_MEMBERS_SUCCESS],
        onError: [e.REQUEST_ERROR, e.GET_PROJECT_MEMBERS],
      },
    }
  })

  /**
   * Set the project from which data should be displayed
   */
  eventsStore.on(e.ACTIVATE_PROJECT, (_, projectId: string) => {
    const project = rootStore.projectsStore.getProjectById(projectId)

    return {
      dispatch: [e.ACTIVATE_PROJECT_SUCCESS, project],
    }
  })

  // TODO move this to user object?
  /**
   * Sync locally stored user prefs to state and optional object of prefs to merge in.
   * Preferences derived from state will override passed in prefs as they are the truth
   * no matter what anyone else says :)
   */
  eventsStore.on(e.UPDATE_USER_PREFERENCES, (state, prefsToUpdate = {}) => {
    if (!state.user) {
      return
    }

    const userPrefs: IUserPreferences = {
      lastViewedProject: '',
    }

    try {
      Object.assign(userPrefs, JSON.parse(localStorage.getItem(state.user.id)))
    } catch (e) {
      /* empty */
    }

    localStorage.setItem(
      state.user.id,
      JSON.stringify({
        ...userPrefs,
        ...prefsToUpdate,
        ...{
          lastViewedProject: state.activeProject.id,
        },
      }),
    )
  })

  eventsStore.on(e.LOAD_PROJECT_STATUSES, () => {
    const dispatchN = []

    rootStore.projectsStore.list.forEach(project => {
      if (rootStore.projectsStore.projectStatuses.has(project.id)) {
        return
      }

      dispatchN.push([e.GET_PROJECT_STATUS, project.id])
    })

    return {
      dispatchN,
    }
  })

  eventsStore.on(e.GET_PROJECT_STATUS, () => {
    // Temporarily disable until we can pull this from the api
    // return {
    //   db: {
    //     type: GET_BY_ID,
    //     collection: 'projects',
    //     id: projectFbId,
    //     onSuccess: [e.GET_PROJECT_ACTIVITIES],
    //     onError: [e.REQUEST_ERROR, e.GET_PROJECT_STATUS],
    //   },
    // }
  })

  eventsStore.on(e.LOGOUT, state => {
    state.loading.set(e.LOGOUT, true)

    return {
      cmd: {
        cmd: {
          execute: () => {
            return rootStore.auth.logout()
          },
        },
        onSuccess: [e.LOGOUT_SUCCESS],
        onError: [e.REQUEST_ERROR, e.LOGOUT],
      },
    }
  })

  eventsStore.on(e.LOGOUT_SUCCESS, state => {
    state.loading.clear()

    eventsStore.terminateGraphSubscriptions()

    state.user = null
    state.activeProject = Project.none
    state.userActiveProjectSettings = null
    state.activeSchedule = null
    state.initProjectCode = ''

    rootStore.projectsStore.clear()

    state.resetToDefaultDeliveryConfigurations()
    state.resetToDefaultLogisticsConfigurations()
    state.resetToDefaultFormsConfigurations()
    state.resetToDefaultActivitiesConfigurations()
    rootStore.materialConfigurationStore.resetToDefault()

    rootStore.chatService.disconnect()

    rootStore.common.displayLoginView()
  })

  eventsStore.on(e.LISTEN_TO_NOTIFICATIONS_COUNT, state => {
    return {
      graphSubscription: {
        query: ListenNotificationsByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        listenMany: true,
        onData: [e.NOTIFICATION_COUNT_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_NOTIFICATIONS_COUNT],
      },
    }
  })

  eventsStore.on(e.LOAD_AND_LISTEN_TO_COMPANY_TYPE_TAGS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_COMPANY_TYPE_TAGS, true)

    return {
      graphQuery: {
        query: CompanyTypeTagsDocument,
        onSuccess: [e.COMPANY_TYPE_TAGS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_COMPANY_TYPE_TAGS],
      },
    }
  })

  eventsStore.on(e.LISTEN_TO_COMPANY_TYPE_TAGS, () => {
    return {
      graphSubscription: {
        query: ListenCompanyTypeTagDocument,
        onData: [e.COMPANY_TYPE_TAGS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_COMPANY_TYPE_TAGS],
      },
    }
  })

  eventsStore.on(e.COMPANY_TYPE_TAGS_RECEIVED, (state, { companyTypeTags }) => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_COMPANY_TYPE_TAGS, false)

    rootStore.companiesStore.receiveTypeTagsList(companyTypeTags?.data || [])

    // We are currently unable to change Company Type Tags. So there is no need to subscribe

    // return {
    //   dispatch: [e.LISTEN_TO_COMPANY_TYPE_TAGS],
    // }
  })

  eventsStore.on(
    e.COMPANY_TYPE_TAGS_UPDATED,
    (_: InitialState, { companyTypeTag }: ISubscription) => {
      if (companyTypeTag) {
        rootStore.companiesStore.receiveOneTypeTag(
          companyTypeTag.id,
          companyTypeTag.item,
        )
      }
    },
  )

  eventsStore.on(
    e.NOTIFICATION_COUNT_UPDATED,
    (state, data: ISubscription[]) => {
      data.forEach(({ notification }) => {
        if (notification?.item && !notification.item.wasRead) {
          state.unreadNotificationsCount++
        }
      })
    },
  )

  eventsStore.on(
    e.LOAD_AND_LISTEN_TO_ACTIVITY_CODE_LOCATION_RELATIONSHIPS,
    state => {
      return {
        graphQuery: {
          query: ActivityLocationsByProjectIdDocument,
          variables: {
            projectId: state.activeProject.id,
          },
          onSuccess: [e.ACTIVITY_CODE_LOCATION_RELATIONSHIPS_RECEIVED],
          onError: [
            e.REQUEST_ERROR,
            e.LOAD_AND_LISTEN_TO_ACTIVITY_CODE_LOCATION_RELATIONSHIPS,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.ACTIVITY_CODE_LOCATION_RELATIONSHIPS_RECEIVED,
    (_, { activityLocations }) => {
      rootStore.activityCodeLocationRelationshipsStore.receiveList(
        activityLocations ? activityLocations.data : [],
      )

      return {
        dispatch: [e.LISTEN_TO_ACTIVITY_CODE_LOCATION_RELATIONSHIPS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_ACTIVITY_CODE_LOCATION_RELATIONSHIPS, state => {
    return {
      graphSubscription: {
        query: ListenActivityLocationsByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.ACTIVITY_CODE_LOCATION_RELATIONSHIPS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [
          e.REQUEST_ERROR,
          e.LISTEN_TO_ACTIVITY_CODE_LOCATION_RELATIONSHIPS,
        ],
      },
    }
  })

  eventsStore.on(
    e.ACTIVITY_CODE_LOCATION_RELATIONSHIPS_UPDATED,
    (state: InitialState, { activityLocation }: ISubscription) => {
      state.loading.set(e.SAVE_ACTIVITY_CODE_LOCATION_RELATIONSHIP, false)
      state.loading.set(e.DELETE_ACTIVITY_CODE_LOCATION_RELATIONSHIP, false)

      if (activityLocation) {
        rootStore.activityCodeLocationRelationshipsStore.receiveOne(
          activityLocation.id,
          activityLocation.item,
        )
      }

      rootStore.activitiesStore.setActivityData()
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_ACTIVITY_COMPANY_RELATIONSHIPS, state => {
    if (!state.activeScheduleId) return

    return {
      graphQuery: {
        query: CompanyRelationshipsByScheduleIdDocument,
        variables: {
          scheduleId: state.activeScheduleId,
        },
        onSuccess: [e.ACTIVITY_COMPANY_RELATIONSHIPS_RECEIVED],
        onError: [
          e.REQUEST_ERROR,
          e.LOAD_AND_LISTEN_TO_ACTIVITY_COMPANY_RELATIONSHIPS,
        ],
      },
    }
  })

  eventsStore.on(
    e.ACTIVITY_COMPANY_RELATIONSHIPS_RECEIVED,
    (state, { companyRelationships }) => {
      rootStore.activityCompanyRelationshipsStore.receiveList(
        companyRelationships ? companyRelationships.data : [],
      )

      return {
        dispatch: [e.LISTEN_TO_ACTIVITY_COMPANY_RELATIONSHIPS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_ACTIVITY_COMPANY_RELATIONSHIPS, state => {
    return {
      graphSubscription: {
        query: ListenCompanyRelationshipsByScheduleIdDocument,
        variables: {
          scheduleId: state.activeScheduleId,
        },
        onData: [e.ACTIVITY_COMPANY_RELATIONSHIPS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_ACTIVITY_COMPANY_RELATIONSHIPS],
      },
    }
  })

  eventsStore.on(
    e.ACTIVITY_COMPANY_RELATIONSHIPS_UPDATED,
    (state: InitialState, { companyRelationship }: ISubscription) => {
      state.loading.set(e.SAVE_ACTIVITY_COMPANY_RELATIONSHIP, false)
      state.loading.set(e.DELETE_ACTIVITY_COMPANY_RELATIONSHIP, false)

      if (companyRelationship) {
        rootStore.activityCompanyRelationshipsStore.receiveOne(
          companyRelationship.id,
          companyRelationship.item,
        )
      }

      rootStore.activitiesStore.setActivityData()
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_THREADS, state => {
    return {
      graphQuery: {
        query: GetThreadListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.THREADS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_THREADS],
      },
    }
  })

  eventsStore.on(e.THREADS_RECEIVED, (_, { threads }: IQuery) => {
    rootStore.threadsStore.receiveList(threads ? threads.data : [])

    return {
      dispatch: [e.LISTEN_TO_THREAD],
    }
  })

  eventsStore.on(e.LISTEN_TO_THREAD, state => {
    return {
      graphSubscription: {
        query: ListenThreadDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.THREAD_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_THREAD],
      },
    }
  })

  eventsStore.on(
    e.THREAD_UPDATED,
    (_: InitialState, { thread }: ISubscription) => {
      if (thread) {
        rootStore.threadsStore.receiveOne(thread.id, thread.item)
      }
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_PERMIT_TYPES, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_PERMIT_TYPES, true)

    return {
      graphQuery: {
        query: PermitTypeListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.PERMIT_TYPES_LOAD_SUCCESS],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_PERMIT_TYPES],
      },
    }
  })

  eventsStore.on(
    e.PERMIT_TYPES_LOAD_SUCCESS,
    (state: InitialState, { permitTypes }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_PERMIT_TYPES, false)

      rootStore.permitTypesStore.receiveProjectSpecificTypes(permitTypes.data)

      return {
        dispatch: [e.LISTEN_TO_PERMIT_TYPES],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_PERMIT_TYPES, state => {
    return {
      graphSubscription: {
        query: ListenPermitTypesByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.PERMIT_TYPES_RECEIVED],
        listenMany: true,
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_PERMIT_TYPES],
      },
    }
  })

  eventsStore.on(
    e.PERMIT_TYPES_RECEIVED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ permitType }) => {
        if (permitType) {
          rootStore.permitTypesStore.receiveProjectSpecificType(
            permitType.id,
            permitType.item,
          )
        }
      })
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_MESSAGES, (state: InitialState) => {
    return {
      graphQuery: {
        query: MessagesByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.MESSAGES_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_MESSAGES],
      },
    }
  })

  eventsStore.on(e.MESSAGES_RECEIVED, (_, { messages }: IQuery) => {
    rootStore.messagesStore.receiveList(messages.data || [])

    return {
      dispatch: [e.LISTEN_TO_MESSAGES],
    }
  })

  eventsStore.on(e.LISTEN_TO_MESSAGES, state => {
    return {
      graphSubscription: {
        query: ListenMessagesByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.MESSAGE_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_MESSAGES],
      },
    }
  })

  eventsStore.on(
    e.MESSAGE_UPDATED,
    (_: InitialState, { message }: ISubscription) => {
      if (!message) {
        return
      }

      rootStore.messagesStore.updateOne(message.id, message.item)
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_PHOTOS, (state: InitialState) => {
    return {
      graphQuery: {
        query: PhotosByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.PHOTOS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_PHOTOS],
      },
    }
  })

  eventsStore.on(e.PHOTOS_RECEIVED, (_: InitialState, { photos }: IQuery) => {
    rootStore.photosStore.receiveList(photos.data || [])

    return {
      dispatch: [e.LISTEN_TO_PHOTOS],
    }
  })

  eventsStore.on(e.LISTEN_TO_PHOTOS, state => {
    return {
      graphSubscription: {
        query: ListenPhotosByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.PHOTO_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_PHOTOS],
      },
    }
  })

  eventsStore.on(
    e.PHOTO_UPDATED,
    (_: InitialState, { photo }: ISubscription) => {
      if (!photo) {
        return
      }

      rootStore.photosStore.updateOne(photo.id, photo.item)
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_CONTENT_OBJECTS, state => {
    return {
      graphQuery: {
        query: GetContentObjectListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.CONTENT_OBJECTS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_CONTENT_OBJECTS],
      },
    }
  })

  eventsStore.on(
    e.CONTENT_OBJECTS_RECEIVED,
    (_, { contentObjects }: IQuery) => {
      const mapByType =
        contentObjects?.data.reduce<{ [type: string]: any[] }>((map, co) => {
          const type = co.contentObjectType
          if (!map[type]) {
            map[type] = []
          }

          map[type].push(co)
          return map
        }, {}) || {}

      rootStore.rfisStore.receiveList(
        mapByType[ContentObjectType.RequestForInformation] || [],
      )
      rootStore.flagsStore.receiveList(mapByType[ContentObjectType.Flag] || [])
      rootStore.scheduleCommentsStore.receiveList(
        mapByType[ContentObjectType.ScheduleComment] || [],
      )
      rootStore.categoriesOfVarianceStore.receiveList(
        mapByType[ContentObjectType.CategoryOfVariance] || [],
      )
      rootStore.safetyHazardsStore.receiveList(
        mapByType[ContentObjectType.SafetyHazard] || [],
      )

      return {
        dispatchN: [
          [e.LISTEN_TO_CONTENT_OBJECTS],
          [e.TRIGGER_CALCULATE_ACTIVITY_TREE],
        ],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_CONTENT_OBJECTS, state => {
    return {
      graphSubscription: {
        query: ListenToContentObjectDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.CONTENT_OBJECT_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_CONTENT_OBJECTS],
      },
    }
  })

  eventsStore.on(
    e.CONTENT_OBJECT_UPDATED,
    (_: InitialState, { contentObject }: ISubscription) => {
      if (!contentObject) {
        return
      }

      let coStore
      switch (contentObject.item.contentObjectType) {
        case ContentObjectType.RequestForInformation:
          coStore = rootStore.rfisStore
          break

        case ContentObjectType.Flag:
          coStore = rootStore.flagsStore
          break

        case ContentObjectType.ScheduleComment:
          coStore = rootStore.scheduleCommentsStore
          break

        case ContentObjectType.CategoryOfVariance:
          coStore = rootStore.categoriesOfVarianceStore
          break

        case ContentObjectType.SafetyHazard:
          coStore = rootStore.safetyHazardsStore
          break
      }

      coStore.receiveOne(contentObject.id, contentObject.item)

      return {
        dispatch: [e.TRIGGER_CALCULATE_ACTIVITY_TREE],
      }
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_ALL_DELIVERY_ATTRIBUTES, () => {
    rootStore.deliveryVehicleTypesStore.loadAndListenTo()
    rootStore.deliveryUnitsStore.loadAndListenTo()
  })

  eventsStore.on(
    e.LOAD_DELIVERY_ATTRIBUTES,
    (state, collectionKey, query, onDataReceivedCb) => {
      const loadingKey = `${e.LOAD_DELIVERY_ATTRIBUTES}-${collectionKey}`

      state.loading.set(loadingKey, true)

      return {
        graphQuery: {
          query,
          variables: {
            projectId: state.activeProject.id,
          },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            loadingKey,
            onDataReceivedCb,
          ],
          onError: [e.REQUEST_ERROR, e.LOAD_DELIVERY_ATTRIBUTES],
        },
      }
    },
  )

  eventsStore.on(e.REGISTER_SERVICE_WORKER, () => {
    if (!('serviceWorker' in navigator)) {
      return
    }

    if (window.navigator) {
      navigator.serviceWorker.register('/sw.js')
    }
  })

  eventsStore.on(e.RESET_INPUT_STATUSES, () => {
    // only postcallback
  })

  eventsStore.on(e.GET_CURRENT_USER, (state, userFirebaseId) => {
    state.loading.set(e.GET_CURRENT_USER, true)

    return {
      graphQuery: {
        query: GetUserByFirebaseIdDocument,
        variables: {
          userFirebaseId,
        },
        onSuccess: [e.GET_CURRENT_USER_SUCCESS],
        onError: [e.REQUEST_ERROR, e.GET_CURRENT_USER],
      },
    }
  })

  eventsStore.on(e.GET_CURRENT_USER_SUCCESS, (state, { user }: IQuery) => {
    state.loading.set(e.GET_CURRENT_USER, false)

    state.user = User.fromDto(user)
    rootStore.chatService.init(user.id)
  })

  eventsStore.on(e.LOAD_AND_LISTEN_TO_BASEMAPS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_BASEMAPS, true)

    return {
      graphQuery: {
        query: BasemapsByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.BASEMAPS_RECIEVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_BASEMAPS],
      },
    }
  })

  eventsStore.on(e.BASEMAPS_RECIEVED, (state, { basemaps }: IQuery) => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_BASEMAPS, false)

    rootStore.basemapsStore.receiveList(basemaps?.data || [])

    return {
      graphSubscription: {
        query: ListenBasemapsByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.BASEMAP_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REPORT_ERROR, e.BASEMAPS_RECIEVED],
      },
    }
  })

  eventsStore.on(e.BASEMAP_UPDATED, (_, { basemap }: ISubscription) => {
    if (!basemap) {
      return
    }

    rootStore.basemapsStore.receiveOne(basemap.id, basemap.item)
  })

  eventsStore.on(e.GLOBE_VIEWS_RECEIVED, (state, { globeViews }: IQuery) => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_GLOBE_VIEWS, false)

    rootStore.globeViewsStore.receiveList(globeViews?.data || [])

    return {
      graphSubscription: {
        query: ListenGlobeViewsByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.GLOBE_VIEW_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REPORT_ERROR, e.GLOBE_VIEWS_RECEIVED],
      },
    }
  })

  eventsStore.on(
    e.GLOBE_VIEW_UPDATED,
    (state, { globeView }: ISubscription) => {
      state.loading.set(e.SAVE_GLOBE_VIEW, false)
      if (!globeView) {
        return
      }

      rootStore.globeViewsStore.receiveOne(globeView.id, globeView.item)
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_SITEMAPS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_SITEMAPS, true)

    return {
      graphQuery: {
        query: SitemapsByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.SITEMAPS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_SITEMAPS],
      },
    }
  })

  eventsStore.on(e.SITEMAPS_RECEIVED, (state, { sitemaps }: IQuery) => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_SITEMAPS, false)

    rootStore.sitemapsStore.receiveList(sitemaps?.data || [])

    return {
      graphSubscription: {
        query: ListenSitemapsByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.SITEMAP_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REPORT_ERROR, e.SITEMAPS_RECEIVED],
      },
    }
  })

  eventsStore.on(e.SITEMAP_UPDATED, (_, { sitemap }: ISubscription) => {
    if (!sitemap) {
      return
    }

    rootStore.sitemapsStore.receiveOne(sitemap.id, sitemap.item)
  })

  eventsStore.on(e.LISTEN_TO_SITEMAP_ITEMS_DATA, state => {
    return {
      graphSubscription: {
        query: ListenSitemapItemDataByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.SITEMAP_ITEM_DATA_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_SITEMAP_ITEMS_DATA],
      },
    }
  })

  eventsStore.on(
    e.SITEMAP_ITEM_DATA_UPDATED,
    (state, { sitemapSpecificItemData }: ISubscription) => {
      if (!sitemapSpecificItemData) {
        return
      }

      rootStore.sitemapsStore.receiveOneItem(
        sitemapSpecificItemData.id,
        sitemapSpecificItemData.item,
      )
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_GLOBE_VIEWS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_GLOBE_VIEWS, true)

    return {
      graphQuery: {
        query: GlobeViewsByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.GLOBE_VIEWS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_GLOBE_VIEWS],
      },
    }
  })

  eventsStore.on(e.LISTEN_TO_GLOBE_VIEW_ITEMS_DATA, state => {
    return {
      graphSubscription: {
        query: ListenGlobeViewItemsDataByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.GLOBE_VIEW_ITEM_DATA_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_GLOBE_VIEW_ITEMS_DATA],
      },
    }
  })

  eventsStore.on(
    e.GLOBE_VIEW_ITEM_DATA_UPDATED,
    (state, { globeViewSpecificItemData }: ISubscription) => {
      if (!globeViewSpecificItemData) {
        return
      }

      rootStore.globeViewsStore.receiveOneItem(
        globeViewSpecificItemData.id,
        globeViewSpecificItemData.item,
      )
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_SITEMAP_ITEMS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_SITEMAP_ITEMS, true)

    return {
      graphQuery: {
        query: SitemapItemsByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.SITEMAP_ITEMS_RECIEVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_SITEMAP_ITEMS],
      },
    }
  })

  eventsStore.on(
    e.SITEMAP_ITEMS_RECIEVED,
    (state, { sitemapItems }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_SITEMAP_ITEMS, false)

      rootStore.sitemapItemsStore.receiveList(sitemapItems?.data || [])

      return {
        graphSubscription: {
          query: ListenSitemapItemsByProjectIdDocument,
          variables: {
            projectId: state.activeProject.id,
          },
          onData: [e.SITEMAP_ITEM_UPDATED],
          onSuccess: [e.NO_EFFECT],
          onError: [e.REPORT_ERROR, e.SITEMAP_ITEMS_RECIEVED],
        },
      }
    },
  )

  eventsStore.on(
    e.SITEMAP_ITEM_UPDATED,
    (_, { sitemapItem }: ISubscription) => {
      if (!sitemapItem) {
        return
      }

      rootStore.sitemapItemsStore.receiveOne(sitemapItem.id, sitemapItem.item)
    },
  )

  eventsStore.on(e.LOAD_LOCATIONS_AND_LISTEN_CHANGES, state => {
    state.loading.set(e.LOAD_LOCATIONS_AND_LISTEN_CHANGES, true)
    return {
      graphQuery: {
        query: LocationsByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.LOAD_LOCATIONS_SUCCESS],
        onError: [e.REQUEST_ERROR, e.LOAD_LOCATIONS_AND_LISTEN_CHANGES],
      },
    }
  })

  eventsStore.on(e.LOAD_LOCATIONS_SUCCESS, (state, { locations }: IQuery) => {
    state.loading.set(e.LOAD_LOCATIONS_AND_LISTEN_CHANGES, false)

    const items = locations.data || []
    const itemsByType = {}
    items.forEach(item => {
      if (!itemsByType[item.type]) {
        itemsByType[item.type] = []
      }
      itemsByType[item.type].push(item)
    })

    // Alphabetically sorting by name
    Object.keys(itemsByType).forEach(key => {
      itemsByType[key].sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name),
      )
    })

    rootStore.buildingsStore.receiveList(
      itemsByType[LocationType.Building] || [],
    )
    rootStore.gatesStore.receiveList(itemsByType[LocationType.Gate] || [])
    rootStore.zonesStore.receiveList(itemsByType[LocationType.Zone] || [])
    rootStore.routesStore.receiveList(itemsByType[LocationType.Route] || [])
    rootStore.offloadingEquipmentsStore.receiveList(
      itemsByType[LocationType.OffloadingEquipment] || [],
    )
    rootStore.levelsStore.receiveList(itemsByType[LocationType.Level] || [])
    rootStore.areasStore.receiveList(itemsByType[LocationType.Area] || [])
    rootStore.logisticsObjectsStore.receiveList(
      itemsByType[LocationType.LogisticsObject] || [],
    )
    rootStore.verticalObjectsStore.receiveList(
      itemsByType[LocationType.VerticalObject] || [],
    )
    rootStore.locationIntegrationsStore.receiveList(
      itemsByType[LocationType.Integration] || [],
    )
    rootStore.stagingsStore.receiveList(itemsByType[LocationType.Staging] || [])
    rootStore.interiorDoorsStore.receiveList(
      itemsByType[LocationType.InteriorDoor] || [],
    )
    rootStore.interiorPathsStore.receiveList(
      itemsByType[LocationType.InteriorPath] || [],
    )

    return {
      graphSubscription: {
        query: ListenLocationsByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.LOCATION_RECEIVED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REPORT_ERROR, e.LOAD_LOCATIONS_SUCCESS],
      },
    }
  })

  eventsStore.on(e.LOCATION_RECEIVED, (_, { location }: ISubscription) => {
    if (!location.item) {
      return
    }

    const item = location.item as ILocationDto
    switch (location.item.type) {
      case LocationType.Building: {
        rootStore.buildingsStore.receiveOne(item)
        break
      }
      case LocationType.Zone: {
        rootStore.zonesStore.receiveOne(item)
        break
      }
      case LocationType.Gate: {
        rootStore.gatesStore.receiveOne(item)
        break
      }
      case LocationType.Route: {
        rootStore.routesStore.receiveOne(item)
        break
      }
      case LocationType.OffloadingEquipment: {
        rootStore.offloadingEquipmentsStore.receiveOne(item)
        break
      }
      case LocationType.Level: {
        rootStore.levelsStore.receiveOne(item)
        break
      }
      case LocationType.Area: {
        rootStore.areasStore.receiveOne(item)
        break
      }
      case LocationType.LogisticsObject: {
        rootStore.logisticsObjectsStore.receiveOne(item)
        break
      }
      case LocationType.VerticalObject: {
        rootStore.verticalObjectsStore.receiveOne(item)
        break
      }
      case LocationType.Integration: {
        rootStore.locationIntegrationsStore.receiveOne(item)
        break
      }
      case LocationType.Staging: {
        rootStore.stagingsStore.receiveOne(item)
        break
      }
      case LocationType.InteriorDoor: {
        rootStore.interiorDoorsStore.receiveOne(item)
        break
      }
      case LocationType.InteriorPath: {
        rootStore.interiorPathsStore.receiveOne(item)
        break
      }
    }
  })

  eventsStore.on(e.LISTEN_AND_LOAD_DELIVERIES, state => {
    state.loading.set(e.LISTEN_AND_LOAD_DELIVERIES, true)

    return {
      graphSubscription: {
        query: ListenDeliveriesByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        listenMany: true,
        onData: [e.DELIVERY_RECEIVED],
        onSuccess: [
          e.COMPLETE_REQUEST_AND_DISPATCH,
          e.LISTEN_AND_LOAD_DELIVERIES,
          e.LOAD_DELIVERIES,
        ],
        onError: [e.REQUEST_ERROR, e.LISTEN_AND_LOAD_DELIVERIES],
      },
    }
  })

  eventsStore.on(
    e.LOAD_DELIVERIES_SUCCESS,
    (state, { deliveries: { data: deliveries } }: IQuery) => {
      state.loading.set(e.LOAD_DELIVERIES, false)
      rootStore.deliveriesStore.receiveList(deliveries)

      setLatestDeliveryByProjectAndUser(rootStore)

      return {
        dispatchN: [
          [e.TRIGGER_CALCULATE_ACTIVITY_TREE],
          [e.TRIGGER_CALCULATE_DELIVERY_TREE],
        ],
      }
    },
  )
  eventsStore.on(
    e.DELIVERY_RECEIVED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ delivery: { item: dto } }) => {
        rootStore.deliveriesStore.updateOne(dto.id, dto)

        updateDisplayedDeliveryIfNeed(rootStore, dto.id)
      })

      setLatestDeliveryByProjectAndUser(rootStore)

      return {
        dispatchN: [
          [e.TRIGGER_CALCULATE_ACTIVITY_TREE],
          [e.TRIGGER_CALCULATE_DELIVERY_TREE],
        ],
      }
    },
  )

  eventsStore.on(e.LOAD_CLOSURES_AND_LISTEN_CHANGES, state => {
    return {
      graphQuery: {
        query: GetClosuresListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.LOAD_CLOSURES_SUCCESS],
        onError: [e.REQUEST_ERROR, e.LOAD_CLOSURES_AND_LISTEN_CHANGES],
      },
    }
  })

  eventsStore.on(e.LOAD_CLOSURES_SUCCESS, (_, { closures }: IQuery) => {
    rootStore.closuresStore.receiveList(closures.data)
    return {
      dispatch: [e.LISTEN_TO_CLOSURES],
    }
  })

  eventsStore.on(e.LOAD_CLOSURE_ASSIGNMENTS_AND_LISTEN_CHANGES, state => {
    return {
      graphQuery: {
        query: GetClosureAssignmentsListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.LOAD_CLOSURE_ASSIGNMENTS_SUCCESS],
        onError: [
          e.REQUEST_ERROR,
          e.LOAD_CLOSURE_ASSIGNMENTS_AND_LISTEN_CHANGES,
        ],
      },
    }
  })

  eventsStore.on(
    e.LOAD_CLOSURE_ASSIGNMENTS_SUCCESS,
    (_, { closureAssignments }: IQuery) => {
      const { closureAssignmentsStore } = rootStore

      closureAssignmentsStore.clearList()
      closureAssignmentsStore.updateFromList(closureAssignments.data)

      return {
        dispatch: [e.LISTEN_TO_CLOSURE_ASSIGNMENTS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_CLOSURES, state => {
    return {
      graphSubscription: {
        query: ListenToClosuresDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        listenMany: true,
        onData: [e.CLOSURE_RECEIVED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_CLOSURES],
      },
    }
  })

  eventsStore.on(e.LISTEN_TO_CLOSURE_ASSIGNMENTS, state => {
    return {
      graphSubscription: {
        query: ListenToClosureAssignmentsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.CLOSURE_ASSIGNMENT_RECEIVED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_CLOSURE_ASSIGNMENTS],
      },
    }
  })

  eventsStore.on(
    e.CLOSURE_RECEIVED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ closure }) => {
        if (closure) {
          rootStore.closuresStore.receiveOne(closure.id, closure.item)
        }
      })
    },
  )

  eventsStore.on(
    e.CLOSURE_ASSIGNMENT_RECEIVED,
    (_, { closureAssignment: { item } }: ISubscription) => {
      rootStore.closureAssignmentsStore.updateFromList([item])
    },
  )

  eventsStore.on(e.LOAD_CLOSURE_FOLLOWINGS_AND_LISTEN_CHANGES, state => {
    state.loading.set(e.LOAD_CLOSURE_FOLLOWINGS_AND_LISTEN_CHANGES, true)

    return {
      graphQuery: {
        query: GetClosureFollowingListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.LOAD_CLOSURE_FOLLOWINGS_SUCCESS],
        onError: [
          e.REQUEST_ERROR,
          e.LOAD_CLOSURE_FOLLOWINGS_AND_LISTEN_CHANGES,
        ],
      },
    }
  })

  eventsStore.on(
    e.LOAD_CLOSURE_FOLLOWINGS_SUCCESS,
    (state, { closureFollowings }: IQuery) => {
      state.loading.set(e.LOAD_CLOSURE_FOLLOWINGS_AND_LISTEN_CHANGES, false)

      rootStore.closureFollowingsStore.receiveList(
        (closureFollowings?.data as IAssociationDto[]) || [],
      )

      return {
        dispatch: [e.LISTEN_TO_CLOSURE_FOLLOWING],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_CLOSURE_FOLLOWING, state => {
    return {
      graphSubscription: {
        query: ListenToClosureFollowingDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        listenMany: true,
        onData: [e.CLOSURE_FOLLOWING_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_CLOSURE_FOLLOWING],
      },
    }
  })

  eventsStore.on(
    e.CLOSURE_FOLLOWING_UPDATED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ closureFollowing }) => {
        if (closureFollowing) {
          rootStore.closureFollowingsStore.receiveOne(
            closureFollowing.id,
            closureFollowing.item as IAssociationDto,
          )
        }
      })
    },
  )

  eventsStore.on(e.LOAD_ANNOUNCEMENTS_AND_LISTEN_CHANGES, state => {
    return {
      graphQuery: {
        query: GetAnnouncementsListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.LOAD_ANNOUNCEMENTS_SUCCESS],
        onError: [e.REQUEST_ERROR, e.LOAD_ANNOUNCEMENTS_AND_LISTEN_CHANGES],
      },
    }
  })

  eventsStore.on(
    e.LOAD_ANNOUNCEMENTS_SUCCESS,
    (_, { announcements: { data: announcements } }: IQuery) => {
      rootStore.announcementsStore.receiveList(announcements)

      return {
        dispatch: [e.LISTEN_TO_ANNOUNCEMENTS],
      }
    },
  )

  eventsStore.on(e.LOAD_ANNOUNCEMENT_ASSIGNMENTS_AND_LISTEN_CHANGES, state => {
    return {
      graphQuery: {
        query: GetAnnouncementAssignmentsListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.LOAD_ANNOUNCEMENT_ASSIGNMENTS_SUCCESS],
        onError: [
          e.REQUEST_ERROR,
          e.LOAD_ANNOUNCEMENT_ASSIGNMENTS_AND_LISTEN_CHANGES,
        ],
      },
    }
  })

  eventsStore.on(
    e.LOAD_ANNOUNCEMENT_ASSIGNMENTS_SUCCESS,
    (
      _,
      { announcementAssignments: { data: announcementAssignments } }: IQuery,
    ) => {
      const { announcementAssignmentsStore } = rootStore

      announcementAssignmentsStore.clearList()
      announcementAssignmentsStore.updateFromList(announcementAssignments)

      return {
        dispatch: [e.LISTEN_TO_ANNOUNCEMENT_ASSIGNMENTS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_ANNOUNCEMENTS, state => {
    return {
      graphSubscription: {
        query: ListenToAnnouncementsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.ANNOUNCEMENT_RECEIVED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_ANNOUNCEMENTS],
      },
    }
  })

  eventsStore.on(e.LISTEN_TO_ANNOUNCEMENT_ASSIGNMENTS, state => {
    return {
      graphSubscription: {
        query: ListenToAnnouncementAssignmentsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.ANNOUNCEMENT_ASSIGNMENT_RECEIVED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_ANNOUNCEMENT_ASSIGNMENTS],
      },
    }
  })

  eventsStore.on(
    e.ANNOUNCEMENT_RECEIVED,
    (_, { announcement: { id, item } }: ISubscription) => {
      rootStore.announcementsStore.receiveOne(id, item)
    },
  )

  eventsStore.on(
    e.ANNOUNCEMENT_ASSIGNMENT_RECEIVED,
    (_, { announcementAssignment: { item } }: ISubscription) => {
      rootStore.announcementAssignmentsStore.updateFromList([item])
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_SITE_PERMITS, state => {
    return {
      graphQuery: {
        query: GetSitePermitListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.SITE_PERMITS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_SITE_PERMITS],
      },
    }
  })

  eventsStore.on(e.SITE_PERMITS_RECEIVED, (_, { sitePermits }: IQuery) => {
    rootStore.sitePermitsStore.receiveList(sitePermits.data)

    return {
      dispatch: [e.LISTEN_TO_SITE_PERMITS],
    }
  })

  eventsStore.on(e.LISTEN_TO_SITE_PERMITS, state => {
    return {
      graphSubscription: {
        query: ListenToSitePermitDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        listenMany: true,
        onData: [e.SITE_PERMIT_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_SITE_PERMITS],
      },
    }
  })

  eventsStore.on(
    e.SITE_PERMIT_UPDATED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ sitePermit }) => {
        if (sitePermit) {
          rootStore.sitePermitsStore.receiveOne(
            sitePermit.id,
            sitePermit.item as any,
          )
        }
      })
    },
  )

  eventsStore.on(
    e.LOAD_AND_LISTEN_TO_SITE_PERMIT_STATUS_CHANGES,
    (state: InitialState, formId: string, callbackFn: () => void) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_SITE_PERMIT_STATUS_CHANGES, true)

      return {
        graphQuery: {
          query: GetSitePermitStatusChangeListDocument,
          variables: {
            projectId: state.activeProject.id,
            permitId: formId,
          },
          onSuccess: [
            e.SITE_PERMIT_STATUS_CHANGES_RECEIVED,
            formId,
            callbackFn,
          ],
          onError: [
            e.REQUEST_ERROR,
            e.LOAD_AND_LISTEN_TO_SITE_PERMIT_STATUS_CHANGES,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.SITE_PERMIT_STATUS_CHANGES_RECEIVED,
    (
      state: InitialState,
      formId: string,
      callbackFn: () => void,
      { sitePermitStatusChanges }: IQuery,
    ) => {
      rootStore.permitStatusChangesStore.receiveList(
        sitePermitStatusChanges.data,
      )
      state.loading.set(e.LOAD_AND_LISTEN_TO_SITE_PERMIT_STATUS_CHANGES, false)

      return {
        dispatch: [e.LISTEN_TO_SITE_PERMIT_STATUS_CHANGES, formId, callbackFn],
      }
    },
  )

  eventsStore.on(
    e.LISTEN_TO_SITE_PERMIT_STATUS_CHANGES,
    (state: InitialState, formId: string, callbackFn: () => void) => {
      return {
        graphSubscription: {
          query: ListenToSitePermitStatusChangeDocument,
          variables: {
            projectId: state.activeProject.id,
            permitId: formId,
          },
          listenMany: true,
          onData: [e.SITE_PERMIT_STATUS_CHANGE_UPDATED],
          onSuccess: [e.RUN_CALLBACK, callbackFn],
          onError: [e.REQUEST_ERROR, e.LISTEN_TO_SITE_PERMIT_STATUS_CHANGES],
        },
      }
    },
  )

  eventsStore.on(
    e.SITE_PERMIT_STATUS_CHANGE_UPDATED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ sitePermitStatusChange }) => {
        if (sitePermitStatusChange) {
          rootStore.permitStatusChangesStore.receiveOne(
            sitePermitStatusChange.id,
            sitePermitStatusChange.item,
          )
        }
      })
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_SITE_PERMIT_INSPECTIONS, state => {
    return {
      graphQuery: {
        query: GetSitePermitInspectionListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.SITE_PERMIT_INSPECTIONS_RECEIVED],
        onError: [
          e.REQUEST_ERROR,
          e.LOAD_AND_LISTEN_TO_SITE_PERMIT_INSPECTIONS,
        ],
      },
    }
  })

  eventsStore.on(
    e.SITE_PERMIT_INSPECTIONS_RECEIVED,
    (_, { sitePermitInspections }: IQuery) => {
      rootStore.permitInspectionsStore.receiveList(sitePermitInspections.data)

      return {
        dispatch: [e.LISTEN_TO_SITE_PERMIT_INSPECTIONS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_SITE_PERMIT_INSPECTIONS, state => {
    return {
      graphSubscription: {
        query: ListenToSitePermitInspectionDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.SITE_PERMIT_INSPECTION_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_SITE_PERMIT_INSPECTIONS],
      },
    }
  })

  eventsStore.on(
    e.SITE_PERMIT_INSPECTION_UPDATED,
    (_: InitialState, { sitePermitInspection }: ISubscription) => {
      if (!sitePermitInspection) {
        return
      }

      rootStore.permitInspectionsStore.receiveOne(
        sitePermitInspection.id,
        sitePermitInspection.item,
      )
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_SITE_PERMIT_INSPECTION_CHANGES, state => {
    return {
      graphQuery: {
        query: GetSitePermitInspectionChangeListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.SITE_PERMIT_INSPECTION_CHANGES_RECEIVED],
        onError: [
          e.REQUEST_ERROR,
          e.LOAD_AND_LISTEN_TO_SITE_PERMIT_INSPECTION_CHANGES,
        ],
      },
    }
  })

  eventsStore.on(
    e.SITE_PERMIT_INSPECTION_CHANGES_RECEIVED,
    (_, { sitePermitInspectionChanges }: IQuery) => {
      rootStore.permitInspectionChangesStore.receiveList(
        sitePermitInspectionChanges.data,
      )

      return {
        dispatch: [e.LISTEN_TO_SITE_PERMIT_INSPECTION_CHANGES],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_SITE_PERMIT_INSPECTION_CHANGES, state => {
    return {
      graphSubscription: {
        query: ListenToSitePermitInspectionChangeDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.SITE_PERMIT_INSPECTION_CHANGE_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_SITE_PERMIT_INSPECTION_CHANGES],
      },
    }
  })

  eventsStore.on(
    e.SITE_PERMIT_INSPECTION_CHANGE_UPDATED,
    (_: InitialState, { sitePermitInspectionChange }: ISubscription) => {
      if (!sitePermitInspectionChange) {
        return
      }

      rootStore.permitInspectionChangesStore.receiveOne(
        sitePermitInspectionChange.id,
        sitePermitInspectionChange.item,
      )
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_ACTIVITY_FOLLOWINGS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_ACTIVITY_FOLLOWINGS, true)

    return {
      graphQuery: {
        query: GetActivityFollowingListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.ACTIVITY_FOLLOWINGS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_ACTIVITY_FOLLOWINGS],
      },
    }
  })

  eventsStore.on(
    e.ACTIVITY_FOLLOWINGS_RECEIVED,
    (state, { activityFollowings }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_ACTIVITY_FOLLOWINGS, false)

      rootStore.activityFollowingsStore.receiveList(
        (activityFollowings?.data as IAssociationDto[]) || [],
      )

      return {
        dispatch: [e.LISTEN_TO_ACTIVITY_FOLLOWING],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_ACTIVITY_FOLLOWING, state => {
    return {
      graphSubscription: {
        query: ListenToActivityFollowingDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        listenMany: true,
        onData: [e.ACTIVITY_FOLLOWING_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_ACTIVITY_FOLLOWING],
      },
    }
  })

  eventsStore.on(
    e.ACTIVITY_FOLLOWING_UPDATED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ activityFollowing }) => {
        if (activityFollowing) {
          rootStore.activityFollowingsStore.receiveOne(
            activityFollowing.id,
            activityFollowing.item as IAssociationDto,
          )
        }
      })
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_ANNOUNCEMENTS_FOLLOWINGS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_ANNOUNCEMENTS_FOLLOWINGS, true)

    return {
      graphQuery: {
        query: GetAnnouncementFollowingListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.ANNOUNCEMENT_FOLLOWINGS_RECEIVED],
        onError: [
          e.REQUEST_ERROR,
          e.LOAD_AND_LISTEN_TO_ANNOUNCEMENTS_FOLLOWINGS,
        ],
      },
    }
  })

  eventsStore.on(
    e.ANNOUNCEMENT_FOLLOWINGS_RECEIVED,
    (state, { announcementFollowings }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_ANNOUNCEMENTS_FOLLOWINGS, false)

      rootStore.announcementFollowingsStore.receiveList(
        (announcementFollowings?.data as IAssociationDto[]) || [],
      )

      return {
        dispatch: [e.LISTEN_TO_ANNOUNCEMENT_FOLLOWING],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_ANNOUNCEMENT_FOLLOWING, state => {
    return {
      graphSubscription: {
        query: ListenToAnnouncementFollowingDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        listenMany: true,
        onData: [e.ANNOUNCEMENT_FOLLOWING_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_ANNOUNCEMENT_FOLLOWING],
      },
    }
  })

  eventsStore.on(
    e.ANNOUNCEMENT_FOLLOWING_UPDATED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ announcementFollowing }) => {
        if (announcementFollowing) {
          rootStore.announcementFollowingsStore.receiveOne(
            announcementFollowing.id,
            announcementFollowing.item as IAssociationDto,
          )
        }
      })
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_SITE_PERMITS_FOLLOWINGS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_SITE_PERMITS_FOLLOWINGS, true)

    return {
      graphQuery: {
        query: GetSitePermitFollowingListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.SITE_PERMIT_FOLLOWINGS_RECEIVED],
        onError: [
          e.REQUEST_ERROR,
          e.LOAD_AND_LISTEN_TO_SITE_PERMITS_FOLLOWINGS,
        ],
      },
    }
  })

  eventsStore.on(
    e.SITE_PERMIT_FOLLOWINGS_RECEIVED,
    (state, { sitePermitFollowings }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_SITE_PERMITS_FOLLOWINGS, false)

      rootStore.sitePermitFollowingsStore.receiveList(
        (sitePermitFollowings?.data as IAssociationDto[]) || [],
      )

      return {
        dispatch: [e.LISTEN_TO_SITE_PERMIT_FOLLOWING],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_SITE_PERMIT_FOLLOWING, state => {
    return {
      graphSubscription: {
        query: ListenToSitePermitFollowingDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        listenMany: true,
        onData: [e.SITE_PERMIT_FOLLOWING_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_SITE_PERMIT_FOLLOWING],
      },
    }
  })

  eventsStore.on(
    e.SITE_PERMIT_FOLLOWING_UPDATED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ sitePermitFollowing }) => {
        if (sitePermitFollowing) {
          rootStore.sitePermitFollowingsStore.receiveOne(
            sitePermitFollowing.id,
            sitePermitFollowing.item as IAssociationDto,
          )
        }
      })
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_CASTS_FOLLOWINGS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_CASTS_FOLLOWINGS, true)

    return {
      graphQuery: {
        query: GetCastFollowingListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.CAST_FOLLOWINGS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_CASTS_FOLLOWINGS],
      },
    }
  })

  eventsStore.on(
    e.CAST_FOLLOWINGS_RECEIVED,
    (state, { castFollowings }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_CASTS_FOLLOWINGS, false)

      rootStore.castFollowingsStore.receiveList(
        (castFollowings?.data as IAssociationDto[]) || [],
      )
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_MATERIALS, (state: InitialState) => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_MATERIALS, true)

    const { id, materialsUploadId } = state.activeProject

    return {
      graphQuery: {
        query: GetMaterialListDocument,
        variables: { projectId: id, materialsUploadId },
        onSuccess: [e.MATERIALS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_MATERIALS],
      },
    }
  })

  eventsStore.on(
    e.MATERIALS_RECEIVED,
    (state: InitialState, { projectMaterials }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_MATERIALS, false)

      rootStore.materialsStore.receiveList(projectMaterials?.data || [])

      return {
        dispatch: [e.LISTEN_TO_MATERIAL],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_MATERIAL, (state: InitialState) => {
    const { id, materialsUploadId } = state.activeProject
    return {
      graphSubscription: {
        query: ListenToMaterialDocument,
        variables: { projectId: id, materialsUploadId },
        listenMany: true,
        onData: [e.MATERIAL_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_MATERIAL],
      },
    }
  })

  eventsStore.on(
    e.MATERIAL_UPDATED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ projectMaterial }) => {
        if (projectMaterial) {
          rootStore.materialsStore.receiveOne(
            projectMaterial.id,
            projectMaterial.item,
          )
        }
      })
    },
  )

  eventsStore.on(
    e.LOAD_AND_LISTEN_TO_MATERIAL_CONFIGURATION,
    (state: InitialState) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_MATERIAL_CONFIGURATION, true)

      const { id } = state.activeProject

      return {
        graphQuery: {
          query: GetMaterialConfigurationDocument,
          variables: { projectId: id },
          onSuccess: [e.MATERIAL_CONFIGURATION_RECEIVED],
          onError: [
            e.REQUEST_ERROR,
            e.LOAD_AND_LISTEN_TO_MATERIAL_CONFIGURATION,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.MATERIAL_CONFIGURATION_RECEIVED,
    (state: InitialState, { materialConfiguration }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_MATERIAL_CONFIGURATION, false)

      rootStore.materialConfigurationStore.updateMaterialConfiguration(
        materialConfiguration,
      )

      return {
        dispatch: [e.LISTEN_TO_MATERIAL_CONFIGURATION],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_MATERIAL_CONFIGURATION, (state: InitialState) => {
    const { id } = state.activeProject
    return {
      graphSubscription: {
        query: ListenToMaterialConfigurationDocument,
        variables: { projectId: id },
        listenMany: false,
        onData: [e.MATERIAL_CONFIGURATION_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_MATERIAL_CONFIGURATION],
      },
    }
  })

  eventsStore.on(
    e.MATERIAL_CONFIGURATION_UPDATED,
    (_: InitialState, { materialConfiguration }: ISubscription) => {
      rootStore.materialConfigurationStore.updateMaterialConfiguration(
        materialConfiguration?.item,
      )
    },
  )

  eventsStore.on(
    e.LOAD_AND_LISTEN_TO_MATERIAL_CATEGORIES,
    (state: InitialState) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_MATERIAL_CATEGORIES, true)

      const { id, materialsUploadId } = state.activeProject

      return {
        graphQuery: {
          query: GetMaterialCategoryListDocument,
          variables: { projectId: id, materialsUploadId },
          onSuccess: [e.MATERIAL_CATEGORIES_RECEIVED],
          onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_MATERIAL_CATEGORIES],
        },
      }
    },
  )

  eventsStore.on(
    e.MATERIAL_CATEGORIES_RECEIVED,
    (state: InitialState, { projectMaterialCategories }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_MATERIAL_CATEGORIES, false)

      rootStore.materialCategoryStore.receiveList(
        projectMaterialCategories?.data || [],
      )

      return {
        dispatch: [e.LISTEN_TO_MATERIAL_CATEGORY],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_MATERIAL_CATEGORY, (state: InitialState) => {
    const { id, materialsUploadId } = state.activeProject

    return {
      graphSubscription: {
        query: ListenToMaterialCategoryDocument,
        variables: { projectId: id, materialsUploadId },
        listenMany: true,
        onData: [e.MATERIAL_CATEGORY_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_MATERIAL_CATEGORY],
      },
    }
  })

  eventsStore.on(
    e.MATERIAL_CATEGORY_UPDATED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ projectMaterialCategory }) => {
        if (projectMaterialCategory) {
          rootStore.materialCategoryStore.receiveOne(
            projectMaterialCategory.id,
            projectMaterialCategory.item,
          )
        }
      })
    },
  )

  eventsStore.on(e.RUN_CALLBACK, (_, callback, data) => {
    if (callback) {
      callback(data)
    }
  })

  eventsStore.on(e.COLLECT_TELEMETRY, (_, telemetry: ITelemetryPayload) => {
    const { event, timing } = telemetry

    if (event) {
      ReactGA.event({
        category: event.eventCategory,
        action: event.eventAction,
        label: window.location.pathname.split('/')[2],
        value: event.eventValue,
      })
    }
    if (timing) {
      ReactGA.event({
        category: timing.timingCategory,
        action: timing.timingVar,
        label: window.location.pathname.split('/')[2],
        value: timing.timingValue,
      })
    }
  })

  eventsStore.on(e.LOAD_AND_LISTEN_TO_STATUS_UPDATES, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_STATUS_UPDATES, true)
    return {
      graphQuery: {
        query: GetStatusUpdatesDocument,
        variables: {
          projectId: state.activeProject.id,
          activityP6Codes: rootStore.activitiesStore.list.map(a => a.code),
        },
        onSuccess: [e.STATUS_UPDATES_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_STATUS_UPDATES],
      },
    }
  })

  eventsStore.on(e.LISTEN_TO_STATUS_UPDATES, state => {
    return {
      graphSubscription: {
        query: ListenToStatusUpdateDocument,
        variables: { projectId: state.activeProject.id },
        listenMany: true,
        onData: [e.STATUS_UPDATES_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_STATUS_UPDATES],
      },
    }
  })

  eventsStore.on(e.STATUS_UPDATES_UPDATED, (_, data: ISubscription[]) => {
    const addedOrUpdatedStatuses: IStatusUpdate[] = []
    const removedStatusIds: string[] = []
    const activityIds = []
    data.forEach(({ statusUpdate: { id, item } }) => {
      if (item && !item.isDeleted) {
        activityIds.push(item.activityP6Code)
        addedOrUpdatedStatuses.push(item)
      } else {
        removedStatusIds.push(id)
        const activity = rootStore.activitiesStore.byId.get(item.activityP6Code)
        const statusUpdate = rootStore.statusUpdatesStore.byId.get(id)
        if (statusUpdate && activity && !activity.isDeleted) {
          activityIds.push(statusUpdate.activityP6Code)
        }
      }
    })

    rootStore.statusUpdatesStore.updateStatusUpdates(
      addedOrUpdatedStatuses,
      removedStatusIds,
    )

    return {
      dispatch: [e.CALCULATE_ACTIVITY_ACTUAL_DATES, activityIds],
    }
  })

  eventsStore.on(
    e.CALCULATE_ACTIVITY_ACTUAL_DATES,
    (state, activityIds: string[]) => {
      const { activitiesStore, statusUpdatesStore } = rootStore
      if (
        !activitiesStore.isDataReceived ||
        !statusUpdatesStore.isDataReceived
      ) {
        return
      }

      state.loading.set(GET_ACTIVITY_ACTUAL_DATES, true)
      const wholeMap = statusUpdatesStore.allStatusesUpdatesByActivityMap
      const statusUpdatesByActivityMap = activityIds
        ? activityIds.reduce(
            (map, id) => Object.assign(map, { [id]: wholeMap[id] }),
            {},
          )
        : wholeMap

      const data = JSON.stringify(
        {
          activities:
            activitiesStore.getActivitiesToCalculateDates(activityIds),
          statusUpdatesByActivityMap,
          timezoneId: rootStore.projectDateStore.timezoneId,
        },
        (_key, value) => {
          if (!value) {
            return value
          }

          if (value.getActivityToUpdateDates) {
            return value.getActivityToUpdateDates()
          }

          if (value.getStatusUpdateToUpdateDates) {
            return value.getStatusUpdateToUpdateDates()
          }

          return value
        },
      )

      return {
        worker: {
          type: GET_ACTIVITY_ACTUAL_DATES,
          data,
          onSuccess: [e.CALCULATE_ACTIVITY_ACTUAL_DATES_SUCCESS],
          onError: [e.COMPLETE_REQUEST, e.CALCULATE_ACTIVITY_ACTUAL_DATES],
        },
      }
    },
  )

  eventsStore.on(
    e.CALCULATE_ACTIVITY_ACTUAL_DATES_SUCCESS,
    (state, datesMap) => {
      rootStore.activitiesStore.updateActivitiesActualDates(datesMap)
      rootStore.activitiesStore.calculateActivityDurations()
      state.loading.set(GET_ACTIVITY_ACTUAL_DATES, false)

      return {
        dispatch: [e.TRIGGER_CALCULATE_ACTIVITY_TREE],
      }
    },
  )

  eventsStore.on(e.TRIGGER_CALCULATE_ACTIVITY_TREE, () => {
    // service event
    // aim: dispatch CALCULATE_ACTIVITY_TREE if needs and required data are received
    // see ActivityListComponent.store.ts (onTreeRequest)
  })

  eventsStore.on(e.TRIGGER_CALCULATE_DELIVERY_TREE, () => {
    // service event
    // aim: dispatch CALCULATE_DELIVERY_TREE if needs and required data are received
    // see DeliveryListComponent.store.ts (onTreeRequest)
  })

  eventsStore.on(e.LOAD_AND_LISTEN_TO_ACTIVITY_PRESETS, state => {
    return {
      graphQuery: {
        query: GetActivityPresetListDocument,
        variables: {
          projectId: state.activeProject.id,
          userId: state.user.id,
        },
        onSuccess: [e.ACTIVITY_PRESETS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_ACTIVITY_PRESETS],
      },
    }
  })

  eventsStore.on(
    e.ACTIVITY_PRESETS_RECEIVED,
    (state, { activityPresets }: IQuery) => {
      state.activityPresets = activityPresets.data.map(dto =>
        ActivityPreset.fromDto(dto),
      )

      return {
        graphSubscription: {
          query: ListenToActivityPresetDocument,
          variables: {
            projectId: state.activeProject.id,
            userId: state.user.id,
          },
          onSuccess: [e.NO_EFFECT],
          onData: [e.ACTIVITY_PRESET_UPDATED],
          onError: [e.REQUEST_ERROR, e.ACTIVITY_PRESETS_RECEIVED],
        },
      }
    },
  )

  eventsStore.on(
    e.ACTIVITY_PRESET_UPDATED,
    (state, { activityPreset: { id, item } }: ISubscription) => {
      const existingItemIndex = state.activityPresets.findIndex(
        ap => ap.id === id,
      )
      if (existingItemIndex > -1) {
        state.activityPresets.splice(existingItemIndex, 1)
      }

      if (item) {
        state.activityPresets.push(ActivityPreset.fromDto(item))
      }
    },
  )

  eventsStore.on(e.GET_ACTIVITY_CUSTOM_FILTERS, state => {
    return {
      graphQuery: {
        query: GetActivityCustomFiltersByProjectDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.GET_ACTIVITY_CUSTOM_FILTERS_SUCCESS],
        onError: [e.REQUEST_ERROR, e.GET_ACTIVITY_CUSTOM_FILTERS],
      },
    }
  })

  eventsStore.on(e.GET_DELIVERY_CUSTOM_FILTERS, state => {
    return {
      graphQuery: {
        query: GetDeliveryCustomFiltersByProjectDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.GET_DELIVERY_CUSTOM_FILTERS_SUCCESS],
        onError: [e.REQUEST_ERROR, e.GET_DELIVERY_CUSTOM_FILTERS],
      },
    }
  })

  eventsStore.on(
    e.GET_DELIVERY_CUSTOM_FILTERS_SUCCESS,
    (_, { deliveryListCustomFilters: { data: filters } }: IQuery) => {
      rootStore.customDeliveryListFiltersStore.receiveList(filters)
    },
  )

  eventsStore.on(e.GET_WORKFLOW_CUSTOM_FILTERS, state => {
    return {
      graphQuery: {
        query: GetWorkflowCustomFiltersByProjectDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.GET_WORKFLOW_CUSTOM_FILTERS_SUCCESS],
        onError: [e.REQUEST_ERROR, e.GET_WORKFLOW_CUSTOM_FILTERS],
      },
    }
  })

  eventsStore.on(
    e.GET_WORKFLOW_CUSTOM_FILTERS_SUCCESS,
    (_, { workflowsCustomFilters: { data: filters } }: IQuery) => {
      rootStore.customWorkflowsListFiltersStore.receiveList(filters)
    },
  )

  eventsStore.on(
    e.GET_ACTIVITY_CUSTOM_FILTERS_SUCCESS,
    (_, { activityListCustomFilters: { data: filters } }: IQuery) => {
      rootStore.customActivityListFiltersStore.receiveList(filters)
    },
  )

  eventsStore.on(e.GET_HIERARCHY_CONFIGURATION, state => {
    state.loading.set(e.GET_HIERARCHY_CONFIGURATION, true)

    return {
      graphQuery: {
        query: GetHierarchyConfigurationsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.GET_HIERARCHY_CONFIGURATION_SUCCESS],
        onError: [e.REQUEST_ERROR, e.GET_HIERARCHY_CONFIGURATION],
      },
    }
  })

  eventsStore.on(
    e.GET_HIERARCHY_CONFIGURATION_SUCCESS,
    (state, { hierarchyConfigurations }: IQuery) => {
      state.loading.set(e.GET_HIERARCHY_CONFIGURATION, false)

      const config = hierarchyConfigurations.data[0]
      rootStore.hierarchyConfigurationStore.receiveConfiguration(config)
    },
  )

  eventsStore.on(e.LISTEN_TO_USERS, state => {
    return {
      graphSubscription: {
        query: ListenToUsersByProjectDocument,
        variables: { projectId: state.activeProject.id },
        onData: [e.USER_RECEIVED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_USERS],
      },
    }
  })

  eventsStore.on(
    e.USER_RECEIVED,
    (state, { user: { item } }: ISubscription) => {
      const user = User.fromDto(item)

      if (user.id === state.user.id) {
        state.user = user
      }

      if (rootStore.userProjectsStore.hasUserAccessToActiveProject(user)) {
        rootStore.projectMembersStore.setOne(user.getCopy() as User)
      }
    },
  )

  eventsStore.on(e.INIT_COMPLETE, state => {
    state.loading.clear()
    state.didInitialize = true
  })

  eventsStore.on(e.LOAD_CAST_ASSIGNMENTS, state => {
    state.loading.set(e.LOAD_CAST_ASSIGNMENTS, true)
    return {
      graphQuery: {
        query: GetCastAssignmentListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.CAST_ASSIGNMENTS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_CAST_ASSIGNMENTS],
      },
    }
  })

  eventsStore.on(
    e.CAST_ASSIGNMENTS_RECEIVED,
    (state, { castAssignments }: IQuery) => {
      state.loading.set(e.LOAD_CAST_ASSIGNMENTS, false)
      rootStore.castAssignmentsStore.receiveList(castAssignments?.data || [])
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_ACTIVITY_ASSIGNMENTS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_ACTIVITY_ASSIGNMENTS, true)
    return {
      graphQuery: {
        query: GetActivityAssignmentListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.ACTIVITY_ASSIGNMENTS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_ACTIVITY_ASSIGNMENTS],
      },
    }
  })

  eventsStore.on(
    e.ACTIVITY_ASSIGNMENTS_RECEIVED,
    (state, { activityAssignments }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_ACTIVITY_ASSIGNMENTS, false)
      rootStore.activityAssignmentsStore.receiveList(
        activityAssignments?.data || [],
      )
      return {
        dispatch: [e.LISTEN_TO_ACTIVITY_ASSIGNMENTS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_ACTIVITY_ASSIGNMENTS, state => {
    return {
      graphSubscription: {
        query: ListenToActivityAssignmentsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        listenMany: true,
        onData: [e.ACTIVITY_ASSIGNMENTS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_ACTIVITY_ASSIGNMENTS],
      },
    }
  })
  eventsStore.on(
    e.ACTIVITY_ASSIGNMENTS_UPDATED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ activityAssignment }) => {
        if (activityAssignment) {
          rootStore.activityAssignmentsStore.receiveOne(
            activityAssignment.id,
            activityAssignment.item.isDeleted ? null : activityAssignment.item,
          )
        }
      })
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_DELIVERY_ASSIGNMENTS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_DELIVERY_ASSIGNMENTS, true)

    return {
      graphQuery: {
        query: GetDeliveryAssignmentListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.DELIVERY_ASSIGNMENTS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_DELIVERY_ASSIGNMENTS],
      },
    }
  })

  eventsStore.on(
    e.DELIVERY_ASSIGNMENTS_RECEIVED,
    (state, { deliveryAssignments }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_DELIVERY_ASSIGNMENTS, false)
      rootStore.deliveryAssignmentsStore.receiveList(
        deliveryAssignments?.data || [],
      )
      return {
        dispatch: [e.LISTEN_TO_DELIVERY_ASSIGNMENTS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_DELIVERY_ASSIGNMENTS, state => {
    return {
      graphSubscription: {
        query: ListenToDeliveryAssignmentsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        listenMany: true,
        onData: [e.DELIVERY_ASSIGNMENTS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_DELIVERY_ASSIGNMENTS],
      },
    }
  })

  eventsStore.on(
    e.DELIVERY_ASSIGNMENTS_UPDATED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ deliveryAssignment }) => {
        if (deliveryAssignment) {
          rootStore.deliveryAssignmentsStore.receiveOne(
            deliveryAssignment.id,
            deliveryAssignment.item,
          )
        }
      })
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_SITE_PERMIT_ASSIGNMENTS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_SITE_PERMIT_ASSIGNMENTS, true)

    return {
      graphQuery: {
        query: GetSitePermitAssignmentListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.SITE_PERMIT_ASSIGNMENTS_RECEIVED],
        onError: [
          e.REQUEST_ERROR,
          e.LOAD_AND_LISTEN_TO_SITE_PERMIT_ASSIGNMENTS,
        ],
      },
    }
  })

  eventsStore.on(
    e.SITE_PERMIT_ASSIGNMENTS_RECEIVED,
    (state, { sitePermitAssignments }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_SITE_PERMIT_ASSIGNMENTS, false)
      rootStore.sitePermitAssignmentsStore.receiveList(
        sitePermitAssignments?.data || [],
      )
      return {
        dispatch: [e.LISTEN_TO_SITE_PERMIT_ASSIGNMENTS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_SITE_PERMIT_ASSIGNMENTS, state => {
    return {
      graphSubscription: {
        query: ListenToSitePermitAssignmentsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        listenMany: true,
        onData: [e.SITE_PERMIT_ASSIGNMENTS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_SITE_PERMIT_ASSIGNMENTS],
      },
    }
  })

  eventsStore.on(
    e.SITE_PERMIT_ASSIGNMENTS_UPDATED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ sitePermitAssignment }) => {
        if (sitePermitAssignment) {
          rootStore.sitePermitAssignmentsStore.receiveOne(
            sitePermitAssignment.id,
            sitePermitAssignment.item,
          )
        }
      })
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_DELIVERIES_FOLLOWINGS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_DELIVERIES_FOLLOWINGS, true)

    return {
      graphQuery: {
        query: GetDeliveryFollowingListDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.DELIVERY_FOLLOWINGS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_DELIVERIES_FOLLOWINGS],
      },
    }
  })

  eventsStore.on(
    e.DELIVERY_FOLLOWINGS_RECEIVED,
    (state, { deliveryFollowings }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_DELIVERIES_FOLLOWINGS, false)

      rootStore.deliveryFollowingsStore.receiveList(
        (deliveryFollowings?.data as IAssociationDto[]) || [],
      )

      return {
        dispatch: [e.LISTEN_TO_DELIVERY_FOLLOWING],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_DELIVERY_FOLLOWING, state => {
    return {
      graphSubscription: {
        query: ListenToDeliveryFollowingDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        listenMany: true,
        onData: [e.DELIVERY_FOLLOWING_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_DELIVERY_FOLLOWING],
      },
    }
  })

  eventsStore.on(
    e.DELIVERY_FOLLOWING_UPDATED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ deliveryFollowing }) => {
        if (deliveryFollowing) {
          rootStore.deliveryFollowingsStore.receiveOne(
            deliveryFollowing.id,
            deliveryFollowing.item as IAssociationDto,
          )
        }
      })
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_PROJECT_TEAMS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_PROJECT_TEAMS, true)

    return {
      graphQuery: {
        query: TeamsByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.PROJECT_TEAMS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_PROJECT_TEAMS],
      },
    }
  })

  eventsStore.on(e.PROJECT_TEAMS_RECEIVED, (state, { teams }: IQuery) => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_PROJECT_TEAMS, false)

    rootStore.projectTeamsStore.receiveList(teams?.data || [])

    return {
      dispatch: [e.LISTEN_TO_PROJECT_TEAMS],
    }
  })

  eventsStore.on(e.LISTEN_TO_PROJECT_TEAMS, state => {
    return {
      graphSubscription: {
        query: ListenTeamsByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.PROJECT_TEAMS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_PROJECT_TEAMS],
      },
    }
  })

  eventsStore.on(
    e.PROJECT_TEAMS_UPDATED,
    (_: InitialState, { team }: ISubscription) => {
      if (team) {
        rootStore.projectTeamsStore.receiveOne(team.id, team.item)
      }
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_PROJECT_TRADES, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_PROJECT_TRADES, true)

    return {
      graphQuery: {
        query: TradesByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.PROJECT_TRADES_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_PROJECT_TRADES],
      },
    }
  })

  eventsStore.on(e.PROJECT_TRADES_RECEIVED, (state, { trades }: IQuery) => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_PROJECT_TRADES, false)

    rootStore.projectTradesStore.receiveList(trades?.data || [])

    return {
      dispatch: [e.LISTEN_TO_PROJECT_TRADES],
    }
  })

  eventsStore.on(e.LISTEN_TO_PROJECT_TRADES, state => {
    return {
      graphSubscription: {
        query: ListenTradesByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.PROJECT_TRADE_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_PROJECT_TRADES],
      },
    }
  })

  eventsStore.on(
    e.PROJECT_TRADE_UPDATED,
    (_: InitialState, { trade }: ISubscription) => {
      if (trade) {
        rootStore.projectTradesStore.receiveOne(trade.id, trade.item)
      }
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_PROJECT_ROLES, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_PROJECT_ROLES, true)

    return {
      graphQuery: {
        query: UserRolesByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.PROJECT_ROLES_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_PROJECT_ROLES],
      },
    }
  })

  eventsStore.on(e.PROJECT_ROLES_RECEIVED, (state, { userRoles }: IQuery) => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_PROJECT_ROLES, false)

    rootStore.projectRolesStore.receiveList(userRoles?.data || [])

    return {
      dispatch: [e.LISTEN_TO_PROJECT_ROLES],
    }
  })

  eventsStore.on(e.LISTEN_TO_PROJECT_ROLES, state => {
    return {
      graphSubscription: {
        query: ListenUserRolesByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.PROJECT_ROLES_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_PROJECT_ROLES],
      },
    }
  })

  eventsStore.on(
    e.PROJECT_ROLES_UPDATED,
    (_: InitialState, { userRole }: ISubscription) => {
      if (userRole) {
        rootStore.projectRolesStore.receiveOne(userRole.id, userRole.item)
      }
    },
  )

  const scheduleEntitiesReceivedEvents: EventTypes[] = [
    e.ACTIVITIES_RECEIVED,
    e.ACTIVITY_CODES_RECEIVED,
    e.CALENDARS_RECEIVED,
    e.ACTIVITY_CODE_TYPES_RECEIVED,
    e.ACTIVITY_CODE_RELATIONSHIPS_RECEIVED,
    e.RESOURCES_RECEIVED,
    e.ACTIVITY_RESOURCE_RELATIONSHIPS_RECEIVED,
  ]

  eventsStore.on(e.GET_SCHEDULE, (state, date?: Date) => {
    if (!state.activeScheduleId) {
      return
    }

    state.loading.set(e.GET_SCHEDULE, true)
    rootStore.clearOutScheduleData()

    return {
      flow: {
        startWith: [
          [
            e.LOAD_SCHEDULE_ENTITIES,
            GetActivitiesDocument,
            e.ACTIVITIES_RECEIVED,
            date,
          ],
          [
            e.LOAD_SCHEDULE_ENTITIES,
            GetActivityCodesDocument,
            e.ACTIVITY_CODES_RECEIVED,
          ],
          [
            e.LOAD_SCHEDULE_ENTITIES,
            GetCalendarsDocument,
            e.CALENDARS_RECEIVED,
          ],
          [
            e.LOAD_SCHEDULE_ENTITIES,
            GetActivityCodeTypesDocument,
            e.ACTIVITY_CODE_TYPES_RECEIVED,
          ],
          [
            e.LOAD_SCHEDULE_ENTITIES,
            GetActivityCodeRelationshipsDocument,
            e.ACTIVITY_CODE_RELATIONSHIPS_RECEIVED,
          ],
          [
            e.LOAD_SCHEDULE_ENTITIES,
            GetResourcesDocument,
            e.RESOURCES_RECEIVED,
          ],
          [
            e.LOAD_SCHEDULE_ENTITIES,
            GetActivityResourceRelationshipsDocument,
            e.ACTIVITY_RESOURCE_RELATIONSHIPS_RECEIVED,
          ],
        ],
        rules: [
          {
            when: 'on',
            event: e.GET_SCHEDULE_ERROR,
            shouldTerminate: true,
          },
          {
            when: 'all',
            events: scheduleEntitiesReceivedEvents.concat(
              e.GET_SCHEDULE_SUCCESS,
            ),
            dispatch: [e.SET_SCHEDULE_DATA],
            successArgs: [],
          },
        ],
      },
      graphQuery: {
        query: GetScheduleDocument,
        variables: { id: state.activeScheduleId },
        onSuccess: [e.GET_SCHEDULE_SUCCESS],
        onError: [e.REQUEST_ERROR, e.GET_SCHEDULE],
      },
    }
  })

  scheduleEntitiesReceivedEvents.forEach(event => {
    eventsStore.on(event, emptyHandler)
  })

  eventsStore.on(
    e.LOAD_SCHEDULE_ENTITIES,
    (
      state,
      getEntitiesDocument: DocumentNode,
      entitiesReceivedEvent: EventTypes,
      date?: Date,
    ) => {
      return {
        graphQuery: {
          query: getEntitiesDocument,
          variables: {
            scheduleId: state.activeScheduleId,
            date: date?.getTime(),
            timezoneId: date
              ? rootStore.projectDateStore.getClientTimezoneId()
              : null,
          },
          onSuccess: [entitiesReceivedEvent],
          onError: [e.GET_SCHEDULE_ERROR],
        },
      }
    },
  )

  eventsStore.on(e.GET_SCHEDULE_ERROR, error => {
    return {
      dispatchN: [
        [e.REQUEST_ERROR, e.LOAD_SCHEDULE_ENTITIES, error],
        [e.REQUEST_ERROR, e.GET_SCHEDULE, error],
      ],
    }
  })

  eventsStore.on(e.SET_SCHEDULE_DATA, (state, ...dataMap: Array<[IQuery]>) => {
    const scheduleData: IQuery = dataMap.reduce(
      (result, [query]) => Object.assign(result, query),
      {},
    )
    rootStore.setProjectDataFromSchedule(scheduleData)
    state.loading.set(e.GET_SCHEDULE, false)
    return {
      dispatch: [e.LOAD_AND_LISTEN_TO_STATUS_UPDATES],
    }
  })

  eventsStore.on(e.LISTEN_TO_ACTIVITY_CODE_TYPE, state => {
    return {
      graphSubscription: {
        query: ListenToActivityCodeTypeDocument,
        variables: {
          scheduleId: state.activeScheduleId,
        },
        onData: [e.ACTIVITY_CODE_TYPE_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.ACTIVITY_CODE_TYPE_UPDATED],
      },
    }
  })

  eventsStore.on(
    e.ACTIVITY_CODE_TYPE_UPDATED,
    (_, { activityCodeType }: ISubscription) => {
      if (!activityCodeType) {
        return
      }

      const { item, id } = activityCodeType
      const { activityCodeTypesStore } = rootStore
      activityCodeTypesStore.updateOne(id, item)
    },
  )

  eventsStore.on(e.LISTEN_TO_ACTIVITY_CODE, state => {
    return {
      graphSubscription: {
        query: ListenToActivityCodeDocument,
        variables: {
          scheduleId: state.activeScheduleId,
        },
        onData: [e.ACTIVITY_CODE_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.ACTIVITY_CODE_UPDATED],
      },
    }
  })

  eventsStore.on(
    e.ACTIVITY_CODE_UPDATED,
    (_, { activityCode }: ISubscription) => {
      if (!activityCode) {
        return
      }

      const { item, id } = activityCode
      const { activityCodesStore } = rootStore
      activityCodesStore.updateOne(id, item)
    },
  )

  eventsStore.on(e.LISTEN_TO_RESOURCE, state => {
    return {
      graphSubscription: {
        query: ListenToResourceDocument,
        variables: {
          scheduleId: state.activeScheduleId,
        },
        onData: [e.RESOURCE_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.RESOURCE_UPDATED],
      },
    }
  })

  eventsStore.on(e.RESOURCE_UPDATED, (_, { resource }: ISubscription) => {
    if (!resource) {
      return
    }

    const { item, id } = resource
    const { resourcesStore } = rootStore
    resourcesStore.updateOne(id, item)
  })

  eventsStore.on(e.LISTEN_TO_ACTIVITY_RESOURCE_RELATIONSHIP, state => {
    return {
      graphSubscription: {
        query: ListenToActivityResourceRelationshipDocument,
        variables: {
          scheduleId: state.activeScheduleId,
        },
        listenMany: true,
        onData: [e.ACTIVITY_RESOURCE_RELATIONSHIP_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.ACTIVITY_RESOURCE_RELATIONSHIP_UPDATED],
      },
    }
  })

  eventsStore.on(
    e.ACTIVITY_RESOURCE_RELATIONSHIP_UPDATED,
    (_, data: ISubscription[]) => {
      data.forEach(({ activityResourceRelationship }) => {
        const { item, id } = activityResourceRelationship

        rootStore.resourcesStore.updateOneRelationship(id, item)
      })
    },
  )

  eventsStore.on(e.LISTEN_TO_ACTIVITY_CODE_RELATIONSHIP, state => {
    return {
      graphSubscription: {
        query: ListenToActivityCodeRelationshipDocument,
        variables: {
          scheduleId: state.activeScheduleId,
        },
        onData: [e.ACTIVITY_CODE_RELATIONSHIP_UPDATED],
        listenMany: true,
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.ACTIVITY_UPDATED],
      },
    }
  })

  eventsStore.on(
    e.ACTIVITY_CODE_RELATIONSHIP_UPDATED,
    (_, data: ISubscription[]) => {
      const { activityCodeRelationshipsStore, activitiesStore } = rootStore
      data.forEach(({ activityCodeRelationship }) => {
        const { item, id } = activityCodeRelationship

        activityCodeRelationshipsStore.updateOne(id, item)
        activitiesStore.setActivityData()
      })
    },
  )

  eventsStore.on(e.LISTEN_TO_ACTIVITY, state => {
    return {
      graphSubscription: {
        query: ListenToActivityDocument,
        variables: {
          scheduleId: state.activeScheduleId,
        },
        listenMany: true,
        onData: [e.ACTIVITY_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.ACTIVITY_UPDATED],
      },
    }
  })

  eventsStore.on(e.ACTIVITY_UPDATED, (state, data: ISubscription[]) => {
    data.forEach(({ activity }) => {
      if (!activity) {
        return
      }

      const {
        item,
        item: { code, isDeleted },
      } = activity
      const { activitiesStore } = rootStore

      if (isDeleted) {
        activitiesStore.removeOne(code)
      } else {
        activitiesStore.updateOne(code, item)
        activitiesStore.setActivityData(code)

        return {
          dispatch: [e.CALCULATE_ACTIVITY_ACTUAL_DATES, [code]],
        }
      }
    })
  })

  eventsStore.on(e.GET_PROJECT_CONFIG, state => {
    const { id: activeProjectId } = state.activeProject

    if (!activeProjectId) {
      return
    }

    return {
      graphQuery: {
        query: ProjectConfigurationByNameDocument,
        variables: {
          id: activeProjectId,
        },
        onSuccess: [e.PROJECT_CONFIG_RECEIVED],
        onError: [e.REQUEST_ERROR, e.GET_PROJECT_CONFIG],
      },
    }
  })

  eventsStore.on(e.PROJECT_CONFIG_RECEIVED, (state, { project }: IQuery) => {
    state.featureFlags = {}
    flaggedFeaturesList.forEach(feature => {
      state.featureFlags[feature] = project.projectConfig[feature]
    })
  })

  eventsStore.on(e.LOAD_AND_LISTEN_TO_MATERIAL_OPTIONS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_MATERIAL_OPTIONS, true)

    return {
      graphQuery: {
        query: GetProjectMaterialOptionsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.MATERIAL_OPTIONS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_MATERIAL_OPTIONS],
      },
    }
  })

  eventsStore.on(
    e.MATERIAL_OPTIONS_RECEIVED,
    (state, { projectMaterialOptions }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_MATERIAL_OPTIONS, false)

      state.projectMaterialOptions =
        projectMaterialOptions.data[0] ||
        state.getDefaultProjectMaterialOptions()

      return {
        dispatch: [e.LISTEN_TO_MATERIAL_OPTIONS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_MATERIAL_OPTIONS, state => {
    return {
      graphSubscription: {
        query: ListenToProjectMaterialOptionsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.MATERIAL_OPTIONS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_MATERIAL_OPTIONS],
      },
    }
  })

  eventsStore.on(
    e.MATERIAL_OPTIONS_UPDATED,
    (state: InitialState, { projectMaterialOptions }: ISubscription) => {
      state.projectMaterialOptions = projectMaterialOptions.item
    },
  )

  eventsStore.on(e.LISTEN_TO_PROJECT, state => {
    return {
      graphSubscription: {
        query: ListenToProjectDocument,
        variables: {
          id: state.activeProject.id,
        },
        onData: [e.PROJECT_RECEIVED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_PROJECT],
      },
    }
  })

  eventsStore.on(e.PROJECT_RECEIVED, (state, { project }: ISubscription) => {
    const projectDto = project.item

    const areMaterialsUpdated =
      state.activeProject.materialsUploadId !== projectDto.materialsUploadId

    rootStore.projectsStore.setOne(projectDto)
    state.activeProject = Project.fromDto(projectDto)

    if (areMaterialsUpdated) {
      return {
        dispatch: [e.MATERIALS_UPLOADED],
      }
    }
  })

  eventsStore.on(e.LOAD_AND_LISTEN_TO_PROJECT_ADDRESS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_PROJECT_ADDRESS, true)
    return {
      graphQuery: {
        query: GetProjectAddressDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.PROJECT_ADDRESS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_PROJECT_ADDRESS],
      },
    }
  })

  eventsStore.on(
    e.PROJECT_ADDRESS_RECEIVED,
    (state, { projectAddresses }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_PROJECT_ADDRESS, false)
      state.projectAddress =
        projectAddresses.data[0] || state.getDefaultProjectAddress()

      return {
        dispatch: [e.LISTEN_TO_PROJECT_ADDRESS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_PROJECT_ADDRESS, state => {
    return {
      graphSubscription: {
        query: ListenToProjectAddressDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.PROJECT_ADDRESS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_PROJECT_ADDRESS],
      },
    }
  })

  eventsStore.on(
    e.PROJECT_ADDRESS_UPDATED,
    (state: InitialState, { projectAddress }: ISubscription) => {
      state.loading.set(e.SAVE_PROJECT_ADDRESSES, false)
      state.projectAddress = projectAddress.item
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_PROJECT_TYPE_OPTIONS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_PROJECT_TYPE_OPTIONS, true)

    return {
      graphQuery: {
        query: GetProjectTypeOptionsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.PROJECT_TYPE_OPTIONS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_PROJECT_TYPE_OPTIONS],
      },
    }
  })

  eventsStore.on(e.LISTEN_TO_PROJECT_TYPE_OPTIONS, state => {
    return {
      graphSubscription: {
        query: ListenToProjectTypeOptionsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.PROJECT_TYPE_OPTIONS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_PROJECT_TYPE_OPTIONS],
      },
    }
  })

  eventsStore.on(
    e.PROJECT_TYPE_OPTIONS_UPDATED,
    (state: InitialState, { projectTypeOptions }: ISubscription) => {
      state.loading.set(e.SAVE_PROJECT_TYPE_OPTIONS, false)
      state.projectTypeOptions = projectTypeOptions.item
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_PROJECT_SU_OPTIONS, state => {
    return {
      graphQuery: {
        query: GetProjectStatusUpdateOptionsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.PROJECT_SU_OPTIONS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_PROJECT_SU_OPTIONS],
      },
    }
  })

  eventsStore.on(
    e.PROJECT_SU_OPTIONS_RECEIVED,
    (state, { projectStatusUpdateOptions }: IQuery) => {
      state.projectStatusUpdateOptions =
        projectStatusUpdateOptions.data[0] ||
        state.getDefaultProjectStatusUpdateOptions()

      return {
        dispatch: [e.LISTEN_TO_PROJECT_SU_OPTIONS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_PROJECT_SU_OPTIONS, state => {
    return {
      graphSubscription: {
        query: ListenToProjectStatusUpdateOptionsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.PROJECT_SU_OPTIONS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_PROJECT_SU_OPTIONS],
      },
    }
  })

  eventsStore.on(
    e.PROJECT_SU_OPTIONS_UPDATED,
    (state: InitialState, { projectStatusUpdateOptions }: ISubscription) => {
      state.projectStatusUpdateOptions = projectStatusUpdateOptions.item
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_PROJECT_COLORING_OPTIONS, state => {
    return {
      graphQuery: {
        query: GetProjectColoringOptionsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.PROJECT_COLORING_OPTIONS_RECEIVED],
        onError: [
          e.REQUEST_ERROR,
          e.LOAD_AND_LISTEN_TO_PROJECT_COLORING_OPTIONS,
        ],
      },
    }
  })

  eventsStore.on(
    e.PROJECT_COLORING_OPTIONS_RECEIVED,
    (state, { projectColoringOptions }: IQuery) => {
      state.projectColoringOptions =
        projectColoringOptions.data[0] ||
        state.getDefaultProjectColoringOptions()

      return {
        dispatch: [e.LISTEN_TO_PROJECT_COLORING_OPTIONS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_PROJECT_COLORING_OPTIONS, state => {
    return {
      graphSubscription: {
        query: ListenToProjectColoringOptionsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.PROJECT_COLORING_OPTIONS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_PROJECT_COLORING_OPTIONS],
      },
    }
  })

  eventsStore.on(
    e.PROJECT_COLORING_OPTIONS_UPDATED,
    (state: InitialState, { projectColoringOptions }: ISubscription) => {
      state.projectColoringOptions = projectColoringOptions.item
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_ACTIVITIES_CONFIGURATIONS, state => {
    return {
      graphQuery: {
        query: GetActivitiesConfigurationsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.ACTIVITIES_CONFIGURATIONS_RECEIVED],
        onError: [
          e.REQUEST_ERROR,
          e.LOAD_AND_LISTEN_TO_ACTIVITIES_CONFIGURATIONS,
        ],
      },
    }
  })

  eventsStore.on(
    e.ACTIVITIES_CONFIGURATIONS_RECEIVED,
    (state, { activitiesConfigurations }: IQuery) => {
      state.updateActivitiesConfigurations(activitiesConfigurations.data[0])

      return {
        dispatch: [e.LISTEN_TO_ACTIVITIES_CONFIGURATIONS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_ACTIVITIES_CONFIGURATIONS, state => {
    return {
      graphSubscription: {
        query: ListenToActivitiesConfigurationsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.ACTIVITIES_CONFIGURATIONS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_ACTIVITIES_CONFIGURATIONS],
      },
    }
  })

  eventsStore.on(
    e.ACTIVITIES_CONFIGURATIONS_UPDATED,
    (state: InitialState, { activitiesConfigurations }: ISubscription) => {
      state.updateActivitiesConfigurations(activitiesConfigurations.item)
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_DELIVERY_CONFIGURATIONS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_DELIVERY_CONFIGURATIONS, true)

    return {
      graphQuery: {
        query: GetDeliveryConfigurationsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.DELIVERY_CONFIGURATIONS_RECEIVED],
        onError: [
          e.REQUEST_ERROR,
          e.LOAD_AND_LISTEN_TO_DELIVERY_CONFIGURATIONS,
        ],
      },
    }
  })

  eventsStore.on(
    e.DELIVERY_CONFIGURATIONS_RECEIVED,
    (state, { deliveryConfigurations }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_DELIVERY_CONFIGURATIONS, false)

      state.updateDeliveryConfigurations(deliveryConfigurations.data[0])

      return {
        dispatch: [e.LISTEN_TO_DELIVERY_CONFIGURATIONS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_DELIVERY_CONFIGURATIONS, state => {
    return {
      graphSubscription: {
        query: ListenToDeliveryConfigurationsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.DELIVERY_CONFIGURATIONS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_DELIVERY_CONFIGURATIONS],
      },
    }
  })

  eventsStore.on(
    e.DELIVERY_CONFIGURATIONS_UPDATED,
    (state: InitialState, { deliveryConfigurations }: ISubscription) => {
      state.updateDeliveryConfigurations(deliveryConfigurations.item)
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_LOGISTICS_CONFIGURATIONS, state => {
    return {
      graphQuery: {
        query: GetLogisticsConfigurationsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.LOGISTICS_CONFIGURATIONS_RECEIVED],
        onError: [
          e.REQUEST_ERROR,
          e.LOAD_AND_LISTEN_TO_LOGISTICS_CONFIGURATIONS,
        ],
      },
    }
  })

  eventsStore.on(
    e.LOGISTICS_CONFIGURATIONS_RECEIVED,
    (state, { logisticsConfigurations }: IQuery) => {
      state.loading.set(e.GET_LOGISTICS_CONFIG_AND_OPEN_DEFAULT_PAGE, false)
      state.updateLogisticsConfigurations(logisticsConfigurations.data[0])

      return {
        dispatch: [e.LISTEN_TO_LOGISTICS_CONFIGURATIONS],
      }
    },
  )

  eventsStore.on(
    e.LOGISTICS_CONFIGURATIONS_UPDATED,
    (state: InitialState, { logisticsConfigurations }: ISubscription) => {
      state.updateLogisticsConfigurations(logisticsConfigurations.item)
    },
  )

  eventsStore.on(e.LISTEN_TO_LOGISTICS_CONFIGURATIONS, state => {
    return {
      graphSubscription: {
        query: ListenToLogisticsConfigurationsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.LOGISTICS_CONFIGURATIONS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_LOGISTICS_CONFIGURATIONS],
      },
    }
  })

  eventsStore.on(e.LOAD_AND_LISTEN_TO_FORMS_CONFIGURATIONS, state => {
    return {
      graphQuery: {
        query: GetFormsConfigurationsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.FORMS_CONFIGURATIONS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_FORMS_CONFIGURATIONS],
      },
    }
  })

  eventsStore.on(
    e.FORMS_CONFIGURATIONS_RECEIVED,
    (state, { formsConfigurations }: IQuery) => {
      state.updateFormsConfigurations(formsConfigurations.data[0])

      return {
        dispatch: [e.LISTEN_TO_FORMS_CONFIGURATIONS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_FORMS_CONFIGURATIONS, state => {
    return {
      graphSubscription: {
        query: ListenToFormsConfigurationsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.FORMS_CONFIGURATIONS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_FORMS_CONFIGURATIONS],
      },
    }
  })

  eventsStore.on(
    e.FORMS_CONFIGURATIONS_UPDATED,
    (state: InitialState, { formsConfigurations }: ISubscription) => {
      state.updateFormsConfigurations(formsConfigurations.item)
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_DELIVERY_FIELDS_CONFIGURATIONS, state => {
    if (!state.delivery.isDefaultsReceived || !state.activeProject.id) {
      return
    }

    state.loading.set(e.LOAD_AND_LISTEN_TO_DELIVERY_FIELDS_CONFIGURATIONS, true)

    return {
      graphQuery: {
        query: GetDeliveryFieldsConfigurationsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.DELIVERY_FIELDS_CONFIGURATIONS_RECEIVED],
        onError: [
          e.REQUEST_ERROR,
          e.LOAD_AND_LISTEN_TO_DELIVERY_FIELDS_CONFIGURATIONS,
        ],
      },
    }
  })

  eventsStore.on(
    e.DELIVERY_FIELDS_CONFIGURATIONS_RECEIVED,
    (state, { deliveryFieldsConfigurations }: IQuery) => {
      state.loading.set(
        e.LOAD_AND_LISTEN_TO_DELIVERY_FIELDS_CONFIGURATIONS,
        false,
      )

      const custom = deliveryFieldsConfigurations.data[0]
      const { defaultMandatoryFields, defaultHiddenFields } = state.delivery

      state.delivery.mandatoryFields =
        (custom?.mandatoryFields && listToMap(custom.mandatoryFields)) ||
        defaultMandatoryFields

      state.delivery.hiddenFields =
        (custom?.hiddenFields && listToMap(custom.hiddenFields)) ||
        defaultHiddenFields

      state.delivery.customFieldNames = custom?.fieldNames
        ? listToMap(
            custom.fieldNames,
            item => item.id,
            item => item.name,
          )
        : {}

      state.delivery.deliveryFieldsConfigId = custom?.id

      return {
        dispatch: [e.LISTEN_TO_DELIVERY_FIELDS_CONFIGURATIONS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_DELIVERY_FIELDS_CONFIGURATIONS, state => {
    return {
      graphSubscription: {
        query: ListenToDeliveryFieldsConfigurationsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.DELIVERY_FIELDS_CONFIGURATIONS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_DELIVERY_FIELDS_CONFIGURATIONS],
      },
    }
  })

  eventsStore.on(
    e.DELIVERY_FIELDS_CONFIGURATIONS_UPDATED,
    (state: InitialState, { deliveryFieldsConfigurations }: ISubscription) => {
      const { mandatoryFields, hiddenFields, fieldNames, id } =
        deliveryFieldsConfigurations.item

      state.delivery.deliveryFieldsConfigId = id
      state.delivery.mandatoryFields = listToMap(mandatoryFields)
      state.delivery.hiddenFields = listToMap(hiddenFields)
      state.delivery.customFieldNames = listToMap(
        fieldNames,
        item => item.id,
        item => item.name,
      )
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_ZONEMAP_THRESHOLDS, state => {
    return {
      graphQuery: {
        query: GetZoneMapThresholdsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.ZONEMAP_THRESHOLDS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_ZONEMAP_THRESHOLDS],
      },
    }
  })

  eventsStore.on(
    e.ZONEMAP_THRESHOLDS_RECEIVED,
    (state, { zoneMapThresholds }: IQuery) => {
      state.zoneMapThresholds =
        zoneMapThresholds.data[0] || state.getDefaultZoneMapThresholds()

      return {
        dispatch: [e.LISTEN_TO_ZONEMAP_THRESHOLDS],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_ZONEMAP_THRESHOLDS, state => {
    return {
      graphSubscription: {
        query: ListenToZoneMapThresholdsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.ZONEMAP_THRESHOLDS_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_ZONEMAP_THRESHOLDS],
      },
    }
  })

  eventsStore.on(
    e.ZONEMAP_THRESHOLDS_UPDATED,
    (state: InitialState, { zoneMapThresholds }: ISubscription) => {
      state.zoneMapThresholds = zoneMapThresholds.item
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_PROJECT_HISTORY, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_PROJECT_HISTORY, true)

    return {
      graphQuery: {
        query: GetProjectHistoryDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.PROJECT_HISTORY_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_PROJECT_HISTORY],
      },
    }
  })

  eventsStore.on(
    e.PROJECT_HISTORY_RECEIVED,
    (state, { projectHistories }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_PROJECT_HISTORY, false)
      state.projectHistory =
        projectHistories.data[0] || state.getDefaultProjectHistory()

      return {
        dispatch: [e.LISTEN_TO_PROJECT_HISTORY],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_PROJECT_HISTORY, state => {
    return {
      graphSubscription: {
        query: ListenToProjectHistoryDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.PROJECT_HISTORY_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_PROJECT_HISTORY],
      },
    }
  })

  eventsStore.on(
    e.PROJECT_HISTORY_UPDATED,
    (state: InitialState, { projectHistory }: ISubscription) => {
      state.loading.set(e.SAVE_PROJECT_HISTORY, false)

      const oldScheduleId = state.activeSchedule?.id
      const newScheduleId = projectHistory.item.lastUpdated?.find(
        lu => lu.pageName === 'schedule',
      )?.updateInfo.scheduleId

      state.projectHistory = projectHistory.item

      if (oldScheduleId !== newScheduleId) {
        state.activeProject.scheduleId = newScheduleId

        return {
          dispatch: [e.SCHEDULE_LOADED],
        }
      }
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_COMPANIES, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_COMPANIES, true)

    return {
      graphQuery: {
        query: CompaniesByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.COMPANIES_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_COMPANIES],
      },
    }
  })

  eventsStore.on(e.LOAD_AND_LISTEN_TO_SCANNERS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_SCANNERS, true)

    return {
      graphQuery: {
        query: ScannersByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.SCANNERS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_SCANNERS],
      },
    }
  })

  eventsStore.on(e.LISTEN_TO_SCANNERS, state => {
    return {
      graphSubscription: {
        query: ListenScannersByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.SCANNER_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_SCANNERS],
      },
    }
  })

  eventsStore.on(e.SCANNERS_RECEIVED, (state, { scanners }) => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_SCANNERS, false)

    rootStore.scannersStore.receiveList(scanners ? scanners.data : [])

    return {
      dispatch: [e.LISTEN_TO_SCANNERS],
    }
  })

  eventsStore.on(
    e.SCANNER_UPDATED,
    (_: InitialState, { scanner }: ISubscription) => {
      if (scanner) {
        rootStore.scannersStore.receiveOne(scanner.id, scanner.item)
      }
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_SCAN_HISTORIES, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_SCAN_HISTORIES, true)

    return {
      graphQuery: {
        query: ScanHistoriesByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.SCAN_HISTORIES_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_SCAN_HISTORIES],
      },
    }
  })

  eventsStore.on(e.LISTEN_TO_SCAN_HISTORIES, state => {
    return {
      graphSubscription: {
        query: ListenScanHistoriesByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.SCAN_HISTORY_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_SCAN_HISTORIES],
      },
    }
  })

  eventsStore.on(e.SCAN_HISTORIES_RECEIVED, (state, { scanHistories }) => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_SCAN_HISTORIES, false)

    rootStore.scanHistoriesStore.receiveList(scanHistories?.data || [])

    return {
      dispatch: [e.LISTEN_TO_SCAN_HISTORIES],
    }
  })

  eventsStore.on(
    e.SCAN_HISTORY_UPDATED,
    (_: InitialState, { scanHistory }: ISubscription) => {
      if (scanHistory) {
        rootStore.scanHistoriesStore.receiveOne(
          scanHistory.id,
          scanHistory.item,
        )
      }
    },
  )

  eventsStore.on(e.GET_ACTIVITY_FILTERS_SETTINGS, state => {
    state.loading.set(e.GET_ACTIVITY_FILTERS_SETTINGS, true)

    return {
      graphQuery: {
        query: ActivityFiltersSettingsByScheduleIdDocument,
        variables: {
          scheduleId: state.activeScheduleId,
        },
        onSuccess: [e.GET_ACTIVITY_FILTERS_SETTINGS_SUCCESS],
        onError: [e.REQUEST_ERROR, e.GET_ACTIVITY_FILTERS_SETTINGS],
      },
    }
  })

  eventsStore.on(e.LISTEN_TO_COMPANIES, state => {
    return {
      graphSubscription: {
        query: ListenCompaniesByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.COMPANIES_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_COMPANIES],
      },
    }
  })

  eventsStore.on(e.COMPANIES_RECEIVED, (state, { companies }) => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_COMPANIES, false)

    rootStore.companiesStore.receiveList(companies ? companies.data : [])

    return {
      dispatch: [e.LISTEN_TO_COMPANIES],
    }
  })

  eventsStore.on(
    e.COMPANIES_UPDATED,
    (_: InitialState, { company }: ISubscription) => {
      if (company) {
        rootStore.companiesStore.receiveOne(company.id, company.item)
      }
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_USER_PROJECTS, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_USER_PROJECTS, true)

    return {
      graphQuery: {
        query: UserProjectsByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.USER_PROJECTS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_USER_PROJECTS],
      },
    }
  })

  eventsStore.on(e.LISTEN_TO_USER_PROJECTS, state => {
    return {
      graphSubscription: {
        query: ListenUserProjectsByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.USER_PROJECT_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_USER_PROJECTS],
      },
    }
  })

  eventsStore.on(
    e.USER_PROJECTS_RECEIVED,
    (state, { userProjects }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_USER_PROJECTS, false)

      rootStore.userProjectsStore.receiveList(
        userProjects ? userProjects.data : [],
      )

      return {
        dispatch: [e.LISTEN_TO_USER_PROJECTS],
      }
    },
  )

  eventsStore.on(
    e.USER_PROJECT_UPDATED,
    (state: InitialState, { userProject: { item } }: ISubscription) => {
      if (!item) {
        return
      }

      const { userId, isDeleted } = item
      rootStore.userProjectsStore.receiveOne(userId, item)

      const isAuthUserUpdated = userId === state.user.id

      if (isAuthUserUpdated) {
        if (isDeleted) {
          localStorage.setItem(
            state.user.id,
            JSON.stringify({ lastViewedProject: '' }),
          )

          return {
            dispatch: [e.INIT_APP, state.user],
          }
        } else {
          state.userActiveProjectSettings = UserProject.fromDto(item)
        }
      }
    },
  )

  eventsStore.on(
    e.GET_CONFIGURATION,
    (state: InitialState, name: string, onSuccessAction) => {
      state.loading.set(`${e.GET_CONFIGURATION}_${name}`, true)

      return {
        graphQuery: {
          query: ConfigurationByNameDocument,
          variables: { name },
          onSuccess: [onSuccessAction],
          onError: [e.REQUEST_ERROR, e.GET_CONFIGURATION],
        },
      }
    },
  )

  eventsStore.on(
    e.DEFAULT_HIDDEN_FIELDS_CONFIGURATION_RECEIVED,
    (state: InitialState, { configuration }) => {
      const cName = ConfigurationName.DefaultHiddenFields
      state.loading.set(`${e.GET_CONFIGURATION}_${cName}`, false)
      try {
        state.delivery.defaultHiddenFields = JSON.parse(configuration?.value)

        if (
          state.delivery.defaultMandatoryFields &&
          !state.delivery.isDefaultsReceived
        ) {
          state.delivery.isDefaultsReceived = true
          return {
            dispatch: [e.LOAD_AND_LISTEN_TO_DELIVERY_FIELDS_CONFIGURATIONS],
          }
        }
      } catch (e) {
        console.warn(`Incorrect configuration [${cName}]`)
      }
    },
  )

  eventsStore.on(
    e.DEFAULT_MANDATORY_FIELDS_CONFIGURATION_RECEIVED,
    (state: InitialState, { configuration }) => {
      const cName = ConfigurationName.DefaultMandatoryFields
      state.loading.set(`${e.GET_CONFIGURATION}_${cName}`, false)
      try {
        state.delivery.defaultMandatoryFields = JSON.parse(configuration?.value)
        if (
          state.delivery.defaultHiddenFields &&
          !state.delivery.isDefaultsReceived
        ) {
          state.delivery.isDefaultsReceived = true
          return {
            dispatch: [e.LOAD_AND_LISTEN_TO_DELIVERY_FIELDS_CONFIGURATIONS],
          }
        }
      } catch (e) {
        console.warn(`Incorrect configuration [${cName}]`)
      }
    },
  )

  eventsStore.on(e.LOAD_AND_LISTEN_TO_OPERATION_RULES, state => {
    state.loading.set(e.LOAD_AND_LISTEN_TO_OPERATION_RULES, true)

    return {
      graphQuery: {
        query: OperationRulesByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.OPERATION_RULES_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_OPERATION_RULES],
      },
    }
  })

  eventsStore.on(
    e.OPERATION_RULES_RECEIVED,
    (state, { operationRules }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_OPERATION_RULES, false)

      rootStore.operationRulesStore.receiveList(operationRules?.data || [])

      return {
        dispatch: [e.LISTEN_TO_OPERATION_RULES],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_OPERATION_RULES, state => {
    return {
      graphSubscription: {
        query: ListenOperationRuleByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onData: [e.OPERATION_RULES_UPDATED],
        listenMany: true,
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_OPERATION_RULES],
      },
    }
  })

  eventsStore.on(
    e.OPERATION_RULES_UPDATED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ operationRule }) => {
        if (operationRule) {
          rootStore.operationRulesStore.receiveOne(
            operationRule.id,
            operationRule.item,
          )
        }
      })
    },
  )

  eventsStore.on(e.GET_DEFAULT_TEAMS, (state: InitialState) => {
    state.loading.set(e.GET_DEFAULT_TEAMS, true)

    return {
      graphQuery: {
        query: DefaultTeamsDocument,
        variables: { limit: 100000 },
        onSuccess: [e.DEFAULT_TEAMS_RECEIVED],
        onError: [e.REQUEST_ERROR, e.GET_DEFAULT_TEAMS],
      },
    }
  })

  eventsStore.on(
    e.DEFAULT_TEAMS_RECEIVED,
    (state: InitialState, { defaultTeams }: IQuery) => {
      state.loading.set(e.GET_DEFAULT_TEAMS, false)

      rootStore.projectDefaultTeamsStore.receiveList(defaultTeams?.data)
    },
  )

  eventsStore.on(
    e.LOAD_AND_LISTEN_TO_SITE_PERMIT_BIC_INSPECTIONS,
    (state: InitialState, formId: string, callbackFn: () => void) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_SITE_PERMIT_BIC_INSPECTIONS, true)

      return {
        graphQuery: {
          query: GetSitePermitBicInspectionListDocument,
          variables: {
            projectId: state.activeProject.id,
            permitId: formId,
          },
          onSuccess: [
            e.SITE_PERMIT_BIC_INSPECTIONS_RECEIVED,
            formId,
            callbackFn,
          ],
          onError: [
            e.REQUEST_ERROR,
            e.LOAD_AND_LISTEN_TO_SITE_PERMIT_BIC_INSPECTIONS,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.SITE_PERMIT_BIC_INSPECTIONS_RECEIVED,
    (
      state: InitialState,
      formId: string,
      callbackFn: () => void,
      { sitePermitBicInspections }: IQuery,
    ) => {
      rootStore.permitBicInspectionsStore.receiveList(
        sitePermitBicInspections.data,
      )
      state.loading.set(e.LOAD_AND_LISTEN_TO_SITE_PERMIT_BIC_INSPECTIONS, false)

      return {
        dispatch: [e.LISTEN_TO_SITE_PERMIT_BIC_INSPECTIONS, formId, callbackFn],
      }
    },
  )

  eventsStore.on(
    e.LISTEN_TO_SITE_PERMIT_BIC_INSPECTIONS,
    (state: InitialState, formId: string, callbackFn: () => void) => {
      return {
        graphSubscription: {
          query: ListenToSitePermitBicInspectionDocument,
          variables: {
            projectId: state.activeProject.id,
            permitId: formId,
          },
          onData: [e.SITE_PERMIT_BIC_INSPECTION_UPDATED],
          onSuccess: [e.RUN_CALLBACK, callbackFn],
          onError: [e.REQUEST_ERROR, e.LISTEN_TO_SITE_PERMIT_BIC_INSPECTIONS],
        },
      }
    },
  )

  eventsStore.on(
    e.SITE_PERMIT_BIC_INSPECTION_UPDATED,
    (_: InitialState, { sitePermitBicInspection }: ISubscription) => {
      if (!sitePermitBicInspection) {
        return
      }

      rootStore.permitBicInspectionsStore.receiveOne(
        sitePermitBicInspection.id,
        sitePermitBicInspection.item,
      )
    },
  )

  eventsStore.on(e.GET_DEFAULT_PERMIT_TYPES, (state: InitialState) => {
    state.loading.set(e.GET_DEFAULT_PERMIT_TYPES, true)

    return {
      graphQuery: {
        query: GetDefaultPermitTypesDocument,
        variables: { limit: 100000 },
        onSuccess: [e.DEFAULT_PERMIT_TYPES_RECEIVED],
        onError: [e.REQUEST_ERROR, e.GET_DEFAULT_PERMIT_TYPES],
      },
    }
  })

  eventsStore.on(
    e.DEFAULT_PERMIT_TYPES_RECEIVED,
    (state: InitialState, { defaultPermitTypes }: IQuery) => {
      state.loading.set(e.GET_DEFAULT_PERMIT_TYPES, false)

      rootStore.permitTypesStore.receiveDefaultTypes(defaultPermitTypes?.data)
    },
  )

  eventsStore.on(
    e.GET_PDF_BLOB_URL,
    (state: InitialState, fileUrl: string, callbackFn?) => {
      state.loading.set(e.GET_PDF_BLOB_URL, true)

      return {
        http: {
          url: fileUrl,
          method: 'GET',
          mode: 'no-cors',
          blob: true,
          onSuccess: [e.GET_PDF_BLOB_URL_SUCCESS, callbackFn],
          onError: [e.REPORT_ERROR, e.GET_PDF_BLOB_URL],
        },
      }
    },
  )

  eventsStore.on(
    e.GET_PDF_BLOB_URL_SUCCESS,
    (state: InitialState, callbackFn?, blob?: Blob) => {
      state.loading.set(e.GET_PDF_BLOB_URL, false)

      return {
        dispatch: [e.RUN_CALLBACK, callbackFn(blob)],
      }
    },
  )

  eventsStore.on(
    e.GET_WEATHER_FORECASTS,
    (state, startDate: number, endDate: number) => {
      state.loading.set(e.GET_WEATHER_FORECASTS, true)

      return {
        graphQuery: {
          query: GetWeatherForecastsRangeDocument,
          variables: {
            forecastRangeRequest: {
              projectId: state.activeProject.id,
              startDate,
              endDate,
              timezoneId: rootStore.projectDateStore.getClientTimezoneId(),
            },
          },
          onSuccess: [e.WEATHER_FORECASTS_RECEIVED],
          onError: [e.REQUEST_ERROR, e.GET_WEATHER_FORECASTS],
        },
      }
    },
  )

  eventsStore.on(
    e.WEATHER_FORECASTS_RECEIVED,
    (state, { weatherForecasts }: IQuery) => {
      state.loading.set(e.GET_WEATHER_FORECASTS, false)

      rootStore.weatherForecastsStore.receiveWeather(weatherForecasts)

      return {
        dispatch: [e.NO_EFFECT],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_WEATHER_FORECASTS, state => {
    return {
      graphSubscription: {
        query: ListenWeatherForecastsByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        listenMany: true,
        onData: [e.WEATHER_FORECAST_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_WEATHER_FORECASTS],
      },
    }
  })

  eventsStore.on(
    e.WEATHER_FORECAST_UPDATED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ weatherForecast }) => {
        if (weatherForecast) {
          rootStore.weatherForecastsStore.receiveOne(weatherForecast.item)
        }
      })
    },
  )

  eventsStore.on(e.GET_RECURRING_DELIVERIES_SETTING, (state, id: string) => {
    state.loading.set(e.GET_RECURRING_DELIVERIES_SETTING, true)

    return {
      graphQuery: {
        query: GetRecurringDeliveriesSettingsByIdDocument,
        variables: {
          id,
        },
        onSuccess: [e.RECURRING_DELIVERIES_SETTING_RECEIVED],
        onError: [e.REQUEST_ERROR, e.GET_RECURRING_DELIVERIES_SETTING],
      },
    }
  })

  eventsStore.on(
    e.RECURRING_DELIVERIES_SETTING_RECEIVED,
    (state, { recurringDeliveriesSettings }: IQuery) => {
      state.loading.set(e.GET_RECURRING_DELIVERIES_SETTING, false)

      rootStore.recurringDeliveriesSettingsStore.receiveOne(
        recurringDeliveriesSettings?.id,
        recurringDeliveriesSettings,
      )

      return {
        dispatch: [e.NO_EFFECT],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_RECURRING_DELIVERIES_SETTING, state => {
    return {
      graphSubscription: {
        query: ListenToRecurringDeliveriesSettingsDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        listenMany: true,
        onData: [e.RECURRING_DELIVERIES_SETTING_UPDATED],
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.LISTEN_TO_RECURRING_DELIVERIES_SETTING],
      },
    }
  })

  eventsStore.on(
    e.RECURRING_DELIVERIES_SETTING_UPDATED,
    (_: InitialState, data: ISubscription[]) => {
      data.forEach(({ recurringDeliveriesSettings }) => {
        if (recurringDeliveriesSettings) {
          rootStore.recurringDeliveriesSettingsStore.receiveOne(
            recurringDeliveriesSettings.id,
            recurringDeliveriesSettings.item,
          )
        }
      })
    },
  )

  eventsStore.on(e.LOAD_REPORT_TEMPLATE, (_, templateId: string, callback) => {
    return {
      graphQuery: {
        query: GetReportTemplateDocument,
        variables: {
          templateId,
        },
        onSuccess: [e.REPORT_TEMPLATE_RECEIVED, callback],
        onError: [e.RUN_CALLBACK, callback],
      },
    }
  })

  eventsStore.on(
    e.REPORT_TEMPLATE_RECEIVED,
    (_, callback, { reportTemplate }: IQuery) => {
      return {
        dispatch: [e.RUN_CALLBACK, callback, reportTemplate],
      }
    },
  )

  eventsStore.on(
    e.PROJECT_INTEGRATIONS_CONFIGS_RECEIVED,
    (state: InitialState, { configuration }) => {
      const cName = getIntegrationsConfigName(state.activeProject.id)
      state.loading.set(`${e.GET_CONFIGURATION}_${cName}`, false)

      state.clearIntegrations()

      try {
        // eslint-disable-next-line no-var
        var configs = JSON.parse(configuration?.value || '[]')
      } catch {
        console.error('Unable to parse integration configurations')
        return
      }

      configs.forEach(config => state.receiveIntegrationConfig(config))

      return {
        dispatch: [e.INIT_PROJECT_INTEGRATIONS],
      }
    },
  )

  eventsStore.on(e.MATERIALS_UPLOADED, () => {
    rootStore.materialCategoryStore.clearList()
    rootStore.materialsStore.clearList()

    return {
      dispatchN: [
        [e.LOAD_AND_LISTEN_TO_MATERIAL_CATEGORIES],
        [e.LOAD_AND_LISTEN_TO_MATERIALS],
        [e.RESET_MATERIALS_FILTERS],
      ],
    }
  })

  /**
   * General purpose empty event to prevent creating same events
   */
  eventsStore.on(e.NO_EFFECT, () => {
    return
  })

  /**
   * General purpose error handling. To be expanded upon.
   */
  eventsStore.on(e.REQUEST_ERROR, handleRequestError)

  /**
   * Cleanup loading flag after a request is done
   */
  eventsStore.on(e.COMPLETE_REQUEST, handleCompleteRequest)

  /**
   * Report error to logging service
   */
  eventsStore.on(e.REPORT_ERROR, handleReportError)

  eventsStore.on(
    e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
    (state, eventType, callback, data) => {
      state.loading.set(eventType, false)
      callback?.(data)
    },
  )

  eventsStore.on(
    e.COMPLETE_REQUEST_AND_DISPATCH,
    (state, eventType, eventTypeToDispatch, ...params) => {
      state.loading.set(eventType, false)

      return {
        dispatch: [eventTypeToDispatch, ...params],
      }
    },
  )

  eventsStore.on(e.LISTEN_TO_DEPLOYMENT_VERSION, () => {
    if (isRemote) {
      return {
        http: {
          url: '/.commit_id',
          method: 'GET',
          onSuccess: [e.DEPLOYMENT_VERSION_RECEIVED],
          onError: [e.LISTEN_TO_DEPLOYMENT_VERSION_ERROR],
        },
      }
    }
  })

  eventsStore.on(
    e.DEPLOYMENT_VERSION_RECEIVED,
    (_, deploymentVersion: string) => {
      window.RT = null

      if (deploymentVersion !== Config.DEPLOYMENT_VERSION) {
        window.location.reload()
      }

      const minuteMS = 60 * 1000

      window.RT = window.setTimeout(() => {
        eventsStore.dispatch(e.LISTEN_TO_DEPLOYMENT_VERSION)
      }, minuteMS)
    },
  )

  eventsStore.on(e.LISTEN_TO_DEPLOYMENT_VERSION_ERROR, () => {
    window.RT = null
  })

  registerCommonChangeEvents(eventsStore, rootStore)
}

function shouldInterruptInitialization({
  state,
  projectsStore,
}: RootStore): boolean {
  return (
    !!state.initProjectCode && !projectsStore.hasActiveUserAccessToTargetProject
  )
}

function setLatestDeliveryByProjectAndUser({
  deliveriesStore,
  deliveryDetailsStore,
  state,
}: RootStore) {
  deliveryDetailsStore.latestDeliveryByProjectAndUser =
    deliveriesStore.getLatestDeliveryByProjectAndUser(
      state.activeProject.id,
      state.user.id,
    )
}

function updateDisplayedDeliveryIfNeed(
  { deliveriesStore, deliveryDetailsStore }: RootStore,
  updatedDeliveryId: string,
) {
  const { displayedDelivery } = deliveryDetailsStore
  if (displayedDelivery && displayedDelivery.id === updatedDeliveryId) {
    const updatedDelivery = deliveriesStore.byId.get(displayedDelivery.id)
    deliveryDetailsStore.displayedDelivery = updatedDelivery
    deliveryDetailsStore.prepareView()
  }
}

export function handleEventCallback(
  event: string,
  shouldCompleteRequest: boolean,
  callback: () => void,
): IEvent {
  return shouldCompleteRequest
    ? [e.COMPLETE_REQUEST_AND_RUN_CALLBACK, event, callback]
    : [e.RUN_CALLBACK, callback]
}

interface ITelemetryPayload {
  event?: {
    eventCategory: string
    eventAction: string
    eventLabel?: string
    eventValue?: number
  }
  timing?: {
    timingCategory: string
    timingVar: string
    timingValue: number
    timingLabel?: string
  }
}
