import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { IPermitTypeField } from '~/client/graph'
import StruxhubUserSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubUserSelector'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import InitialState from '~/client/src/shared/stores/InitialState'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import StruxhubTagValueSelector from '../../../StruxhubInputs/StruxhubSelector/StruxhubTagValueSelector'
import SitePermitCreationFormStore from '../../SitePermitCreationForm.store'
import PermitBaseFormField from './PermitBaseFormField'

const MAX_SHOWED_USERS_TAG = 2

interface IProps {
  typeField: IPermitTypeField
  isViewMode: boolean
  shouldHideResponsible: boolean

  store: SitePermitCreationFormStore
  fieldsStore: IPermitFieldsStore

  tableId?: string
  tableRowIndex?: number

  projectMembersStore?: ProjectMembersStore
  state?: InitialState
}

@inject('projectMembersStore', 'state')
@observer
export default class PermitFormUserField extends React.Component<IProps> {
  public render() {
    const { typeField, isViewMode } = this.props

    return (
      <PermitBaseFormField
        typeField={typeField}
        isViewMode={isViewMode}
        viewModeElements={this.userProfilePreviews}
      >
        {this.renderUsers()}
      </PermitBaseFormField>
    )
  }

  private renderUsers(): JSX.Element {
    const { typeField, fieldsStore, tableId, tableRowIndex } = this.props
    const { caption, isMandatory } = typeField
    const { isFieldChanged } = fieldsStore

    if (this.fieldValues.length > 1)
      return (
        <StruxhubTagValueSelector
          label={caption}
          isRequired={isMandatory}
          onClick={this.onOpenUserModal.bind(this)}
          values={this.userTags}
          shouldUseDefaultColor
          isShownAsTag
        />
      )

    return (
      <StruxhubUserSelector
        className="overflow-hidden"
        label={caption}
        isRequired={isMandatory}
        isChanged={isFieldChanged(typeField.id, 0, tableId, tableRowIndex)}
        onClick={this.onOpenUserModal.bind(this)}
        value={this.fieldValues[0]}
      />
    )
  }

  private get userProfilePreviews(): JSX.Element[] {
    const { projectMembersStore } = this.props

    return this.fieldValues
      .filter(id => projectMembersStore.hasById(id))
      .map(id => {
        const user = projectMembersStore.getById(id)
        return <UserProfilePreview key={user.id} user={user} />
      })
  }

  private get fieldValues(): string[] {
    const { typeField, fieldsStore, tableId, tableRowIndex } = this.props
    const values = fieldsStore.getFieldValues<string>(
      typeField.id,
      tableId,
      tableRowIndex,
    )

    return values?.length ? values : ['']
  }

  private onOpenUserModal() {
    const { store, typeField, tableId, tableRowIndex } = this.props
    store.setSelectedField(typeField, 0, tableId, tableRowIndex)
    store.setSelectedOnChangeHandler(this.onApply.bind(null))
  }

  private onApply = (newValue: string[]) => {
    const { typeField, fieldsStore, tableId, tableRowIndex } = this.props
    const { changeFieldValue, changeTableFieldValue } = fieldsStore

    if (typeField.isMultiple) {
      return fieldsStore.changeMultipleFieldValues(typeField, newValue)
    }

    const singleValue = newValue[0] || EMPTY_STRING
    if (!this.isTableField) {
      return changeFieldValue(typeField, 0, singleValue)
    }
    changeTableFieldValue(tableId, tableRowIndex, typeField, 0, singleValue)
  }

  private get isTableField(): boolean {
    return !!this.props.tableId
  }

  private get userTags(): { name: string }[] {
    const { projectMembersStore } = this.props

    const values = this.fieldValues.slice(0, MAX_SHOWED_USERS_TAG).map(u => ({
      name: projectMembersStore.hasById(u)
        ? projectMembersStore.getById(u).fullName
        : Localization.translator.unknownUser,
    }))

    const restCount = this.fieldValues.slice(MAX_SHOWED_USERS_TAG).length
    if (restCount) {
      values.push({
        name: `+ ${Localization.translator.xUsers(restCount)} `,
      })
    }

    return values
  }
}
