import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { v4 as uuidv4 } from 'uuid'

import { IAttachment } from '~/client/graph'

import FileAttachment from '../WorkflowCard/FileAttachment'
import TextEditor from './components/TextEditor'
import TextEditorToolbar from './components/TextEditorToolbar'

import './RichTextEditor.scss'

interface IProps {
  value: {
    content?: string
    attachments?: IAttachment[]
  }
  onChange?: (value: { content?: string; attachments?: IAttachment[] }) => void
  isReadOnly?: boolean
  isScrollable?: boolean
}

@observer
export default class RichTextEditor extends React.Component<IProps> {
  private id = `rich-text-editor-${uuidv4()}`

  public render() {
    return (
      <div className="col full-height announcement-content y-start">
        {this.renderContentInput()}
        <TextEditorToolbar addAttachment={this.addAttachment} id={this.id} />
      </div>
    )
  }

  private renderContentInput(): JSX.Element {
    const { isReadOnly, isScrollable, value } = this.props
    const { attachments, content } = value

    return (
      <div
        className={classList({
          'rich-text-content full-height': true,
          'scrollable ios-scrolling-container': isScrollable,
        })}
      >
        <div className="col x-center">
          <TextEditor
            content={content}
            applyContent={this.applyContent}
            addAttachment={this.addAttachment}
            isReadOnly={isReadOnly}
            id={this.id}
          />
          {attachments.map((attachment, idx) => (
            <FileAttachment
              key={idx}
              attachment={attachment}
              removeAttachment={!isReadOnly ? this.removeAttachment : null}
              shouldShowPdfPreview={true}
              isPdfPreviewSmall
            />
          ))}
        </div>
      </div>
    )
  }

  private applyContent = (content: string) => {
    this.props.onChange({
      ...this.props.value,
      content,
    })
  }

  private addAttachment = (attachment: IAttachment) => {
    this.props.onChange({
      ...this.props.value,
      attachments: [...this.props.value.attachments, attachment],
    })
  }

  private removeAttachment = (attachment: IAttachment) => {
    this.props.onChange({
      ...this.props.value,
      attachments: this.props.value.attachments.filter(
        a => a.fileName !== attachment.fileName,
      ),
    })
  }
}
