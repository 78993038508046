import { ApolloError, ServerError } from '@apollo/client'

import { ISubscription } from '~/client/graph'
import InitialState from '~/client/src/shared/stores/InitialState'
import GraphExecutor from '~/client/src/shared/utils/GraphExecutor'

import {
  IEvent,
  IGraphMutationEffect,
  IGraphQueryEffect,
  IGraphSubscriptionEffect,
} from '../../BaseEvents.store'
import EventsStore from '../../Events.store'
import { LOGOUT } from '../../eventConstants'

const AUTH_ERROR_CODE = 401

export default class GraphProcessor {
  public constructor(
    private readonly state: InitialState,
    private readonly executor: GraphExecutor,
  ) {}

  public async processQuery(store: EventsStore, effect: IGraphQueryEffect) {
    if (!store) {
      return
    }

    if (!effect) {
      return
    }

    const { query, variables, onSuccess, onError } = effect
    const result = await this.executor.executeQuery(query, variables)
    if (result.error) {
      this.handleError(store, onError, result.error as ApolloError)
      return
    }

    const [type, ...params] = [...onSuccess, result.data]
    store.dispatch(type, ...params)
  }

  public async processSubscription(
    store: EventsStore,
    effect: IGraphSubscriptionEffect,
    eventName: string,
  ) {
    if (!store) {
      return
    }

    if (!effect) {
      return
    }

    const { query, variables, onData, listenMany, onSuccess, onError } = effect

    try {
      await this.executor.subscribe(
        query,
        variables,
        listenMany,
        eventName,
        (data: ISubscription | ISubscription[]) => {
          const [onDataType, ...onDataParams] = [...onData, data]
          store.dispatch(onDataType, ...onDataParams)
        },
      )

      const [type, ...params] = onSuccess
      return store.dispatch(type, ...params)
    } catch (error) {
      this.handleError(store, onError, error as ApolloError)
    }
  }

  public async processMutation(
    store: EventsStore,
    effect: IGraphMutationEffect,
  ) {
    if (!store) {
      return
    }

    if (!effect) {
      return
    }

    const { mutation, variables, onSuccess, onError } = effect

    const result = await this.executor.executeMutation(mutation, variables)
    if (result.error) {
      this.handleError(store, onError, result.error as ApolloError)
      return
    }

    const [type, ...params] = [...onSuccess, result.data]
    store.dispatch(type, ...params)
  }

  public terminateAllSubscriptions() {
    this.executor.terminateAllSubscriptions()
    this.state.graphClient.clearStore()
  }

  public terminateSubscription(eventName: string) {
    this.executor.terminateSubscription(eventName)
  }

  private handleError(store: EventsStore, onError: IEvent, error: ApolloError) {
    if ((error?.networkError as ServerError)?.statusCode === AUTH_ERROR_CODE) {
      store.dispatch(LOGOUT)
      return
    }
    const [errorType, ...args] = onError
    store.dispatch(errorType, ...args, error)
  }
}
