import * as React from 'react'

import htmlReactParser, {
  DomElement,
  HTMLReactParserOptions,
} from 'html-react-parser'
import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IRichText } from '~/client/graph'

import FileType from '../../../enums/FileType'
import IFilePreviewProperties from '../../../interfaces/IFilePreviewProperties'
import EventsStore from '../../../stores/EventStore/Events.store'
import { SHOW_FULLSCREEN_PREVIEW } from '../../../stores/EventStore/eventConstants'
import VideoPlayer from '../../VideoPlayer/VideoPlayer'
import WorkflowCardExpandableContainer from '../../WorkflowCard/ExpandableContainer'
import { IMAGE_GALLERY_BLOT_NAME, VIDEO_BLOT_NAME } from './TextEditor'

interface IProps {
  value: IRichText
  className?: string
  isContentExpanded?: boolean

  onMount?: () => void
  eventsStore?: EventsStore
}

@inject('eventsStore')
@observer
export default class RichEditorParsed extends React.Component<IProps> {
  public componentDidMount(): void {
    this.props.onMount?.()
  }

  public render() {
    const { isContentExpanded, className } = this.props
    if (isContentExpanded) {
      return (
        <div
          className={classList({
            'content-section px10': true,
            [className]: !!className,
          })}
          onClick={this.handleContentClick}
        >
          {this.announcementContent}
        </div>
      )
    }

    return (
      <WorkflowCardExpandableContainer
        className={classList({
          'content-section': true,
          [className]: !!className,
        })}
      >
        {this.announcementContent}
      </WorkflowCardExpandableContainer>
    )
  }

  private handleContentClick = e => {
    const { eventsStore } = this.props
    if (e.target?.tagName?.toLowerCase() === 'img') {
      e.stopPropagation()
      const index = this.contentImages.findIndex(
        im => im.fileUrl === e.target.getAttribute('src'),
      )

      eventsStore.dispatch(SHOW_FULLSCREEN_PREVIEW, this.contentImages, index)
    }
  }

  @computed
  public get contentImages(): IFilePreviewProperties[] {
    const div = document.createElement('div')
    div.innerHTML = this.props.value.content
    const images = Array.from(div.getElementsByTagName('img')).map(im => ({
      fileType: FileType.Image,
      fileUrl: im.getAttribute('src'),
      fileName: im.getAttribute('alt'),
    }))

    div.remove()
    return images
  }

  @computed
  private get announcementContent(): JSX.Element | JSX.Element[] {
    const options: HTMLReactParserOptions = {
      replace: (element: DomElement) => {
        if (element.attribs?.class === IMAGE_GALLERY_BLOT_NAME) {
          delete element.attribs.contenteditable
          return element
        }

        if (element.attribs?.class === VIDEO_BLOT_NAME) {
          const src = element.attribs['data-video-src']
          return (
            <div data-video-src={src} className={VIDEO_BLOT_NAME}>
              <VideoPlayer src={src} />
            </div>
          )
        }
      },
    }

    return htmlReactParser(this.props.value.content, options)
  }
}
