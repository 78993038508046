export const START_ALL = 'start-all'
export const INIT_AUTH_USER = 'init-auth-user'
export const INIT_APP = 'init-app'
export const INIT_APP_2 = 'init-app-2'
export const INIT_APP_SELECT_PROJECT = 'init-app-select-project'
export const INIT_APP_3 = 'init-app-3'
export const INIT_COMPLETE = 'init-complete'
export const INIT_ERROR = 'init-error'
export const RUN_CALLBACK = 'run-callback'
export const REQUEST_ERROR = 'request-error'
export const REPORT_ERROR = 'report-error'
export const COMPLETE_REQUEST = 'complete-request'
export const COMPLETE_REQUEST_AND_RUN_CALLBACK =
  'complete-request-and-run-callback'
export const COMPLETE_REQUEST_AND_DISPATCH = 'complete-request-and-dispatch'
export const COLLECT_TELEMETRY = 'collect-telemetry'

export const GET_CURRENT_USER = 'get-current-user'
export const GET_CURRENT_USER_SUCCESS = 'get-current-user-success'

export const SEND_WELCOME_EMAIL_TO_CURRENT_USER =
  'send-welcome-email-to-current-user'

export const GO_TO_AVAILABLE_PROJECT = 'go-to-available-project'
export const ACTIVATE_PROJECT = 'activate-project'
export const ACTIVATE_PROJECT_SUCCESS = 'activate-project-success'
export const CHART_LOADED_AND_INITIALIZED = 'chart-loaded-and-initialized'
export const DISPLAY_PROJECT_PICKER = 'display-project-picker'
export const UPDATE_USER_PREFERENCES = 'update-user-preferences'
export const HANDLE_SAME_PROJECT_SELECTION = 'handle-same-project-selection'

export const DISPLAY_SAVE_PASSWORD = 'display-save-password'
export const SAVE_PASSWORD_SUCCESS = 'save-password-success'

export const GET_LOGISTICS_CONFIG_AND_OPEN_DEFAULT_PAGE =
  'get-logistics-config-and-open-default-page'
export const DISPLAY_DEFAULT_MOBILE_PAGE = 'display-default-mobile-page'

// USERS
export const SAVE_MEMBERS = 'save-members' // save user models + project settings
export const UPLOAD_PROJECT_MEMBERS = 'upload-project-members'
export const DOWNLOAD_PROJECT_MEMBERS = 'download-project-members'

export const CHANGE_PASSWORD = 'change-password'
export const CHANGE_PASSWORD_RESULT = 'change-password-result'

export const GET_PROJECT_MEMBERS = 'get-project-members'
export const GET_PROJECT_MEMBERS_SUCCESS = 'get-project-members-success'

export const GET_USERS_FROM_PROCORE = 'get-users-from-procore'

export const INVITE_PROJECT_MEMBERS = 'invite-project-members'
export const SET_PROJECT_MEMBERS_AS_NOT_INVITED =
  'set-project-members-as-not-invited'

// NOTIFICATIONS

export const LOAD_NOTIFICATIONS = 'load-notifications'

export const LOAD_AND_LISTEN_TO_NOTIFICATIONS_COUNT =
  'load-and-listen-to-notifications-count'
export const LISTEN_TO_NOTIFICATIONS_COUNT = 'listen-to-notifications-count'
export const NOTIFICATIONS_COUNT_RECEIVED = 'notifications-count-received'
export const NOTIFICATION_COUNT_UPDATED = 'notification-count-updated'

export const SEND_DESKTOP_NOTIFICATIONS = 'send-desktop-notifications'

export const VIEW_NOTIFICATION = 'view-notification'
export const MARK_NOTIFICATION_AS_READ = 'mark-notification-as-read'
export const MARK_NOTIFICATIONS_AS_READ = 'mark-notifications-as-read'
export const MARK_NOTIFICATIONS_AS_ARCHIVED = 'mark-notifications-as-archived'

// LOGIN LOGOUT SECTION
export const LOGIN_WITH_EMAIL_AND_PASSWORD = 'login-with-email-and-password'
export const LOGIN_WITH_PHONE_NUMBER = 'login-with-phone-number'
export const LOGIN_WITH_INVITE_KEY = 'login-with-invite-key'
export const LOGIN_WITH_INVITE_KEY_ERROR = 'login-with-invite-key-error'
export const LOGIN_PROCORE = 'login-procore'
export const LOGIN_PROCORE_SUCCESS = 'login-procore-success'

export const LOGOUT = 'logout'
export const LOGOUT_SUCCESS = 'logout-success'

export const LOAD_PROJECT_STATUSES = 'load-project-statuses'
export const GET_PROJECT_STATUS = 'get-project-status'
export const CREATE_DELIVERY = 'create-delivery'
export const CREATE_RECURRING_DELIVERIES = 'create-recurring-deliveries'
export const DELETE_DELIVERIES = 'delete-deliveries'
export const NOTIFY_THREAD_REPLIED = 'notify-thread-replied'
export const REGISTER_SERVICE_WORKER = 'register-service-worker'

export const UPDATE_DELIVERY = 'update-delivery'
export const UPDATE_RECURRING_DELIVERIES = 'update-recurring-deliveries'
export const UPDATE_DELIVERY_MATERIALS = 'update-delivery-materials'

export const GET_DELIVERY_TICKET = 'get-delivery-ticket'
export const GET_DELIVERY_TICKET_RESULT = 'get-delivery-ticket-result'
export const SEND_SMS_DELIVERY_TICKET = 'send-sms-delivery-ticket'
export const SEND_SMS_DELIVERY_TICKET_SUCCESS =
  'send-sms-delivery-ticket-success'

export const NO_EFFECT = 'no-effect'

// DELIVERY ATTRs
export const LOAD_AND_LISTEN_TO_ALL_DELIVERY_ATTRIBUTES =
  'load-and-listen-to-all-delivery-attributes'

export const LOAD_DELIVERY_ATTRIBUTES = 'load-to-delivery-attributes'

export const SAVE_DELIVERY_ATTRIBUTES = 'save-delivery-attributes'
export const DELETE_DELIVERY_ATTRIBUTES = 'delete-delivery-attributes'

// DELIVERY SITEMAP SECTION
// BASEMAPS
export const LOAD_AND_LISTEN_TO_BASEMAPS = 'load-and-listen-to-basemaps'
export const BASEMAPS_RECIEVED = 'basemaps-recieved'
export const BASEMAP_UPDATED = 'basemap-updated'
export const SAVE_BASEMAP = 'save-basemap'
export const SAVE_BASEMAP_SUCCESS = 'save-basemap-success'

// SITEMAPS
export const LOAD_AND_LISTEN_TO_SITEMAPS = 'load-and-listen-to-sitemaps'
export const SITEMAPS_RECEIVED = 'sitemaps-received'
export const SITEMAP_UPDATED = 'sitemap-updated'
export const SAVE_SITEMAP = 'save-sitemap'
export const SAVE_SITEMAP_SUCCESS = 'save-sitemap-success'
export const DELETE_SITEMAP = 'delete-sitemap'
export const DELETE_SITEMAP_SUCCESS = 'delete-sitemap-success'

// SITEMAP ITEM DATA
export const LISTEN_TO_SITEMAP_ITEMS_DATA = 'listen_to_sitemap_items_data'
export const SITEMAP_ITEM_DATA_UPDATED = 'sitemap_item_data_updated'
export const SAVE_SITEMAP_ITEM_DATA = 'save-sitemap-item-data'
export const SAVE_SITEMAP_ITEM_DATA_SUCCESS = 'save-sitemap-item-data-success'

// GLOBE_VIEW
export const LOAD_AND_LISTEN_TO_GLOBE_VIEWS = 'load-and-listen-to-globe-views'
export const GLOBE_VIEWS_RECEIVED = 'globe-views-recieved'
export const GLOBE_VIEW_UPDATED = 'globe-view-updated'
export const SAVE_GLOBE_VIEW = 'save-globe-view'
export const SAVE_GLOBE_VIEW_SUCCESS = 'save-globe-view-success'
export const SAVE_GLOBE_VIEW_STYLE = 'save-globe-view-style'
export const SAVE_GLOBE_VIEW_STYLE_SUCCESS = 'save-globe-view-style-success'
export const DELETE_GLOBE_VIEW = 'delete-globe-view'
export const DELETE_GLOBE_VIEW_SUCCESS = 'delete-globe-view-success'

// GLOBE VIEW ITEM DATA
export const LISTEN_TO_GLOBE_VIEW_ITEMS_DATA = 'listen_to_globe_view_items_data'
export const GLOBE_VIEW_ITEM_DATA_UPDATED = 'globe_view_item_data_updated'
export const SAVE_GLOBE_VIEW_ITEM_DATA = 'save-globe-view-item-data'
export const SAVE_GLOBE_VIEW_ITEM_DATA_SUCCESS =
  'save-globe-view-item-data-success'
export const DELETE_GLOBE_VIEW_ITEM_DATA = 'delete-globe-view-item-data'
export const DELETE_GLOBE_VIEW_ITEM_DATA_SUCCESS =
  'delete-globe-view-item-data-success'

// SITEMAP ITEMS
export const LOAD_AND_LISTEN_TO_SITEMAP_ITEMS =
  'load-and-listen-to-sitemap-items'
export const SITEMAP_ITEMS_RECIEVED = 'sitemap-items-recieved'
export const SITEMAP_ITEM_UPDATED = 'sitemap-item-updated'
export const SAVE_SITEMAP_ITEM = 'save-sitemap-item'
export const SAVE_SITEMAP_ITEM_SUCCESS = 'save-sitemap-item-success'
export const DELETE_SITEMAP_ITEMS = 'delete-sitemap-items'

export const LISTEN_AND_LOAD_DELIVERIES = 'listen-and-load-deliveries'
export const LOAD_DELIVERIES = 'load-deliveries'
export const LOAD_DELIVERIES_SUCCESS = 'load-deliveries-success'
export const DELIVERY_RECEIVED = 'delivery-received'

export const LOAD_LOCATIONS_AND_LISTEN_CHANGES =
  'load-locations-and-listen-changes'
export const LOAD_LOCATIONS_SUCCESS = 'load-locations-success'
export const LOCATION_RECEIVED = 'location-received'
export const SAVE_LOCATION = 'save-location'

export const LOAD_CLOSURES_AND_LISTEN_CHANGES =
  'load-closures-and-listen-changes'
export const LISTEN_TO_CLOSURE_ASSIGNMENTS = 'listen-to-closure-assignments'
export const CLOSURE_ASSIGNMENT_RECEIVED = 'closure-assignment-received'
export const LOAD_CLOSURES_SUCCESS = 'load-closures-success'
export const LISTEN_TO_CLOSURES = 'listen-to-closures'
export const CLOSURE_RECEIVED = 'closure-received'

export const LOAD_CLOSURE_ASSIGNMENTS_AND_LISTEN_CHANGES =
  'load-closure-assignments-and-listen-changes'
export const LOAD_CLOSURE_ASSIGNMENTS_SUCCESS =
  'load-closure-assignments-success'

export const LOAD_CLOSURE_FOLLOWINGS_AND_LISTEN_CHANGES =
  'load-closure-followings-and-listen-changes'
export const LOAD_CLOSURE_FOLLOWINGS_SUCCESS = 'load-closure-followings-success'
export const SAVE_CLOSURE_FOLLOWINGS = 'save-closure-followings'
export const DELETE_CLOSURE_FOLLOWINGS = 'delete-closure-followings'

export const LOAD_ANNOUNCEMENT_ASSIGNMENTS_AND_LISTEN_CHANGES =
  'load-announcement-assignments-and-listen-changes'
export const LOAD_ANNOUNCEMENT_ASSIGNMENTS_SUCCESS =
  'load-announcement-assignments-success'
export const LISTEN_TO_CLOSURE_FOLLOWING = 'listen-to-closure-following'
export const CLOSURE_FOLLOWING_UPDATED = 'closure-following-updated'

export const LOAD_ANNOUNCEMENTS_AND_LISTEN_CHANGES =
  'load-announcements-and-listen-changes'
export const LISTEN_TO_ANNOUNCEMENT_ASSIGNMENTS =
  'listen-to-announcement-assignments'
export const ANNOUNCEMENT_ASSIGNMENT_RECEIVED =
  'announcement-assignment-received'
export const LOAD_ANNOUNCEMENTS_SUCCESS = 'load-announcements-success'
export const LISTEN_TO_ANNOUNCEMENTS = 'listen-to-announcements'
export const ANNOUNCEMENT_RECEIVED = 'announcement-received'
export const SAVE_ANNOUNCEMENTS = 'save-announcements'
export const DELETE_ANNOUNCEMENTS = 'delete-announcements'

export const GET_PROJECT_CONFIG = 'get-project-config'
export const PROJECT_CONFIG_RECEIVED = 'project-config-received'

export const UPDATE_ACTIVITY_FILTERS_SETTINGS =
  'update-activity-filters-settings'
export const UPDATE_ACTIVITY_FILTERS_SETTINGS_SUCCESS =
  'update-activity-filters-settings-success'
export const GET_ACTIVITY_FILTERS_SETTINGS = 'get-activity-filters-settings'
export const GET_ACTIVITY_FILTERS_SETTINGS_SUCCESS =
  'get-activity-filters-settings-success'

export const REDIRECT_TO_PROPER_SITE_VERSION = 'redirect-to-proper-site-version'

export const REQUEST_RESET_PASSWORD = 'request-reset-password'
export const VERIFY_PASSWORD_RESET_CODE = 'verify_password_reset_code'
export const VERIFY_PASSWORD_RESET_CODE_SUCCESS =
  'verify_password_reset_code_success'
export const VERIFY_PASSWORD_RESET_CODE_ERROR =
  'verify_password_reset_code_error'

export const CONFIRM_RESET_PASSWORD = 'confirm-reset-password'
export const CONFIRM_RESET_PASSWORD_SUCCESS = 'confirm-reset-password-success'
export const RESET_PASSWORD_ERROR = 'reset-password-error'

// PRESENTATION MODE
export const GET_PRESENTATION_USER_TOKEN = 'get-presentation-user-token'
export const GET_PRESENTATION_USER_TOKEN_SUCCESS =
  'get-presentation-user-token-success'

export const LOAD_AND_LISTEN_TO_PRESENTATION_SETTINGS =
  'load-and-listen-to-presentation-settings'
export const PRESENTATION_SETTINGS_RECEIVED = 'presentation-settings-received'
export const PRESENTATION_SETTINGS_UPDATED = 'presentation-settings-updated'
export const LISTEN_TO_PRESENTATION_SETTINGS = 'listen-to-presentation-settings'
export const SAVE_PRESENTATION_SETTINGS = 'save-presentation-settings'

export const START_PRESENTATION = 'start-presentation'
export const PLAY_PRESENTATION = 'play-presentation'

export const SHOW_FULLSCREEN_PREVIEW = 'show-fullscreen-preview'

export const SAVE_ACTIVITY_PRESET = 'save-activity-preset'
export const DELETE_ACTIVITY_PRESET = 'delete-activity-preset'
export const LOAD_AND_LISTEN_TO_ACTIVITY_PRESETS =
  'load-and-listen-to-activity-presets'
export const ACTIVITY_PRESETS_RECEIVED = 'activity-presets-received'
export const ACTIVITY_PRESET_UPDATED = 'activity-preset-updated'

export const RESET_ALL_FILTERS = 'reset-all-filters'
export const RESET_FILTER = 'reset-filter'
export const SET_FILTER_SELECTION = 'set-filter-selection'
export const RESET_INPUT_STATUSES = 'reset-input-statuses'

// ********* SCHEDULE STUFF

// Schedule
export const GET_SCHEDULE = 'get-schedule'
export const GET_SCHEDULE_SUCCESS = 'get-schedule-success'
export const GET_SCHEDULE_ERROR = 'get-schedule-error'
export const UPLOAD_SCHEDULE = 'upload-schedule'
export const UPLOAD_EXCEL_SCHEDULE = 'upload-excel-schedule'
export const SCHEDULE_LOADED = 'schedule-loaded'
export const RESTORE_SCHEDULE = 'restore-schedule'
export const SET_SCHEDULE_DATA = 'set-schedule-data'

export const LOAD_SCHEDULE_ENTITIES = 'load-schedule-entities'

export const ACTIVITY_CODE_TYPES_RECEIVED = 'activity-code-types-received'
export const LISTEN_TO_ACTIVITY_CODE_TYPE = 'listen-to-activity-code-type'
export const ACTIVITY_CODE_TYPE_UPDATED = 'activity-code-type-updated'

export const ACTIVITY_CODES_RECEIVED = 'activity-codes-received'
export const LISTEN_TO_ACTIVITY_CODE = 'listen-to-activity-code'
export const ACTIVITY_CODE_UPDATED = 'activity-code-updated'

// Analytics Setting

export const LOAD_ANALYTICS_SETTING = 'load-analytics-setting'
export const ANALYTICS_SETTING_RECEIVED = 'analytics-setting-received'
export const SAVE_ANALYTICS_SETTING = 'save-analytics-setting'
export const SAVE_ANALYTICS_SETTING_SUCCESS = 'save-analytics-setting-success'

// SCANNERS
export const LOAD_AND_LISTEN_TO_SCANNERS = 'load-and-listen-to-scanners'
export const LOAD_SCANNERS = 'load-scanners'
export const SCANNERS_RECEIVED = 'scanners-received'
export const LISTEN_TO_SCANNERS = 'listen-to-scanners'
export const SCANNER_UPDATED = 'scanner-updated'
export const SAVE_SCANNERS_SUCCESS = 'save-scanners-success'
export const SAVE_SCANNERS = 'save-scanners'
export const DELETE_SCANNERS = 'delete-scanners'
export const DELETE_SCANNER = 'delete-scanner'

// SCAN HISTORIES
export const LOAD_AND_LISTEN_TO_SCAN_HISTORIES =
  'load-and-listen-to-scan-histories'
export const LOAD_SCAN_HISTORIES = 'load-scan-histories'
export const SCAN_HISTORIES_RECEIVED = 'scan-histories-received'
export const LISTEN_TO_SCAN_HISTORIES = 'listen-to-scan-histories'
export const SCAN_HISTORY_UPDATED = 'scan-history-updated'
export const SAVE_SCAN_HISTORIES_SUCCESS = 'save-scan-histories-success'
export const SAVE_SCAN_HISTORIES = 'save-scan-histories'

export const CALENDARS_RECEIVED = 'calendars-received'

export const RESOURCES_RECEIVED = 'resources-received'
export const LISTEN_TO_RESOURCE = 'listen-to-resource'
export const RESOURCE_UPDATED = 'resource-updated'

export const ACTIVITY_RESOURCE_RELATIONSHIPS_RECEIVED =
  'activity-resource-relationships-received'
export const LISTEN_TO_ACTIVITY_RESOURCE_RELATIONSHIP =
  'listen-to-activity-resource-relationship'
export const ACTIVITY_RESOURCE_RELATIONSHIP_UPDATED =
  'activity-resource-relationship-updated'

// Activity
export const ACTIVITIES_RECEIVED = 'activities-received'
export const LISTEN_TO_ACTIVITY = 'listen-to-activity'
export const ACTIVITY_UPDATED = 'activity-updated'
export const SAVE_ACTIVITY = 'save-activity'
export const DELETE_ACTIVITIES = 'delete-activities'
export const SHOW_MOVED_TO_CURRENT_SECTION_TOOLTIP =
  'show-moved-to-current-section-tooltip'

// Activity code relationship
export const ACTIVITY_CODE_RELATIONSHIPS_RECEIVED =
  'activity-code-relationships-received'
export const LISTEN_TO_ACTIVITY_CODE_RELATIONSHIP =
  'listen-to-activity-code-relationship'
export const ACTIVITY_CODE_RELATIONSHIP_UPDATED =
  'activity-code-relationship-updated'

// Status updates
export const LOAD_AND_LISTEN_TO_STATUS_UPDATES =
  'load-and-listen-to-status-updates'
export const STATUS_UPDATES_RECEIVED = 'status-updates-received'
export const LISTEN_TO_STATUS_UPDATES = 'listen-to-status-updates'
export const STATUS_UPDATES_UPDATED = 'status-updates-updated'
export const SAVE_STATUS_UPDATE_AND_SEND_STAT =
  'save-status-update-and-send-stat'
export const SAVE_STATUS_UPDATE = 'save-status-update'
export const DELETE_STATUS_UPDATE_FOR_DATE = 'delete-status-updates-for-date'

// *********

export const LISTEN_TO_DEPLOYMENT_VERSION = 'get-deployment-version'
export const DEPLOYMENT_VERSION_RECEIVED = 'deployment-version-received'
export const LISTEN_TO_DEPLOYMENT_VERSION_ERROR = 'get-deployment-version-error'

export const SEND_MESSAGE_TO_THREAD = 'send-message-to-thread'

export const GET_HIERARCHY_CONFIGURATION = 'get-hierarchy-configuration'
export const GET_HIERARCHY_CONFIGURATION_SUCCESS =
  'get-hierarchy-configuration-success'
export const UPDATE_HIERARCHY_CONFIGURATION = 'update-hierarchy-configuration'
export const UPDATE_HIERARCHY_CONFIGURATION_SUCCESS =
  'update-hierarchy-configuration-success'

export const CALCULATE_ACTIVITY_ACTUAL_DATES = 'calculate-activity-actual-days'
export const CALCULATE_ACTIVITY_ACTUAL_DATES_SUCCESS =
  'calculate-activity-actual-days-success'
export const CALCULATE_ACTIVITY_TREE = 'calculate-activity-tree'
export const CALCULATE_ACTIVITY_TREE_SUCCESS = 'calculate-activity-tree-success'

export const CALCULATE_DELIVERY_TREE = 'calculate-delivery-tree'
export const CALCULATE_DELIVERY_TREE_SUCCESS = 'calculate-delivery-tree-success'

export const UPDATE_BULK_DELIVERIES_STATUS = 'update-bulk-deliveries-status'
export const UPDATE_BULK_DELIVERIES_STATUS_RESULT =
  'update-bulk-deliveries-status-update-result'

export const EDIT_BULK_DELIVERIES = 'edit-bulk-deliveries'
export const EDIT_BULK_DELIVERIES_RESULT = 'edit-bulk-deliveries-result'

export const LISTEN_TO_USERS = 'listen-to-users'
export const USER_RECEIVED = 'user-received'

export const SAVE_SITEMAP_IMAGE = 'save-delivery-sitemap-image'
export const SAVE_GLOBE_IMAGE = 'save-globe-image'

export const TRY_UNAUTHORIZED_MODE = 'try-unauthorized-mode'
export const TRY_UNAUTHORIZED_MODE_RESULT = 'try-unauthorized-mode-result'

export const TRIGGER_CALCULATE_ACTIVITY_TREE = 'trigger-calculate-activity-tree'
export const TRIGGER_CALCULATE_DELIVERY_TREE = 'trigger-calculate-delivery-tree'

export const DETECT_USER_LOCATION_INFO = 'detect-user-location-info'

// Materials Upload
export const MATERIALS_UPLOADED = 'materials-uploaded'
export const RESET_MATERIALS_FILTERS = 'reset-materials-filters'

// Project Material
export const MATERIAL_UPDATED = 'material-updated'
export const LISTEN_TO_MATERIAL = 'listen-to-material'
export const MATERIALS_RECEIVED = 'materials-received'
export const LOAD_AND_LISTEN_TO_MATERIALS = 'load-and-listen-to-materials'
export const SAVE_MATERIAL = 'save-material'

// Project Material Category
export const MATERIAL_CATEGORY_UPDATED = 'material-category-updated'
export const LISTEN_TO_MATERIAL_CATEGORY = 'listen-to-material-category'
export const MATERIAL_CATEGORIES_RECEIVED = 'material-categories-received'
export const LOAD_AND_LISTEN_TO_MATERIAL_CATEGORIES =
  'load-and-listen-to-material-categories'

// Reports
export const DOWNLOAD_REPORT_FILE = 'download-report-file'
export const DOWNLOAD_REPORT_SUCCESS = 'download-report-success'
export const DOWNLOAD_REPORT_ERROR = 'download-report-error'

export const LOAD_REPORT_TEMPLATE = 'load-report-template'
export const REPORT_TEMPLATE_RECEIVED = 'report-template-received'

// OPERATION RULES
export const LOAD_AND_LISTEN_TO_OPERATION_RULES =
  'load-and-listen-to-operation-rules'
export const OPERATION_RULES_RECEIVED = 'operation-rules-received'
export const LISTEN_TO_OPERATION_RULES = 'listen-to-operation-rules'
export const OPERATION_RULES_UPDATED = 'operation-rules-updated'
export const SAVE_MANY_OPERATION_RULES = 'save-many-operation-rules'
export const SAVE_MANY_OPERATION_RULES_SUCCESS =
  'save-many-operation-rules-success'
export const DELETE_MANY_OPERATION_RULES = 'delete-many-operation-rules'
export const DELETE_MANY_OPERATION_RULES_SUCCESS =
  'delete-many-operation-rules-success'

// TEAMS
export const LOAD_AND_LISTEN_TO_PROJECT_TEAMS =
  'load-and-listen-to-project-teams'
export const LISTEN_TO_PROJECT_TEAMS = 'listen-to-project-teams'
export const PROJECT_TEAMS_RECEIVED = 'project-teams-received'
export const PROJECT_TEAMS_UPDATED = 'project-teams-updated'
export const SAVE_PROJECT_TEAM = 'save-project-team'
export const SAVE_PROJECT_TEAM_SUCCESS = 'save-project-team-success'
export const DELETE_PROJECT_TEAM = 'delete-project-team'
export const DELETE_PROJECT_TEAM_SUCCESS = 'delete-project-team-success'

// TRADES
export const LOAD_AND_LISTEN_TO_PROJECT_TRADES =
  'load-and-listen-to-project-trades'
export const LISTEN_TO_PROJECT_TRADES = 'listen-to-project-trades'
export const PROJECT_TRADES_RECEIVED = 'project-trades-received'
export const PROJECT_TRADE_UPDATED = 'project-trade-updated'
export const SAVE_PROJECT_TRADE = 'save-project-trade'
export const SAVE_PROJECT_TRADE_SUCCESS = 'save-project-trade-success'
export const DELETE_PROJECT_TRADE = 'delete-project-trade'
export const DELETE_PROJECT_TRADE_SUCCESS = 'delete-project-trade-success'

// Default teams
export const GET_DEFAULT_TEAMS = 'get-default-teams'
export const DEFAULT_TEAMS_RECEIVED = 'default-teams-received'

// ROLES
export const LOAD_AND_LISTEN_TO_PROJECT_ROLES =
  'load-and-listen-to-project-roles'
export const LISTEN_TO_PROJECT_ROLES = 'listen-to-project-roles'
export const PROJECT_ROLES_RECEIVED = 'project-roles-received'
export const PROJECT_ROLES_UPDATED = 'project-roles-updated'
export const SAVE_PROJECT_ROLE = 'save-project-role'
export const SAVE_PROJECT_ROLE_SUCCESS = 'save-project-role-success'
export const DELETE_PROJECT_ROLE = 'delete-project-role'
export const DELETE_PROJECT_ROLE_SUCCESS = 'delete-project-role-success'

// DELIVERY SAVED FILTERS
export const GET_DELIVERY_CUSTOM_FILTERS = 'get-delivery-custom-filters'
export const GET_DELIVERY_CUSTOM_FILTERS_SUCCESS =
  'get-delivery-custom-filters-success'

// WORKFLOW SAVED FILTERS
export const GET_WORKFLOW_CUSTOM_FILTERS = 'get-workflow-custom-filters'
export const GET_WORKFLOW_CUSTOM_FILTERS_SUCCESS =
  'get-workflow-custom-filters-success'

// ACTIVITY SAVED FILTERS
export const GET_ACTIVITY_CUSTOM_FILTERS = 'get-activity-custom-filter'
export const GET_ACTIVITY_CUSTOM_FILTERS_SUCCESS =
  'get-activity-custom-list-filter-success'
export const UPDATE_USER_LAST_ACTIVITY_LIST_MOBILE_FILTERS =
  'update-user-last-activity-list-mobile-filters'

// ACTIVITIES FOLLOWINGS
export const LOAD_AND_LISTEN_TO_ACTIVITY_FOLLOWINGS =
  'load-and-listen-to-activity-followings'
export const ACTIVITY_FOLLOWINGS_RECEIVED = 'activity-followings-received'
export const LISTEN_TO_ACTIVITY_FOLLOWING = 'listen-to-activity-following'
export const ACTIVITY_FOLLOWING_UPDATED = 'activity-following-updated'
export const SAVE_ACTIVITY_FOLLOWINGS = 'save-activity-followings'
export const DELETE_ACTIVITY_FOLLOWINGS = 'delete-activity-followings'

// DELIVERIES FOLLOWINGS
export const LOAD_AND_LISTEN_TO_DELIVERIES_FOLLOWINGS =
  'load-and-listen-to-deliveries-followings'
export const DELIVERY_FOLLOWINGS_RECEIVED = 'delivery-followings-received'
export const LISTEN_TO_DELIVERY_FOLLOWING = 'listen-to-delivery-following'
export const DELIVERY_FOLLOWING_UPDATED = 'delivery-following-updated'
export const SAVE_DELIVERY_FOLLOWINGS = 'save-delivery-followings'
export const DELETE_DELIVERY_FOLLOWINGS = 'delete-delivery-followings'

// SITE_PERMITS FOLLOWINGS
export const LOAD_AND_LISTEN_TO_SITE_PERMITS_FOLLOWINGS =
  'load-and-listen-to-site-permits-followings'
export const SITE_PERMIT_FOLLOWINGS_RECEIVED = 'site-permit-followings-received'
export const LISTEN_TO_SITE_PERMIT_FOLLOWING = 'listen-to-site-permit-following'
export const SITE_PERMIT_FOLLOWING_UPDATED = 'site-permit-following-updated'
export const SAVE_SITE_PERMIT_FOLLOWINGS = 'save-site-permit-followings'
export const DELETE_SITE_PERMIT_FOLLOWINGS = 'delete-site-permit-followings'

// ANNOUNCEMENT FOLLOWINGS
export const LOAD_AND_LISTEN_TO_ANNOUNCEMENTS_FOLLOWINGS =
  'load-and-listen-to-announcements-followings'
export const ANNOUNCEMENT_FOLLOWINGS_RECEIVED =
  'announcements-followings-received'
export const LISTEN_TO_ANNOUNCEMENT_FOLLOWING =
  'listen-to-announcement-following'
export const ANNOUNCEMENT_FOLLOWING_UPDATED = 'announcement-following-updated'
export const SAVE_ANNOUNCEMENT_FOLLOWINGS = 'save-announcement-followings'
export const DELETE_ANNOUNCEMENT_FOLLOWINGS = 'delete-announcement-followings'

// CASTS FOLLOWINGS
export const LOAD_AND_LISTEN_TO_CASTS_FOLLOWINGS =
  'load-and-listen-to-casts-followings'
export const CAST_FOLLOWINGS_RECEIVED = 'cast-followings-received'
export const SAVE_CAST_FOLLOWINGS = 'save-cast-followings'
export const DELETE_CAST_FOLLOWINGS = 'delete-cast-followings'

// COMMON ASSIGNMENTS
export const SAVE_ASSIGNMENTS = 'save-assignments'
export const SAVE_ASSIGNMENTS_SUCCESS = 'save-assignments-success'
export const DELETE_ASSIGNMENTS = 'delete-assignments'
export const DELETE_ASSIGNMENTS_SUCCESS = 'delete-assignments-success'

// DELIVERIES ASSIGNMENTS
export const LOAD_AND_LISTEN_TO_DELIVERY_ASSIGNMENTS =
  'load-and-listen-to-delivery-assignments'
export const DELIVERY_ASSIGNMENTS_RECEIVED = 'delivery-assignments-received'
export const LISTEN_TO_DELIVERY_ASSIGNMENTS = 'listen-to-delivery-assignments'
export const DELIVERY_ASSIGNMENTS_UPDATED = 'delivery-assignments-updated'

// ACTIVITY ASSIGNMENTS
export const LOAD_AND_LISTEN_TO_ACTIVITY_ASSIGNMENTS =
  'load-and-listen-to-activity-assignments'
export const ACTIVITY_ASSIGNMENTS_RECEIVED = 'activity-assignments-received'
export const LISTEN_TO_ACTIVITY_ASSIGNMENTS = 'listen-to-activity-assignments'
export const ACTIVITY_ASSIGNMENTS_UPDATED = 'activity-assignments-updated'
export const SAVE_ACTIVITY_ASSIGNMENTS = 'save-activity-assignments'

// CAST ASSIGNMENTS
export const LOAD_CAST_ASSIGNMENTS = 'load-cast-assignments'
export const CAST_ASSIGNMENTS_RECEIVED = 'cast-assignments-received'

// SITE_PERMIT ASSIGNMENTS
export const LOAD_AND_LISTEN_TO_SITE_PERMIT_ASSIGNMENTS =
  'load-and-listen-to-site-permit-assignments'
export const SITE_PERMIT_ASSIGNMENTS_RECEIVED =
  'site-permit-assignments-received'
export const LISTEN_TO_SITE_PERMIT_ASSIGNMENTS =
  'listen-to-site-permit-assignments'
export const SITE_PERMIT_ASSIGNMENTS_UPDATED = 'site-permit-assignments-updated'

// ACTIVITY CODE LOCATION RELATIONSHIP
export const LOAD_AND_LISTEN_TO_ACTIVITY_CODE_LOCATION_RELATIONSHIPS =
  'load-and-listen-to-activity-code-location-relationships'
export const ACTIVITY_CODE_LOCATION_RELATIONSHIPS_RECEIVED =
  'activity-code-location-relationships-received'
export const LISTEN_TO_ACTIVITY_CODE_LOCATION_RELATIONSHIPS =
  'listen-to-activity-code-location-relationships'
export const ACTIVITY_CODE_LOCATION_RELATIONSHIPS_UPDATED =
  'activity-code-location-relationships-updated'
export const SAVE_ACTIVITY_CODE_LOCATION_RELATIONSHIP =
  'save-activity-code-location-relationship'
export const DELETE_ACTIVITY_CODE_LOCATION_RELATIONSHIP =
  'delete-activity-code-location-relationship'

// ACTIVITY COMPANY RELATIONSHIP
export const LOAD_AND_LISTEN_TO_ACTIVITY_COMPANY_RELATIONSHIPS =
  'load-and-listen-to-activity-company-relationships'
export const ACTIVITY_COMPANY_RELATIONSHIPS_RECEIVED =
  'activity-company-relationships-received'
export const LISTEN_TO_ACTIVITY_COMPANY_RELATIONSHIPS =
  'listen-to-activity-company-relationships'
export const ACTIVITY_COMPANY_RELATIONSHIPS_UPDATED =
  'activity-company-relationships-updated'
export const SAVE_ACTIVITY_COMPANY_RELATIONSHIP =
  'save-activity-company-relationship'
export const DELETE_ACTIVITY_COMPANY_RELATIONSHIP =
  'delete-activity-company-relationship'

// Activity threads
export const THREAD_UPDATED = 'thread-updated'
export const LISTEN_TO_THREAD = 'listen-to-thread'
export const THREADS_RECEIVED = 'threads-received'
export const LOAD_AND_LISTEN_TO_THREADS = 'load-and-listen-to-threads'
export const SAVE_THREAD = 'save-thread'

// COMPANIES
export const LOAD_AND_LISTEN_TO_COMPANIES = 'load-and-listen-to-companies'
export const LOAD_AND_LISTEN_TO_COMPANY_TYPE_TAGS =
  'load-and-listen-to-company-type-tags'
export const COMPANY_TYPE_TAGS_RECEIVED = 'company-type-tags-received'
export const COMPANY_TYPE_TAGS_UPDATED = 'company-type-tags-updated'
export const LISTEN_TO_COMPANY_TYPE_TAGS = 'listen-to-company-type-tags'
export const LISTEN_TO_COMPANIES = 'listen-to-companies'
export const COMPANIES_RECEIVED = 'companies-received'
export const COMPANIES_UPDATED = 'companies-updated'
export const SAVE_COMPANIES_SUCCESS = 'save-companies-success'
export const SAVE_COMPANIES = 'save-companies'
export const DELETE_COMPANIES = 'delete-companies'
export const MERGE_COMPANIES = 'merge-companies'
export const LINK_STRUXHUB_COMPANY = 'link-struxhub-company'
export const ACTIVATE_COMPANIES = 'activate-companies'

// Messages
export const LOAD_AND_LISTEN_TO_MESSAGES = 'load-and-listen-to-messages'
export const MESSAGES_RECEIVED = 'messages-received'
export const MESSAGE_UPDATED = 'message-updated'
export const LISTEN_TO_MESSAGES = 'listen-to-messages'
export const SAVE_MESSAGE = 'save-message'

// Photos
export const LOAD_AND_LISTEN_TO_PHOTOS = 'load-and-listen-to-photos'
export const PHOTOS_RECEIVED = 'photos-received'
export const PHOTO_UPDATED = 'photo-updated'
export const LISTEN_TO_PHOTOS = 'listen-to-photos'
export const SAVE_PHOTO = 'save-photo'

// Content objects
export const LOAD_AND_LISTEN_TO_CONTENT_OBJECTS =
  'load-and-listen-to-content-objects'
export const CONTENT_OBJECTS_RECEIVED = 'content-objects-received'
export const CONTENT_OBJECT_UPDATED = 'content-object-updated'
export const LISTEN_TO_CONTENT_OBJECTS = 'listen-to-content-objects'
export const SAVE_CONTENT_OBJECTS = 'save-content-objects'

// Default permit types
export const GET_DEFAULT_PERMIT_TYPES = 'get-default-permit-types'
export const DEFAULT_PERMIT_TYPES_RECEIVED = 'default-permit-types-received'

// Permit Types
export const LISTEN_TO_PERMIT_TYPES = 'listen-to-permit-types'
export const PERMIT_TYPES_LOAD_SUCCESS = 'permit-types-load-success'
export const LOAD_AND_LISTEN_TO_PERMIT_TYPES = 'load-and-listen-to-permit-types'
export const PERMIT_TYPES_RECEIVED = 'permit-types-received'
export const SAVE_PERMIT_TYPES = 'save-permit-types'
export const SAVE_PERMIT_TYPES_SUCCESS = 'save-permit-types-success'
export const REMOVE_PERMIT_TYPES = 'remove-permit-types'
export const REMOVE_PERMIT_TYPES_SUCCESS = 'remove-permit-types-success'

// Site permits
export const LOAD_AND_LISTEN_TO_SITE_PERMITS = 'load-and-listen-to-site-permits'
export const SITE_PERMITS_RECEIVED = 'site-permits-received'
export const SITE_PERMIT_UPDATED = 'site-permit-updated'
export const LISTEN_TO_SITE_PERMITS = 'listen-to-site-permits'
export const SAVE_SITE_PERMITS = 'save-site-permits'
export const SAVE_SITE_PERMITS_SUCCESS = 'save-site-permits-success'
export const DELETE_SITE_PERMITS = 'delete-site-permits'

// Site permit status changes
export const LOAD_AND_LISTEN_TO_SITE_PERMIT_STATUS_CHANGES =
  'load-and-listen-to-site-permit-status-changes'
export const SITE_PERMIT_STATUS_CHANGES_RECEIVED =
  'site-permit-status-changes-received'
export const SITE_PERMIT_STATUS_CHANGE_UPDATED =
  'site-permit-status-change-updated'
export const LISTEN_TO_SITE_PERMIT_STATUS_CHANGES =
  'listen-to-site-permit-status-changes'

// Site permit ball-in-court inspections
export const LOAD_AND_LISTEN_TO_SITE_PERMIT_BIC_INSPECTIONS =
  'load-and-listen-to-site-permit-bic-inspections'
export const SITE_PERMIT_BIC_INSPECTIONS_RECEIVED =
  'site-permit-bic-inspections-received'
export const SITE_PERMIT_BIC_INSPECTION_UPDATED =
  'site-permit-bic-inspection-updated'
export const LISTEN_TO_SITE_PERMIT_BIC_INSPECTIONS =
  'listen-to-site-permit-bic-inspections'
export const SAVE_SITE_PERMIT_BIC_INSPECTION = 'save-site-permit-bic-inspection'
export const SAVE_SITE_PERMIT_BIC_INSPECTION_SUCCESS =
  'save-site-permit-bic-inspection-success'

// Site permit inspections
export const LOAD_AND_LISTEN_TO_SITE_PERMIT_INSPECTIONS =
  'load-and-listen-to-site-permit-inspections'
export const SITE_PERMIT_INSPECTIONS_RECEIVED =
  'site-permit-inspections-received'
export const SITE_PERMIT_INSPECTION_UPDATED = 'site-permit-inspection-updated'
export const LISTEN_TO_SITE_PERMIT_INSPECTIONS =
  'listen-to-site-permit-inspections'
export const ADD_SITE_PERMIT_INSPECTION = 'add-site-permit-inspection'
export const ADD_SITE_PERMIT_INSPECTION_SUCCESS =
  'add-site-permit-inspection-success'

// Site permit inspection changes
export const LOAD_AND_LISTEN_TO_SITE_PERMIT_INSPECTION_CHANGES =
  'load-and-listen-to-site-permit-inspection-changes'
export const SITE_PERMIT_INSPECTION_CHANGES_RECEIVED =
  'site-permit-inspection-changes-received'
export const SITE_PERMIT_INSPECTION_CHANGE_UPDATED =
  'site-permit-inspection-change-updated'
export const LISTEN_TO_SITE_PERMIT_INSPECTION_CHANGES =
  'listen-to-site-permit-inspection-changes'
export const UPDATE_SITE_PERMIT_INSPECTION = 'update-site-permit-inspection'
export const UPDATE_SITE_PERMIT_INSPECTION_SUCCESS =
  'update-site-permit-inspection-success'

export const LOAD_DELIVERY_STATUS_CHANGES = 'load-delivery-status-changes'
export const CHANGE_DELIVERY_STATUS_SUCCESS = 'change-delivery-status-success'

// Tenant configurations
export const GET_CONFIGURATION = 'get-configuration'
export const DEFAULT_MANDATORY_FIELDS_CONFIGURATION_RECEIVED =
  'default-mandatory-fields-configuration-received'
export const DEFAULT_HIDDEN_FIELDS_CONFIGURATION_RECEIVED =
  'default-hidden-fields-configuration-received'

// Project configurations
export const PROJECT_INTEGRATIONS_CONFIGS_RECEIVED =
  'project-integrations-configs-received'
export const INIT_PROJECT_INTEGRATIONS = 'init-project-integrations'

// PDF downloading
export const GET_PDF_BLOB_URL = 'get-pdf-blob-url'
export const GET_PDF_BLOB_URL_SUCCESS = 'get-pdf-blob-url-success'

// Material options
export const LOAD_AND_LISTEN_TO_MATERIAL_OPTIONS =
  'load-and-listen-to-material-options'
export const MATERIAL_OPTIONS_RECEIVED = 'material-options-received'
export const MATERIAL_OPTIONS_UPDATED = 'material-options-updated'
export const LISTEN_TO_MATERIAL_OPTIONS = 'listen-to-material-options'
export const SAVE_MATERIAL_OPTIONS = 'save-material-options'

export const LISTEN_TO_PROJECT = 'listen-to-project'
export const PROJECT_RECEIVED = 'project-received'
// Project address
export const LOAD_AND_LISTEN_TO_PROJECT_ADDRESS =
  'load-and-listen-to-project-address'
export const PROJECT_ADDRESS_RECEIVED = 'project-address-received'
export const PROJECT_ADDRESS_UPDATED = 'project-address-updated'
export const LISTEN_TO_PROJECT_ADDRESS = 'listen-to-project-address'
export const SAVE_PROJECT_ADDRESSES = 'save-project-addresses'

// Project type options
export const LOAD_AND_LISTEN_TO_PROJECT_TYPE_OPTIONS =
  'load-and-listen-to-project-type-options'
export const PROJECT_TYPE_OPTIONS_RECEIVED = 'project-type-options-received'
export const PROJECT_TYPE_OPTIONS_UPDATED = 'project-type-options-updated'
export const LISTEN_TO_PROJECT_TYPE_OPTIONS = 'listen-to-project-type-options'
export const SAVE_PROJECT_TYPE_OPTIONS = 'save-project-type-options'

// Project status update options
export const LOAD_AND_LISTEN_TO_PROJECT_SU_OPTIONS =
  'load-and-listen-to-project-su-options'
export const PROJECT_SU_OPTIONS_RECEIVED = 'project-su-options-received'
export const PROJECT_SU_OPTIONS_UPDATED = 'project-su-options-updated'
export const LISTEN_TO_PROJECT_SU_OPTIONS = 'listen-to-project-su-options'
export const SAVE_PROJECT_SU_OPTIONS = 'save-project-su-options'

// Project coloring options
export const LOAD_AND_LISTEN_TO_PROJECT_COLORING_OPTIONS =
  'load-and-listen-to-project-coloring-options'
export const PROJECT_COLORING_OPTIONS_RECEIVED =
  'project-coloring-options-received'
export const PROJECT_COLORING_OPTIONS_UPDATED =
  'project-coloring-options-updated'
export const LISTEN_TO_PROJECT_COLORING_OPTIONS =
  'listen-to-project-coloring-options'
export const SAVE_PROJECT_COLORING_OPTIONS = 'save-project-coloring-options'

// Delivery config
export const LOAD_AND_LISTEN_TO_DELIVERY_CONFIGURATIONS =
  'load-and-listen-to-delivery-configurations'
export const DELIVERY_CONFIGURATIONS_RECEIVED =
  'delivery-configurations-received'
export const DELIVERY_CONFIGURATIONS_UPDATED = 'delivery-configurations-updated'
export const LISTEN_TO_DELIVERY_CONFIGURATIONS =
  'listen-to-delivery-configurations'
export const SAVE_DELIVERY_CONFIGURATIONS = 'save-delivery-configurations'

// Material config
export const LOAD_AND_LISTEN_TO_MATERIAL_CONFIGURATION =
  'load-and-listen-to-material-configuration'
export const MATERIAL_CONFIGURATION_RECEIVED = 'material-configuration-received'
export const MATERIAL_CONFIGURATION_UPDATED = 'material-configuration-updated'
export const LISTEN_TO_MATERIAL_CONFIGURATION =
  'listen-to-material-configuration'
export const SAVE_MATERIAL_CONFIGURATION = 'save-material-configuration'

// Activity config
export const LOAD_AND_LISTEN_TO_ACTIVITIES_CONFIGURATIONS =
  'load-and-listen-to-activities-configurations'
export const ACTIVITIES_CONFIGURATIONS_RECEIVED =
  'activities-configurations-received'
export const ACTIVITIES_CONFIGURATIONS_UPDATED =
  'activities-configurations-updated'
export const LISTEN_TO_ACTIVITIES_CONFIGURATIONS =
  'listen-to-activities-configurations'
export const SAVE_ACTIVITIES_CONFIGURATIONS = 'save-activities-configurations'

// Logistics config
export const LOAD_AND_LISTEN_TO_LOGISTICS_CONFIGURATIONS =
  'load-and-listen-to-logistics-configurations'
export const LOGISTICS_CONFIGURATIONS_RECEIVED =
  'logistics-configurations-received'
export const LOGISTICS_CONFIGURATIONS_UPDATED =
  'logistics-configurations-updated'
export const LISTEN_TO_LOGISTICS_CONFIGURATIONS =
  'listen-to-logistics-configurations'
export const SAVE_LOGISTICS_CONFIGURATIONS = 'save-logistics-configurations'

// Forms config
export const LOAD_AND_LISTEN_TO_FORMS_CONFIGURATIONS =
  'load-and-listen-to-forms-configurations'
export const FORMS_CONFIGURATIONS_RECEIVED = 'forms-configurations-received'
export const FORMS_CONFIGURATIONS_UPDATED = 'forms-configurations-updated'
export const LISTEN_TO_FORMS_CONFIGURATIONS = 'listen-to-forms-configurations'
export const SAVE_FORMS_CONFIGURATIONS = 'save-forms-configurations'

// Delivery fields config
export const LOAD_AND_LISTEN_TO_DELIVERY_FIELDS_CONFIGURATIONS =
  'load-and-listen-to-delivery-fields-configurations'
export const DELIVERY_FIELDS_CONFIGURATIONS_RECEIVED =
  'delivery-fields-configurations-received'
export const DELIVERY_FIELDS_CONFIGURATIONS_UPDATED =
  'delivery-fields-configurations-updated'
export const LISTEN_TO_DELIVERY_FIELDS_CONFIGURATIONS =
  'listen-to-delivery-fields-configurations'
export const SAVE_DELIVERY_FIELDS_CONFIGURATIONS =
  'save-delivery-fields-configurations'

export const LOAD_AND_LISTEN_TO_ZONEMAP_THRESHOLDS =
  'load-and-listen-to-zonemap-thresholds'
export const ZONEMAP_THRESHOLDS_RECEIVED = 'zonemap-thresholds-received'
export const ZONEMAP_THRESHOLDS_UPDATED = 'zonemap-thresholds-updated'
export const LISTEN_TO_ZONEMAP_THRESHOLDS = 'listen-to-zonemap-thresholds'
export const SAVE_ZONEMAP_THRESHOLDS = 'save-zonemap-thresholds'

// Project history
export const LOAD_AND_LISTEN_TO_PROJECT_HISTORY =
  'load-and-listen-to-project-history'
export const PROJECT_HISTORY_RECEIVED = 'project-history-received'
export const PROJECT_HISTORY_UPDATED = 'project-history-updated'
export const LISTEN_TO_PROJECT_HISTORY = 'listen-to-project-history'
export const SAVE_PROJECT_HISTORY = 'save-project-history'

// Project
export const LOAD_PROJECTS = 'load-projects'
export const PROJECTS_RECEIVED = 'projects-received'
export const SAVE_PROJECT = 'save-project'
export const SAVE_PROJECT_SUCCESS = 'save-project-success'
export const FILL_PROJECT_WITH_DEFAULT_MATERIALS =
  'fill-project-with-default-materials'

// Auth User's Projects Settings
export const GET_AUTH_USER_PROJECT = 'get-auth-user-project'
export const GET_AUTH_USER_PROJECT_SUCCESS = 'get-auth-user-project-success'

// Members' Project settings
export const LOAD_AND_LISTEN_TO_USER_PROJECTS =
  'load-and-listen-to-users-projects'
export const LISTEN_TO_USER_PROJECTS = 'listen-to-users-projects'
export const USER_PROJECTS_RECEIVED = 'users-projects-received'
export const USER_PROJECT_UPDATED = 'users-projects-updated'

export const SAVE_USER_PROJECTS = 'save-user-projects'
export const SAVE_USER_PROJECTS_SUCCESS = 'save-user-projects-success'
export const SAVE_USER_PROJECTS_ERROR = 'save-user-projects-error'

export const UPDATE_ACTIVITY_COMPANIES_STATUSES =
  'update-activity-companies-statuses'
export const UPDATE_ACTIVITY_COMPANY_STATUS_MULTIDATES =
  'update-activity-company-status-multidates'
export const BULK_UPDATE_ACTIVITY_COMPANY_STATUS =
  'bulk-update-activity-company-status'
export const BULK_UPDATE_ACTIVITY_COMPANY_STATUS_SUCCESS =
  'bulk-update-activity-company-status-success'
export const UPDATE_ACTIVITY_COMPANY_STATUS_SUCCESS =
  'update-activity-company-status-success'
export const UPDATE_ACTIVITY_COMPANY_STATUS_WITH_NOTIFICATION =
  'update-activity-company-status-with-notification'

// Weather forecast
export const GET_WEATHER_FORECASTS = 'get-weather-forecasts'
export const LISTEN_TO_WEATHER_FORECASTS = 'listen-to-weather-forecasts'
export const WEATHER_FORECASTS_RECEIVED = 'weather-forecasts-received'
export const WEATHER_FORECAST_UPDATED = 'weather-forecast-updated'

// Recurring deliveries setting
export const GET_RECURRING_DELIVERIES_SETTING =
  'get-recurring-deliveries-setting'
export const LISTEN_TO_RECURRING_DELIVERIES_SETTING =
  'listen-to-recurring-deliveries-setting'
export const RECURRING_DELIVERIES_SETTING_RECEIVED =
  'recurring-deliveries-setting-received'
export const RECURRING_DELIVERIES_SETTING_UPDATED =
  'recurring-deliveries-setting-updated'

// Maturix casts
export const GET_MATURIX_CASTS = 'get-maturix-casts'
export const GET_MATURIX_CASTS_SUCCESS = 'get-maturix-casts-success'

// Maturix project
export const GET_MATURIX_CONFIGURATION = 'get-maturix-configuration'
export const GET_MATURIX_CONFIGURATION_SUCCESS =
  'get-maturix-configuration-success'
export const EDIT_BULK_ACTIVITIES = 'edit-bulk-activities'

export const SHOW_ONE_TIME_POPUP = 'show-one-time-popup'
