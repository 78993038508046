import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { IPermitTypeField, PermitFieldType } from '~/client/graph'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import InitialState from '~/client/src/shared/stores/InitialState'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'

import SitePermitCreationFormStore from '../../SitePermitCreationForm.store'
import PermitFormUserField from './PermitFormUserField'

interface IProps {
  typeField: IPermitTypeField
  isViewMode: boolean
  shouldHideResponsible: boolean

  store: SitePermitCreationFormStore
  fieldsStore: IPermitFieldsStore

  state?: InitialState
  projectMembersStore?: ProjectMembersStore
}

@inject('state', 'projectMembersStore')
@observer
export default class PermitFormRequesterField extends React.Component<IProps> {
  public render() {
    const { isViewMode, store, typeField, shouldHideResponsible, state } =
      this.props
    const { isMultiple } = typeField
    const { user: currentUser } = state
    const {
      editablePermit: { fields },
      isAssigneeShown,
    } = store

    const responsibleIds = fields.find(
      f => f.type === PermitFieldType.Assignee,
    )?.values

    const areRequesterAndResponsibleCurrentUser =
      !isMultiple &&
      responsibleIds?.includes(currentUser.id) &&
      this.fieldValues.includes(currentUser.id)

    if (
      areRequesterAndResponsibleCurrentUser &&
      shouldHideResponsible &&
      isAssigneeShown &&
      !isViewMode
    ) {
      return null
    }

    return <PermitFormUserField {...this.props} />
  }

  private get fieldValues(): string[] {
    const { typeField, fieldsStore } = this.props
    const values = fieldsStore.getFieldValues<string>(typeField.id)
    return values?.length ? values : ['']
  }
}
