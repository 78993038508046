import React from 'react'

import InlineSVG from 'svg-inline-react'

import DeliveryNotificationChangedIcon from '~/client/static/icons/DeliveryNotificationChanged.svg'
import DeliveryNotificationDoneIcon from '~/client/static/icons/DeliveryNotificationDone.svg'
import DeliveryNotificationInspectionAcceptedIcon from '~/client/static/icons/DeliveryNotificationInspectionAccepted.svg'
import DeliveryNotificationInspectionRejectedIcon from '~/client/static/icons/DeliveryNotificationInspectionRejected.svg'
import DeliveryNotificationOnsiteIcon from '~/client/static/icons/DeliveryNotificationOnsite.svg'
import DeliveryNotificationRequestedIcon from '~/client/static/icons/DeliveryNotificationRequested.svg'
import DeliveryNotificationScheduledIcon from '~/client/static/icons/DeliveryNotificationScheduled.svg'
import NoteBigIcon from '~/client/static/icons/Note-big.svg'
import NotificationDeliveryIcon from '~/client/static/icons/NotificationDelivery.svg'
import NotificationScheduleIcon from '~/client/static/icons/NotificationSchedule.svg'
import ActivitiesNoColorIcon from '~/client/static/icons/activities-no-color.svg'
import ActivitiesIcon from '~/client/static/icons/activities.svg'
import ActivityLateCheckmarkIcon from '~/client/static/icons/activity-late-checkmark.svg'
import ActivityOntimeCheckmarkIcon from '~/client/static/icons/activity-ontime-checkmark.svg'
import ActivityWarningCheckmarkIcon from '~/client/static/icons/activity-warning-checkmark.svg'
import ActualisedFromScheduleIcon from '~/client/static/icons/actualized-from-schedule.svg'
import AddIcon from '~/client/static/icons/add.svg'
import AlertIcon from '~/client/static/icons/alert.svg'
import AnnouncementIcon from '~/client/static/icons/announcement.svg'
import ArchiveIcon from '~/client/static/icons/archive-icon.svg'
import ArrowBackIcon from '~/client/static/icons/arrow-back-ios.svg'
import DownArrowIcon from '~/client/static/icons/arrow-down.svg'
import ArrowForwardIcon from '~/client/static/icons/arrow-forward-ios.svg'
import ArrowNextIcon from '~/client/static/icons/arrow-next.svg'
import AtIcon from '~/client/static/icons/at.svg'
import AttachButtonIcon from '~/client/static/icons/attach-button-icon.svg'
import AttachFileIcon from '~/client/static/icons/attach-file-icon.svg'
import BackArrowIcon from '~/client/static/icons/back-arrow.svg'
import BallInCourtIcon from '~/client/static/icons/ball-in-court.svg'
import BellIcon from '~/client/static/icons/bell.svg'
import BoldIcon from '~/client/static/icons/bold.svg'
import BooleanAndIcon from '~/client/static/icons/boolean-and.svg'
import BooleanOrIcon from '~/client/static/icons/boolean-or.svg'
import CalendarBlankGrayIcon from '~/client/static/icons/calendar-blank-gray.svg'
import CalendarCloseIcon from '~/client/static/icons/calendar-close.svg'
import CalendarSelectedGrayIcon from '~/client/static/icons/calendar-gray-selected.svg'
import CalendarWhiteIcon from '~/client/static/icons/calendar-white.svg'
import CalendarIcon from '~/client/static/icons/calendar_today.svg'
import CameraButtonIcon from '~/client/static/icons/camera-button-icon.svg'
import CameraFilledIcon from '~/client/static/icons/camera-filled.svg'
import CameraBadgeIcon from '~/client/static/icons/camera-outline-badge.svg'
import CameraRoundedIcon from '~/client/static/icons/camera-rounded.svg'
import CameraWhiteIcon from '~/client/static/icons/camera.svg'
import ChatIcon from '~/client/static/icons/chat.svg'
import CheckEmptyBlueIcon from '~/client/static/icons/check-empty-blue.svg'
import CheckEmptyRedIcon from '~/client/static/icons/check-empty-red.svg'
import CheckFillBlueIcon from '~/client/static/icons/check-filled-blue.svg'
import CheckFillGreenIcon from '~/client/static/icons/check-mark-green.svg'
import CheckSolidGreyIcon from '~/client/static/icons/check-solid-grey.svg'
import CheckPaletteBlueIcon from '~/client/static/icons/checked-palette-blue.svg'
import ChecklistIcon from '~/client/static/icons/checklist.svg'
import ClockIcon from '~/client/static/icons/clock.svg'
import CrossGreyIcon from '~/client/static/icons/close-filled-grey.svg'
import CrossWhiteIcon from '~/client/static/icons/close-white.svg'
import CrossIcon from '~/client/static/icons/close.svg'
import CloudDownloadIcon from '~/client/static/icons/cloud-download.svg'
import CollapseAllIcon from '~/client/static/icons/collapse-all.svg'
import CompactBuildingIcon from '~/client/static/icons/compact-building.svg'
import CompanyCompactIcon from '~/client/static/icons/company-compact.svg'
import CompanyIcon from '~/client/static/icons/company.svg'
import ConcreteDirectIcon from '~/client/static/icons/concrete-direct.svg'
import ConditionalLogicIcon from '~/client/static/icons/conditional-logic.svg'
import CopyFileIcon from '~/client/static/icons/copy-file.svg'
import CopyLinkIcon from '~/client/static/icons/copy-link.svg'
import CopyOrientationIcon from '~/client/static/icons/copy-orientation-icon.svg'
import CriticalPriorityIcon from '~/client/static/icons/critical-path.svg'
import DateChangeBlueIcon from '~/client/static/icons/date-change-blue.svg'
import DateChangeRedIcon from '~/client/static/icons/date-change-red.svg'
import DeleteDeliveryIcon from '~/client/static/icons/delete-delivery.svg'
import DeleteIcon from '~/client/static/icons/delete.svg'
import DeliveryIcon from '~/client/static/icons/deliveries.svg'
import DeliveryAcceptedIcon from '~/client/static/icons/delivery-accepted.svg'
import DeliveryAssignMapIcon from '~/client/static/icons/delivery-assign-map.svg'
import DeliveryDoneIcon from '~/client/static/icons/delivery-done.svg'
import DeliveryFailedInspectionIcon from '~/client/static/icons/delivery-failed-inspection.svg'
import DeliveryFilledIcon from '~/client/static/icons/delivery-filled.svg'
import DeliveryHeaderBarIcon from '~/client/static/icons/delivery-header-bar.svg'
import DeliveryOnsiteIcon from '~/client/static/icons/delivery-onsite.svg'
import DeliveryOutlineIcon from '~/client/static/icons/delivery-outline.svg'
import DeliveryPassedInspectionIcon from '~/client/static/icons/delivery-passed-inspection.svg'
import DeliveryRequestedIcon from '~/client/static/icons/delivery-requested.svg'
import DeliveryRoundedCrossedIcon from '~/client/static/icons/delivery-rounded-cross-out.svg'
import DeliveryRoundedIcon from '~/client/static/icons/delivery-rounded-icon.svg'
import DeliveryUpdateIcon from '~/client/static/icons/delivery-update.svg'
import DocsIcon from '~/client/static/icons/docs.svg'
import DocumentsIcon from '~/client/static/icons/documents.svg'
import DoubleVerticalDotsIcon from '~/client/static/icons/double-vertical-dots.svg'
import DropZoneIcon from '~/client/static/icons/drop-zone.svg'
import DuplicateIcon from '~/client/static/icons/duplicate.svg'
import EditActiveIcon from '~/client/static/icons/edit-blue.svg'
import EditFilledIcon from '~/client/static/icons/edit-filled.svg'
import EditInactiveIcon from '~/client/static/icons/edit-grey.svg'
import EditIcon from '~/client/static/icons/edit.svg'
import EmailIcon from '~/client/static/icons/email.svg'
import EmojiIcon from '~/client/static/icons/emoji-icon.svg'
import EquipmentIcon from '~/client/static/icons/equipment.svg'
import ExcelAppIcon from '~/client/static/icons/excel-app-icon.svg'
import ExpandAllIcon from '~/client/static/icons/expand-all.svg'
import EyeHideIcon from '~/client/static/icons/eye-hide.svg'
import EyeViewIcon from '~/client/static/icons/eye-view.svg'
import FilterSortedIcon from '~/client/static/icons/filter-sorted.svg'
import FilterIcon from '~/client/static/icons/filter.svg'
import FlagBadgeIcon from '~/client/static/icons/flag-badge.svg'
import CloseFlagIcon from '~/client/static/icons/flag.svg'
import FormFilledIcon from '~/client/static/icons/form-filled.svg'
import AerialInspectionFormIcon from '~/client/static/icons/form-icons/aerial-form.svg'
import AirMitigationFormIcon from '~/client/static/icons/form-icons/air-mitigation-form.svg'
import EnergizedFormIcon from '~/client/static/icons/form-icons/energized-form.svg'
import FireExtinguisherFormIcon from '~/client/static/icons/form-icons/fire-extinguisher-form.svg'
import GeneralFormIcon from '~/client/static/icons/form-icons/general-form.svg'
import HeavyEquipmentFormIcon from '~/client/static/icons/form-icons/heavy-equipment-form.svg'
import HotworkFormIcon from '~/client/static/icons/form-icons/hotwork-form.svg'
import LadderFormIcon from '~/client/static/icons/form-icons/ladder-form.svg'
import MaterialTransferFormIcon from '~/client/static/icons/form-icons/material-transfer-form.svg'
import PenetrationFormIcon from '~/client/static/icons/form-icons/penetration-form.svg'
import FormOutlineIcon from '~/client/static/icons/form-outline.svg'
import FormAssignMapIcon from '~/client/static/icons/forms-assign-map.svg'
import FromToDeliveryIcon from '~/client/static/icons/from-to-delivery.svg'
import FullscreenExitIcon from '~/client/static/icons/fullscreen-exit.svg'
import FullscreenIcon from '~/client/static/icons/fullscreen.svg'
import GalleryButtonIcon from '~/client/static/icons/gallery-button-icon.svg'
import GanttSharpIcon from '~/client/static/icons/gantt-sharp.svg'
import GanttIcon from '~/client/static/icons/gantt.svg'
import GateIcon from '~/client/static/icons/gate-icon.svg'
import FilledGateIcon from '~/client/static/icons/gate.svg'
import GlobeDeleteIcon from '~/client/static/icons/globe-delete.svg'
import GlobeDuplicateIcon from '~/client/static/icons/globe-duplicate.svg'
import GlobeEditIcon from '~/client/static/icons/globe-edit.svg'
import GlobeIcon from '~/client/static/icons/globe.svg'
import GroupByIcon from '~/client/static/icons/group-by.svg'
import HashTagIcon from '~/client/static/icons/hash-tag.svg'
import HeaderIcon from '~/client/static/icons/header.svg'
import HierarchyIcon from '~/client/static/icons/hierarchy.svg'
import HomeAssignMapIcon from '~/client/static/icons/home-assign-map.svg'
import HomeRoundedCrossedIcon from '~/client/static/icons/home-rounded-cross-out.svg'
import HomeRoundedIcon from '~/client/static/icons/home-rounded-icon.svg'
import HomeIcon from '~/client/static/icons/home.svg'
import HorizontalArrowsIcon from '~/client/static/icons/horizontal-arrow.svg'
import HorizontalLineIcon from '~/client/static/icons/horizontal-line.svg'
import HorizontalListLinesIcon from '~/client/static/icons/horizontal-list-lines.svg'
import IdIconSrc from '~/client/static/icons/id-icon.svg'
import IncompleteIcon from '~/client/static/icons/incomplete.svg'
import InfoIcon from '~/client/static/icons/info.svg'
import IntegrationIcon from '~/client/static/icons/integrations.svg'
import LinkIcon from '~/client/static/icons/link.svg'
import BulletedListIcon from '~/client/static/icons/list-bulleted.svg'
import NumberedListIcon from '~/client/static/icons/list-numbered.svg'
import ListIcon from '~/client/static/icons/list.svg'
import LocationIcon from '~/client/static/icons/location.svg'
import MapSearchIcon from '~/client/static/icons/map_search.svg'
import MapLayerTrafficIcon from '~/client/static/icons/mapbox-icons/map-styles/map-layer-traffic.svg'
import MapSatelliteSmallIcon from '~/client/static/icons/mapbox-icons/map-styles/map-satellite-small.svg'
import MapStreetSmallIcon from '~/client/static/icons/mapbox-icons/map-styles/map-street-small.svg'
import MoreLayerStylesSmallIcon from '~/client/static/icons/mapbox-icons/map-styles/more-layer-styles-small.svg'
import MoreLayerStylesIcon from '~/client/static/icons/mapbox-icons/map-styles/more-layer-styles.svg'
import NotReferencedIcon from '~/client/static/icons/mapbox-icons/not-referenced.svg'
import MaterialIcon from '~/client/static/icons/material.svg'
import MaterialsRoundedIcon from '~/client/static/icons/materials-rounded-icon.svg'
import MaximizeIcon from '~/client/static/icons/maximize.svg'
import MeasureIcon from '~/client/static/icons/measure.svg'
import MegaphoneFilledIcon from '~/client/static/icons/megaphone-filled.svg'
import MegaphoneStencilIcon from '~/client/static/icons/megaphone-stencil.svg'
import MegaphoneIcon from '~/client/static/icons/megaphone.svg'
import MentionButtonIcon from '~/client/static/icons/mention-button-icon.svg'
import OffloadingEquipmentMenuDownIcon from '~/client/static/icons/menu-down.svg'
import OffloadingEquipmentMenuLeftIcon from '~/client/static/icons/menu-left.svg'
import OffloadingEquipmentMenuRightIcon from '~/client/static/icons/menu-right.svg'
import OffloadingEquipmentMenuUpIcon from '~/client/static/icons/menu-up.svg'
import MessagesIcon from '~/client/static/icons/messages.svg'
import MicrosoftAzureIcon from '~/client/static/icons/microsoft-azure.svg'
import MinimizeIcon from '~/client/static/icons/minimize.svg'
import LogoWithTitleIcon from '~/client/static/icons/mobile-icons/logo-with-title.svg'
import LogoutIcon from '~/client/static/icons/mobile-icons/logout.svg'
import ProfileIcon from '~/client/static/icons/mobile-icons/profile.svg'
import MobileIcon from '~/client/static/icons/mobile.svg'
import MoreHorizontalIcon from '~/client/static/icons/more-horizontal.svg'
import MicrosoftIcon from '~/client/static/icons/ms-symbollockup_mssymbol_19.svg'
import NavigationArrowsUpIcon from '~/client/static/icons/navigation-arrows-down.svg'
import NavigationArrowsRevertIcon from '~/client/static/icons/navigation-arrows-revert.svg'
import NavigationArrowsDownIcon from '~/client/static/icons/navigation-arrows-up.svg'
import NavigationArrowsIcon from '~/client/static/icons/navigation-arrows.svg'
import NoteIcon from '~/client/static/icons/note.svg'
import NotificationBellIcon from '~/client/static/icons/notification-bell.svg'
import NotificationsIcon from '~/client/static/icons/notifications.svg'
import PasteOrientationIcon from '~/client/static/icons/paste-orientation-icon.svg'
import PDFAppIcon from '~/client/static/icons/pdf-app-icon.svg'
import PdfIcon from '~/client/static/icons/pdf-icon.svg'
import PermitsRoundedActiveIcon from '~/client/static/icons/permits-rounded-active.svg'
import PermitsRoundedCrossedIcon from '~/client/static/icons/permits-rounded-cross-out.svg'
import PhoneIcon from '~/client/static/icons/phone.svg'
import PhotoIcon from '~/client/static/icons/photo-black.svg'
import PhotoCameraIcon from '~/client/static/icons/photo-camera-badge.svg'
import PhotoLoadingIcon from '~/client/static/icons/photo-loading-icon.svg'
import PhotoPlaceholderIcon from '~/client/static/icons/photo-placeholder.svg'
import PieChartIcon from '~/client/static/icons/pie-chart-blue.svg'
import PinOnMapIcon from '~/client/static/icons/pin on map.svg'
import PlusCircleIcon from '~/client/static/icons/plus-circle.svg'
import PlusRoundedIcon from '~/client/static/icons/plus-rounded.svg'
import PlusIcon from '~/client/static/icons/plus.svg'
import PPTXAppIcon from '~/client/static/icons/pptx-app-icon.svg'
import PresentationIcon from '~/client/static/icons/presentation.svg'
import PrintIcon from '~/client/static/icons/print.svg'
import ProcoreIcon from '~/client/static/icons/procore.svg'
import ProjectDetailsSettingsIcon from '~/client/static/icons/project-details-settings.svg'
import ProjectLocationIcon from '~/client/static/icons/project-location.svg'
import ProjectsIcon from '~/client/static/icons/projects.svg'
import QRCodeIcon from '~/client/static/icons/qr-code.svg'
import QuestionIcon from '~/client/static/icons/question.svg'
import RecurringEventIcon from '~/client/static/icons/recurring-event.svg'
import RedoIcon from '~/client/static/icons/redo.svg'
import ReferencedIcon from '~/client/static/icons/referenced.svg'
import RemoveIcon from '~/client/static/icons/remove.svg'
import ReorderIcon from '~/client/static/icons/reorder.svg'
import RepeatIcon from '~/client/static/icons/repeat.svg'
import ResetViewportIcon from '~/client/static/icons/reset-viewport-icon.svg'
import ResourcesBlueIcon from '~/client/static/icons/resources-blue.svg'
import ResourcesGrayIcon from '~/client/static/icons/resources-gray.svg'
import RestoreIcon from '~/client/static/icons/restore.svg'
import RfiBadgeIcon from '~/client/static/icons/rfi-badge.svg'
import RightArrowIcon from '~/client/static/icons/right-arrow.svg'
import RightBlueArrowIcon from '~/client/static/icons/right.svg'
import RoundedLocationIcon from '~/client/static/icons/rounded-location.svg'
import RouteReportIcon from '~/client/static/icons/route-report.svg'
import SafetyIcon from '~/client/static/icons/safety.svg'
import SaveMenuIcon from '~/client/static/icons/save-menu-small.svg'
import ScheduleAssignMapIcon from '~/client/static/icons/schedule-assign-map.svg'
import ScheduleRoundedCrossedIcon from '~/client/static/icons/schedule-rounded-cross-out.svg'
import ScheduleRoundedIcon from '~/client/static/icons/schedule-rounded-icon.svg'
import BlueSearchIcon from '~/client/static/icons/search-icon.svg'
import SearchIcon from '~/client/static/icons/search.svg'
import SendMessageSlackIcon from '~/client/static/icons/send-message-icon.svg'
import SendMessageIcon from '~/client/static/icons/send-message.svg'
import SensorIcon from '~/client/static/icons/sensor.svg'
import SidebarAnnouncementIcon from '~/client/static/icons/sidebar-notice.svg'
import SitemapIcon from '~/client/static/icons/sitemap.svg'
import SortAscIcon from '~/client/static/icons/sort-asc.svg'
import SortDescIcon from '~/client/static/icons/sort-desc.svg'
import StarFilledBigIcon from '~/client/static/icons/star-filled-big.svg'
import StarFilledRoundedIcon from '~/client/static/icons/star-filled-rounded.svg'
import FilledStarIcon from '~/client/static/icons/star-filled.svg'
import StarHalfIcon from '~/client/static/icons/star-half.svg'
import StarUnfilledBigIcon from '~/client/static/icons/star-unfilled-big.svg'
import StarUnfilledInactiveBigIcon from '~/client/static/icons/star-unfilled-inactive-big.svg'
import UnfilledStarInactiveIcon from '~/client/static/icons/star-unfilled-inactive.svg'
import UnfilledStarIcon from '~/client/static/icons/star-unfilled.svg'
import StatusBadgeGrayIcon from '~/client/static/icons/status-gray.svg'
import StatusUpdateSmallIcon from '~/client/static/icons/status-update-small.svg'
import StatusUpdateStateBlueIcon from '~/client/static/icons/status-update-state-blue.svg'
import StatusUpdateStateGrayIcon from '~/client/static/icons/status-update-state-gray.svg'
import StatusUpdateIcon from '~/client/static/icons/status-update.svg'
import StatusBadgeIcon from '~/client/static/icons/status.svg'
import LogoDarkIcon from '~/client/static/icons/strux-hub-dark.svg'
import LogoMenuIcon from '~/client/static/icons/strux-hub-menu.svg'
import StruxHubFullIcon from '~/client/static/icons/struxhub-full.svg'
import TLetterIcon from '~/client/static/icons/t-letter-icon.svg'
import AddBorderedIcon from '~/client/static/icons/tag-icons/add-bordered.svg'
import TagRightIcon from '~/client/static/icons/tag-right.svg'
import TagIcon from '~/client/static/icons/tag.svg'
import TeamIcon from '~/client/static/icons/team.svg'
import UnderlinedIcon from '~/client/static/icons/text-underlined.svg'
import ThreeDotsIcon from '~/client/static/icons/three-dots.svg'
import TradeIcon from '~/client/static/icons/trade.svg'
import TruckIcon from '~/client/static/icons/truck-icon.svg'
import TruckSmallIcon from '~/client/static/icons/truck-small.svg'
import UndoIcon from '~/client/static/icons/undo.svg'
import UnlinkIcon from '~/client/static/icons/unlink.svg'
import UnscheduledDeliveryIcon from '~/client/static/icons/unscheduled-delivery.svg'
import UploadIcon from '~/client/static/icons/upload.svg'
import UserSetupPrimaryIcon from '~/client/static/icons/user-setup-primary.svg'
import UserIcon from '~/client/static/icons/user.svg'
import UsersDirectoryIcon from '~/client/static/icons/users-directory.svg'
import VarianceIcon from '~/client/static/icons/variance.svg'
import VendorIcon from '~/client/static/icons/vendor.svg'
import WordAppIcon from '~/client/static/icons/word-app-icon.svg'
import WorkflowArrowRightIcon from '~/client/static/icons/workflow-arrow-right.svg'
import WorkflowsBadgeIcon from '~/client/static/icons/workflows.svg'
import ZoneIcon from '~/client/static/icons/zone-icon.svg'

export function PDFApp(props) {
  return <InlineSVG {...props} src={PDFAppIcon} />
}

export function PPTXApp(props) {
  return <InlineSVG {...props} src={PPTXAppIcon} />
}

export function WordApp(props) {
  return <InlineSVG {...props} src={WordAppIcon} />
}

export function ExcelApp(props) {
  return <InlineSVG {...props} src={ExcelAppIcon} />
}

export function AttachButton(props) {
  return <InlineSVG {...props} src={AttachButtonIcon} />
}

export function CameraButton(props) {
  return <InlineSVG {...props} src={CameraButtonIcon} />
}

export function GalleryButton(props) {
  return <InlineSVG {...props} src={GalleryButtonIcon} />
}

export function MentionButton(props) {
  return <InlineSVG {...props} src={MentionButtonIcon} />
}

export function SendMessageSlack(props) {
  return <InlineSVG {...props} src={SendMessageSlackIcon} />
}

export function Emoji(props) {
  return <InlineSVG {...props} src={EmojiIcon} />
}

export function CopyOrientation(props) {
  return <InlineSVG {...props} src={CopyOrientationIcon} />
}

export function PasteOrientation(props) {
  return <InlineSVG {...props} src={PasteOrientationIcon} />
}

export function HomeAssignMap(props) {
  return <InlineSVG {...props} src={HomeAssignMapIcon} />
}

export function DeliveryAssignMap(props) {
  return <InlineSVG {...props} src={DeliveryAssignMapIcon} />
}

export function FormAssignMap(props) {
  return <InlineSVG {...props} src={FormAssignMapIcon} />
}

export function ScheduleAssignMap(props) {
  return <InlineSVG {...props} src={ScheduleAssignMapIcon} />
}

export function ResetViewport(props) {
  return <InlineSVG {...props} src={ResetViewportIcon} />
}

/**
 * New Home icons as of 2024
 */
export function DeliveryOutline(props) {
  return <InlineSVG {...props} src={DeliveryOutlineIcon} />
}

export function DeliveryFilled(props) {
  return <InlineSVG {...props} src={DeliveryFilledIcon} />
}

export function FilterSorted(props) {
  return <InlineSVG {...props} src={FilterSortedIcon} />
}

export function FormFilled(props) {
  return <InlineSVG {...props} src={FormFilledIcon} />
}

export function FormOutline(props) {
  return <InlineSVG {...props} src={FormOutlineIcon} />
}

export function GanttSharp(props) {
  return <InlineSVG {...props} src={GanttSharpIcon} />
}

export function MegaphoneStencil(props) {
  return <InlineSVG {...props} src={MegaphoneStencilIcon} />
}

/**
 * /New Home icons as of 2024
 */
export function RecurringEvent(props) {
  return (
    <InlineSVG
      {...props}
      src={RecurringEventIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function WorkflowArrowRight(props) {
  return (
    <InlineSVG
      {...props}
      src={WorkflowArrowRightIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function UserSetupPrimary(props) {
  return (
    <InlineSVG
      {...props}
      src={UserSetupPrimaryIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function GroupBy(props) {
  return (
    <InlineSVG
      {...props}
      src={GroupByIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function NotReferenced(props) {
  return (
    <InlineSVG
      {...props}
      src={NotReferencedIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function GlobeDuplicate(props) {
  return (
    <InlineSVG
      {...props}
      src={GlobeDuplicateIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function GlobeEdit(props) {
  return (
    <InlineSVG
      {...props}
      src={GlobeEditIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function GlobeDelete(props) {
  return (
    <InlineSVG
      {...props}
      src={GlobeDeleteIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function NotificationBell(props) {
  return (
    <InlineSVG
      {...props}
      src={NotificationBellIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function TruckSmall(props) {
  return (
    <InlineSVG
      {...props}
      src={TruckSmallIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function MapStreetSmall(props) {
  return (
    <InlineSVG
      {...props}
      src={MapStreetSmallIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function MapSatelliteSmall(props) {
  return (
    <InlineSVG
      {...props}
      src={MapSatelliteSmallIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function MoreLayerStylesSmall(props) {
  return (
    <InlineSVG
      {...props}
      src={MoreLayerStylesSmallIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function MapLayerTraffic(props) {
  return (
    <InlineSVG
      {...props}
      src={MapLayerTrafficIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function MoreLayerStyles(props) {
  return (
    <InlineSVG
      {...props}
      src={MoreLayerStylesIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Globe(props) {
  return (
    <InlineSVG
      {...props}
      src={GlobeIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Referenced(props) {
  return (
    <InlineSVG
      {...props}
      src={ReferencedIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function ProjectLocation(props) {
  return (
    <InlineSVG
      {...props}
      src={ProjectLocationIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function FromToDelivery(props) {
  return (
    <InlineSVG
      {...props}
      src={FromToDeliveryIcon}
      className={props?.className || 'row'}
    />
  )
}

export function Fullscreen(props) {
  return (
    <InlineSVG
      {...props}
      src={FullscreenIcon}
      className={props?.className || 'row'}
    />
  )
}

export function FullscreenExit(props) {
  return (
    <InlineSVG
      {...props}
      src={FullscreenExitIcon}
      className={props?.className || 'row'}
    />
  )
}

export function UnscheduledDelivery(props) {
  return (
    <InlineSVG
      {...props}
      src={UnscheduledDeliveryIcon}
      className={props?.className || 'row'}
    />
  )
}

export function StarFilledBig(props) {
  return (
    <InlineSVG
      {...props}
      src={StarFilledBigIcon}
      className={props?.className || 'row'}
    />
  )
}

export function StarUnfilledBig(props) {
  return (
    <InlineSVG
      {...props}
      src={StarUnfilledBigIcon}
      className={props?.className || 'row'}
    />
  )
}

export function StarUnfilledInactiveBig(props) {
  return (
    <InlineSVG
      {...props}
      src={StarUnfilledInactiveBigIcon}
      className={props?.className || 'row'}
    />
  )
}

export function DeleteDelivery(props) {
  return (
    <InlineSVG
      {...props}
      src={DeleteDeliveryIcon}
      className={props?.className || 'row'}
    />
  )
}

export function CopyLink(props) {
  return (
    <InlineSVG
      {...props}
      src={CopyLinkIcon}
      className={props?.className || 'row'}
    />
  )
}

export function ThreeDots(props) {
  return (
    <InlineSVG
      {...props}
      src={ThreeDotsIcon}
      className={props?.className || 'row'}
    />
  )
}

export function DeliveryHeaderBar(props) {
  return (
    <InlineSVG
      {...props}
      src={DeliveryHeaderBarIcon}
      className={props?.className || 'row'}
    />
  )
}

export function LogoDark(props) {
  return (
    <InlineSVG
      {...props}
      src={LogoDarkIcon}
      className={props?.className || 'row'}
    />
  )
}

export function LogoMenu(props) {
  return (
    <InlineSVG
      {...props}
      src={LogoMenuIcon}
      className={props?.className || 'row'}
    />
  )
}

export function RouteReport(props) {
  return (
    <InlineSVG
      {...props}
      src={RouteReportIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function StruxHubFull(props) {
  return (
    <InlineSVG
      {...props}
      src={StruxHubFullIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Profile(props) {
  return (
    <InlineSVG
      {...props}
      src={ProfileIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Logout(props) {
  return (
    <InlineSVG
      {...props}
      src={LogoutIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function LogoWithTitle(props) {
  return (
    <InlineSVG
      {...props}
      src={LogoWithTitleIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function CameraRounded(props) {
  return (
    <InlineSVG
      {...props}
      src={CameraRoundedIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function TLetter(props) {
  return (
    <InlineSVG
      {...props}
      src={TLetterIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function NavigationArrows(props) {
  return (
    <InlineSVG
      {...props}
      src={NavigationArrowsIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function NavigationRevertArrows(props) {
  return (
    <InlineSVG
      {...props}
      src={NavigationArrowsRevertIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function NavigationArrowsUp(props) {
  return (
    <InlineSVG
      {...props}
      src={NavigationArrowsUpIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function NavigationArrowsDown(props) {
  return (
    <InlineSVG
      {...props}
      src={NavigationArrowsDownIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function EditFilled(props) {
  return (
    <InlineSVG
      {...props}
      src={EditFilledIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function PermitsRoundedActive(props) {
  return (
    <InlineSVG
      {...props}
      src={PermitsRoundedActiveIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function DeliveryRoundedCrossed(props) {
  return (
    <InlineSVG
      {...props}
      src={DeliveryRoundedCrossedIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function HomeRoundedCrossed(props) {
  return (
    <InlineSVG
      {...props}
      src={HomeRoundedCrossedIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function PermitsRoundedCrossed(props) {
  return (
    <InlineSVG
      {...props}
      src={PermitsRoundedCrossedIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function ScheduleRoundedCrossed(props) {
  return (
    <InlineSVG
      {...props}
      src={ScheduleRoundedCrossedIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function AttachFile(props) {
  return (
    <InlineSVG
      {...props}
      src={AttachFileIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function CloudDownload(props) {
  return (
    <InlineSVG
      {...props}
      src={CloudDownloadIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function ScheduleRounded(props) {
  return (
    <InlineSVG
      {...props}
      src={ScheduleRoundedIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function DeliveryRounded(props) {
  return (
    <InlineSVG
      {...props}
      src={DeliveryRoundedIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function MaterialsRounded(props) {
  return (
    <InlineSVG
      {...props}
      src={MaterialsRoundedIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function HomeRounded(props) {
  return (
    <InlineSVG
      {...props}
      src={HomeRoundedIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function SendMessage(props) {
  return (
    <InlineSVG
      {...props}
      src={SendMessageIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function DropZone(props) {
  return (
    <InlineSVG
      {...props}
      src={DropZoneIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Checklist(props) {
  return (
    <InlineSVG
      {...props}
      src={ChecklistIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function SidebarAnnouncement(props) {
  return (
    <InlineSVG
      {...props}
      src={SidebarAnnouncementIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Incomplete(props) {
  return (
    <InlineSVG
      {...props}
      src={IncompleteIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function CompactBuilding(props) {
  return (
    <InlineSVG
      {...props}
      src={CompactBuildingIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Vendor(props) {
  return (
    <InlineSVG
      {...props}
      src={VendorIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function ActivityWarningCheckmark(props) {
  return <InlineSVG {...props} src={ActivityWarningCheckmarkIcon} />
}

export function ActivityOntimeCheckmark(props) {
  return <InlineSVG {...props} src={ActivityOntimeCheckmarkIcon} />
}

export function ActivityLateCheckmark(props) {
  return <InlineSVG {...props} src={ActivityLateCheckmarkIcon} />
}

export function Info(props) {
  return <InlineSVG {...props} src={InfoIcon} />
}
export function HorizontalArrows(props) {
  return <InlineSVG {...props} src={HorizontalArrowsIcon} />
}

export function Team(props) {
  return <InlineSVG {...props} src={TeamIcon} />
}

export function Sitemap(props) {
  return <InlineSVG {...props} src={SitemapIcon} />
}

export function DoubleVerticalDots(props) {
  return <InlineSVG {...props} src={DoubleVerticalDotsIcon} />
}

export function Gantt(props) {
  return <InlineSVG {...props} src={GanttIcon} />
}

export function Bold(props) {
  return <InlineSVG {...props} src={BoldIcon} />
}

export function Link(props) {
  return <InlineSVG {...props} src={LinkIcon} />
}

export function Unlink(props) {
  return <InlineSVG {...props} src={UnlinkIcon} />
}

export function MapSearch(props) {
  return <InlineSVG {...props} src={MapSearchIcon} />
}

export function Megaphone(props) {
  return <InlineSVG {...props} src={MegaphoneIcon} />
}

export function List(props) {
  return <InlineSVG {...props} src={ListIcon} />
}

export function Company(props) {
  return <InlineSVG {...props} src={CompanyIcon} />
}

export function CompanyCompact(props) {
  return <InlineSVG {...props} src={CompanyCompactIcon} />
}

export function PlusRounded(props) {
  return <InlineSVG {...props} src={PlusRoundedIcon} />
}

export function PlusCircle(props) {
  return (
    <InlineSVG
      {...props}
      src={PlusCircleIcon}
      className={props.className || 'row'}
    />
  )
}

export function EyeView(props) {
  return (
    <InlineSVG
      {...props}
      src={EyeViewIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function EyeHide(props) {
  return (
    <InlineSVG
      {...props}
      src={EyeHideIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function QRCode(props) {
  return (
    <InlineSVG
      {...props}
      src={QRCodeIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Bell(props) {
  return <InlineSVG {...props} src={BellIcon} />
}

export function BooleanOr(props) {
  return (
    <InlineSVG
      {...props}
      src={BooleanOrIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function BooleanAnd(props) {
  return (
    <InlineSVG
      {...props}
      src={BooleanAndIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function User(props) {
  return (
    <InlineSVG
      {...props}
      src={UserIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function UsersDirectory(props) {
  return (
    <InlineSVG
      {...props}
      src={UsersDirectoryIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Chat(props) {
  return (
    <InlineSVG
      {...props}
      src={ChatIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Search(props) {
  return (
    <InlineSVG
      {...props}
      src={SearchIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Hierarchy(props) {
  return (
    <InlineSVG
      {...props}
      src={HierarchyIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Safety(props) {
  return <InlineSVG {...props} src={SafetyIcon} />
}

export function Variance(props) {
  return <InlineSVG {...props} src={VarianceIcon} />
}

export function RoundedLocation(props) {
  return (
    <InlineSVG
      {...props}
      src={RoundedLocationIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Location(props) {
  return (
    <InlineSVG
      {...props}
      src={LocationIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Edit(props) {
  return (
    <InlineSVG {...props} src={EditIcon} className={`row ${props.className}`} />
  )
}

export function Delete(props) {
  return (
    <InlineSVG
      {...props}
      src={DeleteIcon}
      className={`row ${props.className}`}
    />
  )
}

export function Duplicate(props) {
  return <InlineSVG {...props} src={DuplicateIcon} />
}

export function DeliveryUpdate(props) {
  return <InlineSVG {...props} src={DeliveryUpdateIcon} className="row" />
}

export function PhotoPlaceholder(props) {
  return <InlineSVG {...props} src={PhotoPlaceholderIcon} />
}

export function NoteBig(props) {
  return <InlineSVG {...props} src={NoteBigIcon} />
}

export function Note(props) {
  return <InlineSVG {...props} src={NoteIcon} />
}

export function DeliveryNotificationDone(props) {
  return <InlineSVG {...props} src={DeliveryNotificationDoneIcon} />
}

export function DeliveryNotificationInspectionAccepted(props) {
  return (
    <InlineSVG {...props} src={DeliveryNotificationInspectionAcceptedIcon} />
  )
}

export function DeliveryNotificationInspectionRejected(props) {
  return (
    <InlineSVG {...props} src={DeliveryNotificationInspectionRejectedIcon} />
  )
}

export function DeliveryNotificationOnsite(props) {
  return <InlineSVG {...props} src={DeliveryNotificationOnsiteIcon} />
}

export function DeliveryNotificationRequested(props) {
  return <InlineSVG {...props} src={DeliveryNotificationRequestedIcon} />
}

export function DeliveryNotificationScheduled(props) {
  return <InlineSVG {...props} src={DeliveryNotificationScheduledIcon} />
}

export function DeliveryNotificationChanged(props) {
  return <InlineSVG {...props} src={DeliveryNotificationChangedIcon} />
}

export function NotificationDelivery(props) {
  return <InlineSVG {...props} src={NotificationDeliveryIcon} />
}

export function NotificationSchedule(props) {
  return <InlineSVG {...props} src={NotificationScheduleIcon} />
}

export function ActualisedFromSchedule(props) {
  return <InlineSVG {...props} src={ActualisedFromScheduleIcon} />
}

export function Gate(props) {
  return <InlineSVG {...props} src={GateIcon} />
}

export function Zone(props) {
  return <InlineSVG {...props} src={ZoneIcon} />
}

export function PhotoLoading(props) {
  return <InlineSVG {...props} src={PhotoLoadingIcon} />
}

export function CheckSolidGrey(props) {
  return <InlineSVG {...props} src={CheckSolidGreyIcon} />
}

export function CheckFillGreen(props) {
  return <InlineSVG {...props} src={CheckFillGreenIcon} />
}

export function CheckFillBlue(props) {
  return <InlineSVG {...props} src={CheckFillBlueIcon} />
}

export function CheckEmptyRed(props) {
  return <InlineSVG {...props} src={CheckEmptyRedIcon} />
}

export function CheckEmptyBlue(props) {
  return <InlineSVG {...props} src={CheckEmptyBlueIcon} />
}

export function DeliveryAccepted(props) {
  return <InlineSVG {...props} src={DeliveryAcceptedIcon} />
}

export function DeliveryPassedInspection(props) {
  return <InlineSVG {...props} src={DeliveryPassedInspectionIcon} />
}

export function DeliveryRequested(props) {
  return <InlineSVG {...props} src={DeliveryRequestedIcon} />
}

export function DeliveryFailedInspection(props) {
  return <InlineSVG {...props} src={DeliveryFailedInspectionIcon} />
}

export function DeliveryDone(props) {
  return <InlineSVG {...props} src={DeliveryDoneIcon} />
}

export function DeliveryOnsite(props) {
  return <InlineSVG {...props} src={DeliveryOnsiteIcon} />
}

export function Truck(props) {
  const className = `row ${props.className || ''}`
  return <InlineSVG {...props} src={TruckIcon} className={className} />
}

export function EditInactive(props) {
  return <InlineSVG {...props} src={EditInactiveIcon} />
}

export function EditActive(props) {
  return <InlineSVG {...props} src={EditActiveIcon} />
}

export function OffloadingEquipmentMenuDown(props) {
  return <InlineSVG {...props} src={OffloadingEquipmentMenuDownIcon} />
}

export function OffloadingEquipmentMenuUp(props) {
  return <InlineSVG {...props} src={OffloadingEquipmentMenuUpIcon} />
}

export function OffloadingEquipmentMenuRight(props) {
  return <InlineSVG {...props} src={OffloadingEquipmentMenuRightIcon} />
}

export function OffloadingEquipmentMenuLeft(props) {
  return <InlineSVG {...props} src={OffloadingEquipmentMenuLeftIcon} />
}

export function RightArrow(props) {
  return <InlineSVG {...props} src={RightArrowIcon} />
}

export function DownArrow(props) {
  return <InlineSVG {...props} src={DownArrowIcon} />
}

export function Flag(props) {
  return <InlineSVG {...props} src={FlagBadgeIcon} />
}

export function Tag(props) {
  return <InlineSVG {...props} src={TagIcon} />
}

export function TagRight(props) {
  return (
    <InlineSVG
      {...props}
      src={TagRightIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Rfi(props) {
  return <InlineSVG {...props} src={RfiBadgeIcon} />
}

export function DateChangeRed(props) {
  return <InlineSVG {...props} src={DateChangeRedIcon} />
}

export function DateChangeBlue(props) {
  return <InlineSVG {...props} src={DateChangeBlueIcon} />
}

export function SaveMenu(props) {
  return <InlineSVG {...props} src={SaveMenuIcon} />
}

export function PhotoCamera(props) {
  return <InlineSVG {...props} src={PhotoCameraIcon} />
}

export function Critical(props) {
  return <InlineSVG {...props} src={CriticalPriorityIcon} />
}

export function StatusUpdate(props) {
  return <InlineSVG {...props} src={StatusUpdateIcon} />
}

export function StatusUpdateSmall(props) {
  return (
    <InlineSVG
      {...props}
      src={StatusUpdateSmallIcon}
      className="icon-white-background"
    />
  )
}

export function StatusBadge(props) {
  return <InlineSVG {...props} src={StatusBadgeIcon} />
}

export function StatusBadgeGray(props) {
  return <InlineSVG {...props} src={StatusBadgeGrayIcon} />
}

export function Delivery(props) {
  return <InlineSVG {...props} src={DeliveryIcon} />
}

export function CalendarGrey(props) {
  return <InlineSVG {...props} src={CalendarBlankGrayIcon} />
}

export function CalendarSelectedGrey(props) {
  return <InlineSVG {...props} src={CalendarSelectedGrayIcon} />
}

export function Cross(props) {
  return <InlineSVG {...props} src={CrossIcon} />
}

export function CrossWhite(props) {
  return <InlineSVG {...props} src={CrossWhiteIcon} />
}

export function CrossGrey(props) {
  return <InlineSVG {...props} src={CrossGreyIcon} />
}

export function Plus(props) {
  return <InlineSVG {...props} src={PlusIcon} />
}

export function Add(props) {
  return <InlineSVG {...props} src={AddIcon} />
}

export function Remove(props) {
  return <InlineSVG {...props} src={RemoveIcon} />
}

export function Pdf(props) {
  return <InlineSVG {...props} src={PdfIcon} />
}

export function WorkflowsBadge(props) {
  return <InlineSVG {...props} src={WorkflowsBadgeIcon} />
}

export function Clock(props) {
  return <InlineSVG {...props} src={ClockIcon} />
}

export function CalendarClose(props) {
  return <InlineSVG {...props} src={CalendarCloseIcon} />
}

export function CloseFlag(props) {
  return <InlineSVG {...props} src={CloseFlagIcon} />
}

export function BackArrow(props) {
  return <InlineSVG {...props} src={BackArrowIcon} />
}

export function RightBlueArrow(props) {
  return <InlineSVG {...props} src={RightBlueArrowIcon} />
}

export function CameraBadge(props) {
  return (
    <InlineSVG
      {...props}
      src={CameraBadgeIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Archive(props) {
  return <InlineSVG {...props} src={ArchiveIcon} />
}

export function Activities(props) {
  return <InlineSVG {...props} src={ActivitiesIcon} />
}

export function ActivitiesNoColor(props) {
  return <InlineSVG {...props} src={ActivitiesNoColorIcon} />
}

export function Home(props) {
  return <InlineSVG {...props} src={HomeIcon} />
}

export function Notifications(props) {
  return <InlineSVG {...props} src={NotificationsIcon} />
}

export function Equipment(props) {
  return <InlineSVG {...props} src={EquipmentIcon} />
}

export function ResourcesGray(props) {
  return <InlineSVG {...props} src={ResourcesGrayIcon} />
}

export function ResourcesBlue(props) {
  return <InlineSVG {...props} src={ResourcesBlueIcon} />
}

export function Documents(props) {
  return <InlineSVG {...props} src={DocumentsIcon} />
}

export function Projects(props) {
  return <InlineSVG {...props} src={ProjectsIcon} />
}

export function FilledStar(props) {
  return <InlineSVG className="row" {...props} src={FilledStarIcon} />
}

export function UnfilledStar(props) {
  return <InlineSVG className="row" {...props} src={UnfilledStarIcon} />
}

export function UnfilledStarInactive(props) {
  return (
    <InlineSVG
      {...props}
      src={UnfilledStarInactiveIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function StarHalf(props) {
  return (
    <InlineSVG
      {...props}
      src={StarHalfIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function GeneralForm(props) {
  return (
    <InlineSVG
      {...props}
      src={GeneralFormIcon}
      className={props?.className || 'row'}
    />
  )
}

export function FireExtinguisherForm(props) {
  return (
    <InlineSVG
      {...props}
      src={FireExtinguisherFormIcon}
      className={props?.className || 'row'}
    />
  )
}

export function EnergizedForm(props) {
  return (
    <InlineSVG
      {...props}
      src={EnergizedFormIcon}
      className={props?.className || 'row'}
    />
  )
}

export function HotWorkForm(props) {
  return (
    <InlineSVG
      {...props}
      src={HotworkFormIcon}
      className={props?.className || 'row'}
    />
  )
}

export function LadderForm(props) {
  return (
    <InlineSVG
      {...props}
      src={LadderFormIcon}
      className={props?.className || 'row'}
    />
  )
}

export function AerialInspectionForm(props) {
  return (
    <InlineSVG
      {...props}
      src={AerialInspectionFormIcon}
      className={props?.className || 'row'}
    />
  )
}

export function AirMitigationForm(props) {
  return (
    <InlineSVG
      {...props}
      src={AirMitigationFormIcon}
      className={props?.className || 'row'}
    />
  )
}

export function HeavyEquipmentForm(props) {
  return (
    <InlineSVG
      {...props}
      src={HeavyEquipmentFormIcon}
      className={props?.className || 'row'}
    />
  )
}

export function MaterialTransferForm(props) {
  return (
    <InlineSVG
      {...props}
      src={MaterialTransferFormIcon}
      className={props?.className || 'row'}
    />
  )
}

export function PenetrationForm(props) {
  return (
    <InlineSVG
      {...props}
      src={PenetrationFormIcon}
      className={props?.className || 'row'}
    />
  )
}

export function Filter(props) {
  return (
    <InlineSVG
      className={(props && props.className) || 'row'}
      {...props}
      src={FilterIcon}
    />
  )
}

export function StatusUpdateStateGray(props) {
  return <InlineSVG {...props} src={StatusUpdateStateGrayIcon} />
}

export function StatusUpdateStateBlue(props) {
  return <InlineSVG {...props} src={StatusUpdateStateBlueIcon} />
}

export function CameraWhite(props) {
  return <InlineSVG {...props} src={CameraWhiteIcon} />
}

export function CalendarWhite(props) {
  return <InlineSVG {...props} src={CalendarWhiteIcon} />
}

export function PieChart(props) {
  return <InlineSVG {...props} src={PieChartIcon} />
}

export function Messages(props) {
  return <InlineSVG {...props} src={MessagesIcon} />
}

export function Material(props) {
  return (
    <InlineSVG
      {...props}
      src={MaterialIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function BlueSearch(props) {
  return (
    <InlineSVG
      {...props}
      src={BlueSearchIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Repeat(props) {
  return <InlineSVG {...props} src={RepeatIcon} />
}

export function CopyFile(props) {
  return <InlineSVG {...props} src={CopyFileIcon} />
}

export function CheckPaletteBlue(props) {
  return <InlineSVG {...props} src={CheckPaletteBlueIcon} />
}

export function Trade(props) {
  return <InlineSVG {...props} src={TradeIcon} />
}

export function StarFilledRounded(props) {
  return <InlineSVG {...props} src={StarFilledRoundedIcon} />
}

export function ProjectDetailsSettings(props) {
  return <InlineSVG {...props} src={ProjectDetailsSettingsIcon} />
}

export function Upload(props) {
  return <InlineSVG {...props} src={UploadIcon} />
}

export function Presentation(props) {
  return <InlineSVG {...props} src={PresentationIcon} />
}

export function Mobile(props) {
  return <InlineSVG {...props} src={MobileIcon} />
}

export function BallInCourt(props) {
  return <InlineSVG {...props} src={BallInCourtIcon} />
}

export function Question(props) {
  return (
    <InlineSVG
      {...props}
      src={QuestionIcon}
      className={(props && props.className) || 'row'}
    />
  )
}

export function Reorder(props) {
  return <InlineSVG {...props} src={ReorderIcon} />
}

export function Calendar(props) {
  return <InlineSVG {...props} src={CalendarIcon} />
}

export function Header(props) {
  return <InlineSVG {...props} src={HeaderIcon} />
}

export function Underlined(props) {
  return <InlineSVG {...props} src={UnderlinedIcon} />
}

export function BulletList(props) {
  return <InlineSVG {...props} src={BulletedListIcon} />
}

export function NumberedList(props) {
  return <InlineSVG {...props} src={NumberedListIcon} />
}

export function HorizontalLine(props) {
  return <InlineSVG {...props} src={HorizontalLineIcon} />
}

export function Announcement(props) {
  return <InlineSVG {...props} src={AnnouncementIcon} />
}

export function Maximize(props) {
  return <InlineSVG {...props} src={MaximizeIcon} />
}

export function MoreHorizontal(props) {
  return <InlineSVG {...props} src={MoreHorizontalIcon} />
}

export function ArrowNext(props) {
  return <InlineSVG {...props} src={ArrowNextIcon} />
}

export function ArrowForward(props) {
  return <InlineSVG {...props} src={ArrowForwardIcon} />
}

export function ArrowBack(props) {
  return <InlineSVG {...props} src={ArrowBackIcon} />
}

export function CameraFilled(props) {
  return <InlineSVG {...props} src={CameraFilledIcon} />
}

export function MegaphoneFilled(props) {
  return <InlineSVG {...props} src={MegaphoneFilledIcon} />
}

export function Print(props) {
  return <InlineSVG {...props} src={PrintIcon} />
}

export function Photo(props) {
  return <InlineSVG {...props} src={PhotoIcon} />
}

export function Measure(props) {
  return <InlineSVG {...props} src={MeasureIcon} />
}

export function HashTag(props) {
  return <InlineSVG {...props} src={HashTagIcon} />
}

export function IdIcon(props) {
  return <InlineSVG {...props} src={IdIconSrc} />
}

export function Docs(props) {
  return <InlineSVG {...props} src={DocsIcon} />
}

export function PinOnMap(props) {
  return <InlineSVG {...props} src={PinOnMapIcon} />
}

export function FilledGate(props) {
  return <InlineSVG {...props} src={FilledGateIcon} />
}

export function Email(props) {
  return (
    <InlineSVG
      {...props}
      src={EmailIcon}
      className={props?.className || 'row'}
    />
  )
}

export function At(props) {
  return (
    <InlineSVG {...props} src={AtIcon} className={props?.className || 'row'} />
  )
}

export function Phone(props) {
  return (
    <InlineSVG
      {...props}
      src={PhoneIcon}
      className={props?.className || 'row'}
    />
  )
}

export function Procore(props) {
  return (
    <InlineSVG
      {...props}
      src={ProcoreIcon}
      className={props?.className || 'row'}
    />
  )
}

export function HorizontalListLines(props) {
  return <InlineSVG {...props} src={HorizontalListLinesIcon} />
}

export function Alert(props) {
  return <InlineSVG {...props} src={AlertIcon} />
}

export function ConcreteDirect(props) {
  return <InlineSVG {...props} src={ConcreteDirectIcon} />
}

export function Sensor(props) {
  return <InlineSVG {...props} src={SensorIcon} />
}

export function Integrations(props) {
  return <InlineSVG {...props} src={IntegrationIcon} />
}

export function AddBordered(props) {
  return (
    <InlineSVG
      {...props}
      src={AddBorderedIcon}
      className={props?.className || 'row'}
    />
  )
}

export function Restore(props) {
  return (
    <InlineSVG
      {...props}
      src={RestoreIcon}
      className={props?.className || 'row'}
    />
  )
}

export function Undo(props) {
  return (
    <InlineSVG
      {...props}
      src={UndoIcon}
      className={props?.className || 'row'}
    />
  )
}

export function Redo(props) {
  return (
    <InlineSVG
      {...props}
      src={RedoIcon}
      className={props?.className || 'row'}
    />
  )
}

export function SortDesc(props) {
  return (
    <InlineSVG
      {...props}
      src={SortDescIcon}
      className={props?.className || 'row'}
    />
  )
}

export function SortAsc(props) {
  return (
    <InlineSVG
      {...props}
      src={SortAscIcon}
      className={props?.className || 'row'}
    />
  )
}

export function ExpandAll(props) {
  return (
    <InlineSVG
      {...props}
      src={ExpandAllIcon}
      className={props?.className || 'row'}
    />
  )
}

export function CollapseAll(props) {
  return (
    <InlineSVG
      {...props}
      src={CollapseAllIcon}
      className={props?.className || 'row'}
    />
  )
}

export function ConditionalLogic(props) {
  return (
    <InlineSVG
      {...props}
      src={ConditionalLogicIcon}
    />
  )
}

export function MicrosoftAzure(props) {
  return (
    <InlineSVG
      {...props}
      src={MicrosoftAzureIcon}
      className={props?.className || 'row'}
    />
  )
}

export function Microsoft(props) {
  return (
    <InlineSVG
      {...props}
      src={MicrosoftIcon}
      className={props?.className || 'row'}
    />
  )
}
