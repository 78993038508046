import * as React from 'react'

import { observer } from 'mobx-react'

import { PermitFieldType } from '~/client/graph/types.generated'

import PermitDatePicker from '../../../PermitDatePicker/PermitDatePicker'
import SitePermitCreationFormStore from '../../SitePermitCreationForm.store'
import FormDropdownOptionsModal from './components/FormDropdownOptionsModal/FormDropdownOptionsModal'

// localization: no text to translate

interface IProps {
  store: SitePermitCreationFormStore
}

const DROPDOWN_TYPES = [
  PermitFieldType.Material,
  PermitFieldType.Select,
  PermitFieldType.Measure,
]

@observer
export default class PermitContentPickers extends React.Component<IProps> {
  public render() {
    const { store } = this.props
    const { isDatePickerDisplayed, resetSelectedField } = store

    if (isDatePickerDisplayed) {
      const {
        datePickerStart,
        datePickerEnd,
        shouldShowTimePicker,
        isOneDayMode,
        maxDateRangeInYears,
        isAllDay,
      } = store

      return (
        <PermitDatePicker
          applyDates={this.onApplyDates}
          hideDatePicker={resetSelectedField}
          initialStartDate={datePickerStart}
          initialEndDate={datePickerEnd}
          initialIsAllDay={isAllDay}
          shouldShowTimePicker={shouldShowTimePicker}
          isOneDayMode={isOneDayMode}
          maxRangeInYears={maxDateRangeInYears}
        />
      )
    }

    return <FormDropdownOptionsModal store={store} isShown={this.isShown} />
  }

  private onApplyDates = (
    newStartDate: Date,
    newEndDate: Date,
    isAllDay: boolean,
  ) => {
    const { isPermitDatesFieldSelected, applyDates, changeSelectedFieldValue } =
      this.props.store

    if (isPermitDatesFieldSelected) {
      applyDates(newStartDate, newEndDate, isAllDay)
    } else {
      changeSelectedFieldValue(newStartDate.getTime())
    }
  }

  private get isShown(): boolean {
    return (
      this.props.store.isDatePickerDisplayed ||
      this.props.store.isTypeSelectorShown ||
      this.props.store.isLocationPickerDisplayed ||
      this.props.store.isCompanyPickerDisplayed ||
      this.props.store.isUserPickerDisplayed ||
      DROPDOWN_TYPES.includes(this.props.store.selectedField?.type)
    )
  }
}
