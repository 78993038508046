import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import { IPermitTypeField, IRichText } from '~/client/graph'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import AnnouncementAssignmentsStore from '~/client/src/shared/stores/domain/AnnouncementAssignments.store'
import AnnouncementsStore from '~/client/src/shared/stores/domain/Announcements.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import RichTextEditor from '../../../RichTextEditor/RichTextEditor'
import RichEditorParsed from '../../../RichTextEditor/components/RichEditorParsed'
import StruxhubInputLabel from '../../../StruxhubInputs/components/StruxhubInputLabel'
import SitePermitCreationFormStore from '../../SitePermitCreationForm.store'
import PermitBaseFormField from './PermitBaseFormField'

const getDefaultValue = (): IRichText => ({
  content: '',
  attachments: [],
})

interface IProps {
  typeField: IPermitTypeField

  store: SitePermitCreationFormStore
  fieldsStore: IPermitFieldsStore

  isViewMode: boolean

  projectDateStore?: ProjectDateStore
  announcementsStore?: AnnouncementsStore
  announcementAssignmentsStore?: AnnouncementAssignmentsStore
  eventsStore?: EventsStore
  tableId?: string
}

@inject(
  'projectDateStore',
  'eventsStore',
  'announcementsStore',
  'announcementAssignmentsStore',
)
@observer
export default class PermitFormRichTextField extends React.Component<IProps> {
  public render(): JSX.Element {
    const { isViewMode, typeField } = this.props

    return (
      <PermitBaseFormField
        typeField={typeField}
        isViewMode={isViewMode}
        viewModeElements={this.viewModeElements}
        onAddFieldClick={this.addNewFieldValue}
      >
        <StruxhubInputLabel
          label={typeField.caption}
          isRequired={typeField.isMandatory}
          value=""
          isValid
        />
        {this.fieldValues.map((value, index) => {
          return (
            <div key={`${typeField.id}_${index}`} className="my12">
              <RichTextEditor
                value={value}
                onChange={v => this.onChange(v, index)}
              />
            </div>
          )
        })}
      </PermitBaseFormField>
    )
  }

  private get fieldValues() {
    const { typeField, fieldsStore } = this.props
    const values = fieldsStore.getFieldValues<IRichText>(typeField.id)

    return values?.length ? values : [getDefaultValue()]
  }

  @action.bound
  private onChange = (value: IRichText, index: number) => {
    this.props.fieldsStore.changeFieldValue(this.props.typeField, index, value)
  }

  private get viewModeElements(): JSX.Element[] {
    return this.fieldValues
      .filter(p => p?.content?.length || p?.attachments?.length)
      .map((value, idx) => (
        <RichEditorParsed
          key={`rich-text-${idx}`}
          value={value}
          isContentExpanded
          className="mt10 bb-light-grey"
        />
      ))
  }

  private addNewFieldValue = () => {
    if (!this.isTableField) {
      const { fieldsStore, typeField } = this.props
      fieldsStore.addNewFieldValue(typeField, getDefaultValue())
    }
  }

  private get isTableField(): boolean {
    return !!this.props.tableId
  }
}
