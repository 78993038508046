import * as React from 'react'

import { inject, observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'
import { Content, Header, View } from '~/client/src/shared/components/Layout'
import { Loader } from '~/client/src/shared/components/Loader'
import Tabs, { ITabObject } from '~/client/src/shared/components/Tabs/Tabs'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import AuthenticationStore from '~/client/src/shared/stores/domain/Authentication.store'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'

import TENANT_CONFIGURATION from '../../Config'
import {
  MICROSOFT_PROVIDER_ID,
  STX_AZURE_PROVIDER_ID,
} from '../../services/FirebaseAuthService'
import { LOGOUT } from '../../stores/EventStore/eventConstants'
import { NOOP } from '../../utils/noop'
import Dimmer from '../Dimmer'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import CodeVerificationPopup from './CodeInputDialog/CodeInputDialog'
import EmailPasswordForm from './Forms/EmailPasswordForm'
import PhoneNumberForm from './Forms/PhoneNumberForm'
import LoginViewStore, { OTPLength, SignInMethod } from './LoginView.store'

import './LoginView.scss'

interface ILoginView {
  auth?: AuthenticationStore
  common?: CommonStore
  eventsStore?: EventsStore
}

const customProviderIdToIconMap = {
  [STX_AZURE_PROVIDER_ID]: <Icons.MicrosoftAzure className="row no-grow" />,
  [MICROSOFT_PROVIDER_ID]: <Icons.Microsoft className="row no-grow" />,
}

@inject('auth', 'common', 'eventsStore')
@observer
export default class LoginView extends React.Component<ILoginView> {
  private readonly loginViewStore: LoginViewStore = null
  private phoneLoginSubmitBtnRef: HTMLButtonElement = null

  public constructor(props: ILoginView) {
    super(props)

    const { auth, common, eventsStore } = props
    this.loginViewStore = new LoginViewStore(eventsStore, auth, common)
  }

  public componentDidMount() {
    if (navigator.webdriver) {
      localStorage.setItem('lhTimeToLoginForm', performance.now().toString())
    }
  }

  public render() {
    if (this.props.eventsStore.appState.loading.get(LOGOUT)) {
      return (
        <div className="row full-height">
          <Loader hint={Localization.translator.loggingOut} />
        </div>
      )
    }

    const {
      isLoading,
      isCodeBeingVerified,
      codeVerificationErrorMsg,
      errorMessage,
      signInMethod,
      shouldShowCodeInput,
      handleCodeInputChange,
      hideVerificationCodeModal,
      goToResetPasswordView,
      enteredPhoneNumber,
    } = this.loginViewStore

    let form = null

    switch (signInMethod) {
      default:
      case SignInMethod.EmailPassword:
        form = (
          <EmailPasswordForm
            onSubmit={this.loginViewStore.signInWithEmailPassword}
            onResetPassword={goToResetPasswordView}
          />
        )
        break
      case SignInMethod.PhoneNumber:
        form = (
          <PhoneNumberForm
            setSubmitBtnRef={ref => (this.phoneLoginSubmitBtnRef = ref)}
            onSubmit={this.loginViewStore.signInWithPhoneNumber}
          />
        )
        break
      case SignInMethod.Procore:
        form = this.procoreForm
    }

    return (
      <View className="login-view-container full-width">
        <Header>
          {isLoading && !shouldShowCodeInput && (
            <Loader
              hint={Localization.translator.loggingIn}
              size={50}
              className="col x-center absolute-block absolute-block-y-center z-index-100"
            />
          )}
        </Header>
        <Content className="px16 py12">
          <Dimmer shown={isLoading} onClick={NOOP} />

          {Localization.shouldShowLanguageSelector && (
            <LanguageSelector
              className="primary-blue mb10"
              textClassName="x-end"
            />
          )}

          <CodeVerificationPopup
            codeLength={OTPLength}
            phoneNumber={enteredPhoneNumber}
            isOpen={shouldShowCodeInput}
            onHide={hideVerificationCodeModal}
            errorMessage={codeVerificationErrorMsg}
            isCodeBeingVerified={isCodeBeingVerified}
            isCodeBeingResent={isLoading}
            onResendCode={this.triggerResendOtp}
            onCodeInputChange={handleCodeInputChange}
          />

          {this.renderSignInMethodToggler()}

          {form}

          {!!errorMessage && (
            <label className="text large center red bold mt14">
              {errorMessage}
            </label>
          )}
        </Content>
      </View>
    )
  }

  private renderSignInMethodToggler(): JSX.Element {
    const { signInMethod, switchSignInMethod } = this.loginViewStore

    return (
      <div className="col no-grow">
        <span className="text bold small light no-white-space-wrap uppercase lp15 mb5">
          {Localization.translator.logInWith}
        </span>

        {this.renderCustomProviders()}

        <Tabs
          tabObjects={this.signInMethodTabs}
          activeTabId={signInMethod}
          onTabClick={switchSignInMethod}
          className="mt5 mb20"
          tabTextClassName="text light large bold lp015"
          tabClassName="pa10 mr10"
        />
      </div>
    )
  }

  public triggerResendOtp = () => {
    this.loginViewStore.resetCodeVerificationErrorMessage()
    this.phoneLoginSubmitBtnRef.click()
  }

  private get signInMethodTabs(): Array<ITabObject<SignInMethod>> {
    const iconClassName = 'row no-grow mr8'

    return [
      {
        title: Localization.translator.email_noun,
        page: SignInMethod.EmailPassword,
        isHidden: false,
        icon: <Icons.Email className={iconClassName} />,
      },
      {
        title: Localization.translator.phone,
        page: SignInMethod.PhoneNumber,
        isHidden: false,
        icon: <Icons.Phone className={iconClassName} />,
      },
      {
        title: 'Procore',
        page: SignInMethod.Procore,
        isHidden: false,
        icon: <Icons.Procore className={iconClassName} />,
      },
    ]
  }

  private get procoreForm(): JSX.Element {
    // TODO?: Embed Procore form via iframe
    // Leave the old control and functionality for a while
    return (
      <form>
        <button
          type="button"
          className="bg-white brada4 full-width mt10 pa10 relative ba-procore outline-procore"
          onClick={this.loginViewStore.procoreLogin}
        >
          <img
            src="/static/icons/procore-icon.svg"
            className="absolute-block"
            style={{ top: '10%', height: '80%', left: '1%' }}
          />
          <span className="text procore extra-large">
            {Localization.translator.procoreLogin}
          </span>
        </button>
      </form>
    )
  }

  private renderCustomProviders(): JSX.Element[] {
    const { signInWithCustomProvider } = this.loginViewStore

    return TENANT_CONFIGURATION.firebaseSSOProviders?.map(
      ({ providerId, displayName, type }) => (
        <button
          key={providerId}
          onClick={signInWithCustomProvider.bind(null, providerId, type)}
          className="full-width row x-center bg-white brada4 pa10 my5 pointer ba-palette-brand-lighter"
        >
          <span className="text blue-brand bold large lp015 no-white-space-wrap no-grow mr8 no-grow">
            {displayName}
          </span>
          {customProviderIdToIconMap[providerId]}
        </button>
      ),
    )
  }
}
